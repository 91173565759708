<template>
  <div class="flex flex-col h-screen max-h-screen bg-white">
    <template v-if="isLoadingCompanies">
      <div class="w-full h-full bg-header flex items-center justify-center">
        <div class="flex flex-col">
          <div class="w-full flex justify-center">
            <img alt="loading" src="@/assets/loading.gif" class="w-20">
          </div>
          <p class="text-lg text-white text-center object-none object-center ">
            {{ activeUser.name }}, vamos por más
          </p>
        </div>
      </div>
    </template>
    <template v-else>
      <!--top bar-->
      <div class="w-full h-16 flex justify-between bg-header">
        <div class="md:w-1/5 w-36 flex justify-items-center cursor-pointer">
          <MainDropDown :type="'chat'" class="w-full"/>
        </div>
        <CompanyDropDown class="p-1" :type="'chat'" @changed-company="changedCompany"/>
        <LeadNotificationsDropDown class="p-1" @open-lead="getLead"/>
        <MessageNotificationsDropDown class="p-1" @open-conversation="getConversation"/>
        <div class="flex-grow"></div>
        <DropDown class="p-3"/>
      </div>
      <div class="flex w-full flex-grow h-80">
        <!--left menu-->
        <div class="h-max bg-header lg:flex flex-col sidebar-menu md:overflow-y-auto"
             v-bind:class="{ 'hidden' : activeConversation, 'flex' : !activeConversation }">
          <div class="py-1 flex w-full cursor-pointer">
            <button class="sidebar-icons" @click="isShow = !isShow">
              <MenuIcon class="m-2 h-5 w-5 text-white text-right" aria-hidden="true"/>
            </button>
          </div>
          <div class="test-sidebar" v-bind:class="{ hidden: !isShow }">
            <vue-collapsible-panel-group accordion base-color="#000000">
              <vue-collapsible-panel>
                <template #title>
                  <div class="py-1 flex cursor-pointer md:w-52 w-32" @click="setType('active')"
                       @keydown="setType('active')"
                       v-bind:class="{ 'bg-leadbox-500' : type === 'active' }">
                    <CollectionIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                    <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">Activos</div>
                  </div>
                </template>
                <template #content>
                  <template v-if="isLoadingStatuses">
                    <p class="text-white text-md">Cargando...</p>
                  </template>
                  <template v-else>
                    <StatusItem v-for="(status) in activeStatuses"
                                v-bind:key="status.id" :status="status" :type="'active'"
                                @set-status="setStatus" :selected="selectedStatus"/>
                  </template>
                </template>
              </vue-collapsible-panel>
              <vue-collapsible-panel :expanded="false">
                <template #title>
                  <div class="py-1 flex w-full cursor-pointer" @click="setType('closed')"
                       @keydown="setType('closed')"
                       v-bind:class="{ 'bg-leadbox-500' : type === 'closed' }">
                    <LockClosedIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                    <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">Cerrados</div>
                  </div>
                </template>
                <template #content>
                  <template v-if="isLoadingStatuses">
                    <p class="text-white text-md">Cargando...</p>
                  </template>
                  <template v-else>
                    <StatusItem v-for="(status) in closedStatuses" v-bind:key="status.id"
                                :status="status" :type="'closed'"
                                @set-status="setStatus" :selected="selectedStatus"/>
                  </template>
                </template>
              </vue-collapsible-panel>
              <vue-collapsible-panel :expanded="false">
                <template #title>
                  <div class="py-1 flex w-full cursor-pointer" @click="setType('archived')"
                       @keydown="setType('archived')"
                       v-bind:class="{ 'bg-leadbox-500' : type === 'archived' }">
                    <ArchiveIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                    <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">Archivados</div>
                  </div>
                </template>
                <template #content>
                  <template v-if="isLoadingStatuses">
                    <p class="text-white text-md">Cargando...</p>
                  </template>
                  <template v-else>
                    <StatusItem v-for="(status) in statuses" v-bind:key="status.id"
                                :status="status" :type="'archived'"
                                @set-status="setStatus" :selected="selectedStatus"/>
                  </template>
                </template>
              </vue-collapsible-panel>
            </vue-collapsible-panel-group>
            <div class="h-fit max-h-fit">
              <vue-collapsible-panel-group accordion>
                <vue-collapsible-panel :expanded="true">
                  <template #title>
                    <div class="py-1 flex">
                      <UserIcon class="m-2 h-5 w-5 text-white" aria-hidden="true"/>
                      <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">
                        <b>ASESORES</b>
                      </div>
                    </div>
                  </template>
                  <template #content>
                    <div class="overflow-auto h-fit">
                      <template v-if="isLoadingConversationsUsers">
                        <p class="text-white text-md">Cargando...</p>
                      </template>
                      <template v-else>
                        <AsesorItem v-for="(user) in conversationsUsers" v-bind:key="user.id"
                                    :user="user" :selected="selectedUser" @set-user="setUser"/>
                      </template>
                    </div>
                  </template>
                </vue-collapsible-panel>
              </vue-collapsible-panel-group>
            </div>
          </div>
        </div>
        <!--intermediate menu-->
        <div class="w-full md:w-7/12 lg:w-1/5 h-max bg-gray-200 lg:flex flex-col"
             v-bind:class="{ 'hidden' : activeConversation, 'flex' : !activeConversation }">
          <div class="w-full flex justify-center">
            <div class="flex py-1 mx-1 w-full">
              <litepie-datepicker class="filter-date" placeholder="Ver por fecha"
                                  separator=" hasta " i18n="es" overlay :auto-apply="false"
                                  :formatter="formatter" :options="options" v-model="dateValue">
              </litepie-datepicker>
            </div>
            <div class="p-2 m-1 mx-2 rounded-lg bg-header"
                 @keydown="openCreateModal()"
                 @click="openCreateModal()">
              <PlusSmIcon class="w-6 mx-1 cursor-pointer text-white" aria-hidden="true"/>
            </div>
          </div>
          <div class="w-full">
            <div class="flex rounded-lg bg-gray-100">
              <!--eslint-disable-next-line-->
              <input class="p-2 w-10/12 bg-gray-100 text-sm focus:outline-none" v-model="search"
                     type="text" placeholder="Buscar conversaciones...">
              <div class="p-1 w-2/12 flex justify-center items-center cursor-pointer bg-gray-100"
                   @click="getConversations()" @keydown="getConversations()" data-toggle="tooltip"
                   data-placement="top" title="Buscar">
                <SearchIcon class="m-2 h-6 w-6 text-black" aria-hidden="true"/>
              </div>
            </div>
          </div>
          <div class="w-full">
            <div class="m-1 flex justify-start rounded-lg">
              <button v-bind:class="{ 'bg-gray-300': provider === 'whatsapp',}" class="rounded-lg">
                <!--eslint-disable-next-line-->
                <img alt="logo whatsapp" class="w-8 m-2 h-auto" data-toggle="tooltip"
                     data-placement="top" title="Conversaciones de Whatsapp"
                     src="@/assets/logo-whatsapp.png" @click="setProvider('whatsapp')">
              </button>
              <button v-bind:class="{ 'bg-gray-300': provider === 'facebook-messenger',}"
                      class="rounded-lg">
                <!--eslint-disable-next-line-->
                <img alt="logo whatsapp" class="w-8 m-2 h-auto " data-toggle="tooltip"
                     data-placement="top" title="Conversaciones de Facebook"
                     src="@/assets/logo-messenger.png" @click="setProvider('facebook-messenger')">
              </button>
              <button v-bind:class="{ 'bg-gray-300': provider === 'instagram-messenger',}"
                      class="rounded-lg">
                <!--eslint-disable-next-line-->
                <img alt="logo whatsapp" class="w-8 m-2 h-auto " data-toggle="tooltip"
                     data-placement="top" title="Conversaciones de Instagram"
                     src="@/assets/logo-instagram.png" @click="setProvider('instagram-messenger')">
              </button>
              <button v-bind:class="{ 'bg-gray-300': provider === 'leadbox-chat',}"
                      class="rounded-lg">
                <!--eslint-disable-next-line-->
                <img alt="logo whatsapp" class="w-8 m-2 h-auto " data-toggle="tooltip"
                     data-placement="top" title="Conversaciones de Leadbox Chat"
                     src="@/assets/lead.png" @click="setProvider('leadbox-chat')">
              </button>
            </div>
            <div class="m-1 flex justify-between rounded-lg">
              <p class="my-2 ml-1"><b>Total:</b> {{ total }}</p>
              <div class="flex">
                <div title="Eliminar conversaciones selecionadas" class="hover:bg-gray-300">
                  <TrashIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                             v-if="selectedConversations.length > 0"
                             @click="showDeleteModal = true;"/>
                </div>
                <div title="Archivar conversaciones selecionadas" class="hover:bg-gray-300">
                  <ArchiveIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                               v-if="selectedConversations.length > 0"
                               @click="showArchiveModal = true;"/>
                </div>
                <div title="Asignar selecionados" class="hover:bg-gray-300">
                  <UserIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                            v-if="selectedConversations.length > 0"
                            @click="showAssignOneModal = true;"/>
                </div>
                <div title="Asignación automática" class="hover:bg-gray-300">
                  <UsersIcon class="w-6 m-1 cursor-pointer" aria-hidden="true"
                             @click="showAssignModal = true;"/>
                </div>
<!--                <div title="Filtro por estados" class="hover:bg-gray-300">-->
<!--                  <ConversationStatusesDropDown v-if="statuses.length > 0"-->
<!--                                                class="cursor-pointer"-->
<!--                                                aria-hidden="true" :status="status"-->
<!--                                                @set-status="setStatus"/>-->
<!--                </div>-->
                <div title="Filtro por premiados" class="hover:bg-gray-300">
                  <AwardedDropDown :awarded="awarded"
                                   @set-awarded="setAwarded"/>
                </div>
                <div title="Filtro por mensajes nuevos" class="hover:bg-gray-300">
                  <NewMessagesDropDown :newMessages="new_messages"
                                       @set-new-messages="setNewMessages"/>
                </div>
                <div title="Filtro por leads" class="hover:bg-gray-300">
                  <FiltersDropDown :filter="filter" @set-filter="setFilter"/>
                </div>
                <div title="Opciones" class="hover:bg-gray-300">
                  <OptionsDropDown :order="order" @select-all="selectAll" @refresh="refresh"
                                   :all="allSelected" @change-order="changeOrder"/>
                </div>
              </div>
            </div>
          </div>
          <div class="scroll-smooth overflow-auto h-fit">
            <template v-if="isLoadingConversations">
              <p class="text-md text-black text-center">Cargando....</p>
            </template>
            <template v-else>
              <template v-if="conversations.length>0">
                <ConversationItem v-for="(conversation) in conversations"
                                  v-bind:key="conversation.id" :conversation="conversation"
                                  :active="activeConversation"
                                  :selected="selectedConversations"
                                  @check-clicked="selectGroupConversation"
                                  @selected-conversation="selectConversation"/>
              </template>
              <template v-else>
                <p class="text-center mt-10">
                  {{
                    search !== null && search !== '' ?
                      'No hay resultados para ' + this.search :
                      'No hay conversaciones'
                  }}</p>
              </template>
            </template>
          </div>
          <div class="w-full" v-if="nextPageUrl">
            <div class="m-1 flex rounded-lg cursor-pointer bg-white place-content-center p-2"
                 @click="loadMore()" @keydown="loadMore()">
              {{ isLoadingMore ? 'Cargando....' : 'ver más' }}
            </div>
          </div>
          <!--success masive-->
          <ModalBox :showing="showSuccessModal" @close="showSuccessModal = false">
            <div class="flex">
              <p class="text-black"><b>Envío masivo finalizado</b></p>
            </div>
            <div class="w-full max-h-40">
              <p class="text-black"><b>Envíos correctos</b></p>
              <ul>
                <li v-for="(success, key) in massiveSuccess"
                    v-bind:key="key">
                  {{ success.name }}
                </li>
              </ul>
            </div>
            <div class="w-full max-h-40">
              <p class="text-black"><b>Envíos con error</b></p>
              <ul>
                <li v-for="(error, key) in massiveErrors"
                    v-bind:key="key">
                  <b>{{ error.name }}: </b>{{ error.error }}
                </li>
              </ul>
            </div>
            <div class="flex">
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                      @click="showSuccessModal = false"
                      type="button">Cerrar
              </button>
            </div>
          </ModalBox>
          <!--elimnar leads-->
          <ModalBox :showing="showDeleteModal" @close="showDeleteModal = false">
            <div class="flex">
              <p class="text-black"><b>Eliminar conversaciones</b></p>
            </div>
            <div class="flex">
              <p class="text-black">
                Deseas eliminar {{ selectedConversations.length }} conversaciones seleccionados?
              </p>
            </div>
            <div class="flex">
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                      @click="showDeleteModal = false"
                      type="button">Cerrar
              </button>
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                      @click="deleteConversations()">Aceptar
              </button>
            </div>
          </ModalBox>
          <!--archivar leads-->
          <ModalBox :showing="showArchiveModal" @close="showArchiveModal = false">
            <div class="flex">
              <p class="text-black"><b>Archivar leads</b></p>
            </div>
            <div class="flex">
              <p class="text-black">
                Deseas archivar {{ selectedConversations.length }} leads seleccionados?
              </p>
            </div>
            <div class="flex">
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                      @click="showArchiveModal = false"
                      type="button">Cerrar
              </button>
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                      @click="archiveConversations()">Aceptar
              </button>
            </div>
          </ModalBox>
          <!--enviar template-->
          <ModalBox :showing="showTemplateModal" @close="showTemplateModal = false">
            <div class="flex">
              <p class="text-black"><b>Enviar template por whatsapp</b></p>
            </div>
            <form class="flex-col flex">
              <!--eslint-disable-next-line-->
              <label for="sender_phone" class="my-2">Número de origen</label>
              <select class="my-1 bg-white appearance-none rounded-none relative block bg-gray-100
                                 px-3 py-2 border border-gray-300 placeholder-gray-500 h-10
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-2.5
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="sender_phone"
                      v-model="templateConnection"
                      name="connection_id">
                <option :value="null" class="px-3 py-2 text-sm text-center">
                  -- Selecciona el número de destino --
                </option>
                <option v-for="(connection) in connections"
                        v-bind:key="connection.id" :value="connection"
                        class="px-3 py-2 text-sm">
                  {{ connection.name }} {{ connection.phone_number }}
                </option>
              </select>
              <template v-if="templateConnection">
                <!--eslint-disable-next-line-->
                <label for="flow_selected" class="my-2">Tipo de envío</label>
                <select class="my-1 bg-white appearance-none rounded-none relative block bg-gray-100
                                 px-3 py-2 border border-gray-300 placeholder-gray-500 h-10
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-2.5
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="send_type"
                        v-model="dataTemplate.type"
                        name="send_type">
                  <option :value="null" class="px-3 py-2 text-sm text-center">
                    -- Selecciona typo --
                  </option>
                  <option value="individual" class="px-3 py-2 text-sm">
                    Individual
                  </option>
                  <option value="masivo" class="px-3 py-2 text-sm">
                    Masivo
                  </option>
                </select>
                <template v-if="dataTemplate.type">
                  <!--eslint-disable-next-line-->
                  <label for="template_selected" class="my-2">Plantillas</label>
                  <select class="my-1 bg-white appearance-none rounded-none relative block
                  bg-gray-100 px-3 py-2 border border-gray-300 placeholder-gray-500 h-10
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-2.5
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="template_selected"
                          v-model="dataTemplate.selectedTemplate" @change="setTemplateComponents"
                          name="connection_id">
                    <option :value="null" class="px-3 py-2 text-sm text-center">
                      -- Selecciona la plantilla --
                    </option>
                    <option v-for="(template, key) in dataTemplate.templates"
                            v-bind:key="key" :value="template"
                            class="px-3 py-2 text-sm">
                      {{ template.name }}
                    </option>
                  </select>

                  <template v-if="dataTemplate.selectedTemplate">
                    <template v-if="dataTemplate.type === 'individual'">
                      <template v-if="activeCompany.uuid ===
                      'ef0a6a78-0758-497b-9061-8db75f0cce3e'">
                        <!--eslint-disable-next-line-->
                        <label for="medic_name" class="my-2">Médico</label>
                        <input id="medic_name" class="p-2 my-1 bg-gray-100 text-sm
                        focus:outline-none"
                               v-model="searchMedic" type="search" @input="handleMedicInput"
                               placeholder="Busca el medico de destino ">
                        <div v-if="showMedicDropdown" class="overflow-y-auto max-h-60">
                          <template v-if="isLoadingMedics">
                            <p>Cargando...</p>
                          </template>
                          <template v-else>
                            <ul>
                              <!--eslint-disable-next-line-->
                              <li v-for="result in medicSearchResults" :key="result.id"
                                  @click="handleSelectMedic(result)"
                                  class="p-2 border border-leadbox-900 cursor-pointer">
                                {{ result.NOMBRES_COMPLETOS }} {{ result.TLF_CELULAR }}
                              </li>
                            </ul>
                          </template>
                        </div>
                      </template>
                    </template>
                    <template v-if="dataTemplate.type === 'masivo'">
                      <p><b>Envío Masivo</b></p>
                      <p>Para enviar a varios destinatarios suba un archivo cvs, xlsx con los
                        encabezados en la primera fila</p>
                      <input ref="massive" type="file" id="massive" @change="onMassiveFile"
                             class="p-2 my-1 bg-gray-100 text-sm focus:outline-none"/>
                      <template v-if="dataTemplate.to.length > 0">
                        <p>Vas a enviar a {{ dataTemplate.to.length }} destinatarios</p>
                        <p>
                          <b>Nota: </b> El número de cada fila debe tener código del país sin el +,
                          por ejemplo: 593999999999.
                        </p>
                        <div class="flex justify-end">
                          <!--eslint-disable-next-line-->
                          <div class="py-2 px-4 border cursor-pointer
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-red-600" @click="clearTo">Vaciar
                          </div>
                        </div>
                        <div class="overflow-y-auto max-h-40 w-full">
                          <table class="w-full table-auto">
                            <thead>
                            <tr class="bg-gray-100">
                              <th v-for="(header, kh) in dataTemplate.headers"
                                  v-bind:key="kh">{{ header }}
                              </th>
                              <th>Acciones</th>
                            </tr>
                            </thead>
                            <tbody>
                            <tr v-for="(destinatary, key) in dataTemplate.to" v-bind:key="key">
                              <td class="text-center" v-for="(cell, kc) in dataTemplate.headers"
                                  v-bind:key="kc">{{ destinatary[kc] }}
                              </td>
                              <td class="flex justify-center">
                                <!--eslint-disable-next-line-->
                                <div class="py-2 px-4 border cursor-pointer
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-red-600"
                                     @click="deleteDestinatary(key)">Eliminar
                                </div>
                              </td>
                            </tr>
                            </tbody>
                          </table>
                        </div>

                      </template>
                    </template>
                    <p><b>Texto de la plantilla</b></p>
                    <div class="flex w-full"
                         v-for="(component, key) in dataTemplate.selectedTemplate.components"
                         v-bind:key="key">
                      <template v-if="component.type === 'BODY'">
                        <div>
                          <b>{{ component.type }}</b> {{ component.text }}
                        </div>
                      </template>
                      <template v-if="component.type === 'HEADER'">
                        <div>
                          <b>{{ component.type }}</b> {{ component.format }}
                        </div>
                      </template>
                    </div>
                    <div class="flex-col w-full "
                         v-for="(component, key) in dataTemplate.components"
                         v-bind:key="key">
                      <b class="w-full">Variables de {{ component.type }}</b>
                      <template v-if="component.type === 'body'">
                        <template v-if="dataTemplate.type === 'individual'">
                          <template v-for="(parameter, j) in component.parameters"
                                    v-bind:key="j">
                            <div class="flex w-full">
                              <p class="p-3"><b>{{ j + 1 }}</b></p>
                              <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" type="text" v-model="parameter.text"
                                     @input="setTemplateTexts">
                            </div>
                          </template>
                        </template>
                        <template v-if="dataTemplate.type === 'masivo'">
                          <template v-for="(parameter, j) in component.massiveConfig"
                                    v-bind:key="j">
                            <div class="flex w-full">
                              <p class="p-3"><b>{{ j + 1 }}</b></p>
                              <div class="flex-col w-full">
                                <div class="flex">
                                  <input type="checkbox" class="my-1 w-5"
                                         :value="parameter.fixed"
                                         @change="parameter.fixed = !parameter.fixed;
                                         parameter.column = null;">
                                  <p>Texto Fijo (se repite en todos)</p>
                                </div>
                                <template v-if="parameter.fixed">
                                  <input class="my-2 appearance-none rounded-none relative block
                                w-full px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" type="text" v-model="parameter.column"
                                         placeholder="Ingresa el texto fijo"
                                         @input="setTemplateTexts">
                                </template>
                                <template v-else>
                                  <select class="my-2 appearance-none rounded-none relative block
                                w-full px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="parameter.column"
                                          @change="setTemplateTexts">
                                    <option :value="null">
                                      --Selecciona la columna de esta variable--
                                    </option>
                                    <option v-for="(header, kh) in dataTemplate.headers"
                                            v-bind:key="kh" :value="kh">{{ header }}
                                    </option>
                                  </select>
                                </template>
                              </div>
                            </div>
                          </template>
                        </template>
                      </template>
                      <template v-if="component.type === 'header'">
                        <template v-for="(parameter, k) in component.parameters"
                                  v-bind:key="k">
                          <div class="flex w-full">
                            <p class="p-3 flex-none"><b>{{ k + 1 }}</b></p>
                            <div class="flex bg-transparent w-full grow">
                              <!--eslint-disable-next-line-->
                              <template v-if="parameter.type === 'image'">
                                <input placeholder="Escríbe el url de la imagen o pega tu imagen"
                                       type="text"
                                       class="my-2 appearance-none rounded-none block
                                       w-full px-3 py-2 border border-gray-300 placeholder-gray-500
                                       text-gray-900 rounded-b-md focus:outline-none
                                       focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                       sm:text-sm" v-model="parameter.image.link"
                                       @input="setTemplateTexts"
                                       @paste="onPasteTemplates" :id="key"
                                       :aria-label="k"
                                />
                              </template>
                              <template v-if="parameter.type === 'video'">
                                <input placeholder="Escríbe el url del video o pega tu video"
                                       type="text"
                                       class="my-2 appearance-none rounded-none block
                                       w-full px-3 py-2 border border-gray-300 placeholder-gray-500
                                       text-gray-900 rounded-b-md focus:outline-none
                                       focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                       sm:text-sm" v-model="parameter.video.link"
                                       @input="setTemplateTexts"
                                       @paste="onPasteTemplates" :id="key"
                                       :aria-label="k"
                                />
                              </template>
                            </div>
                            <PaperClipIcon class="mx-1 my-3 text-gray-400 w-6 h-6 flex-none
                                                cursor-pointer"
                                           @click="openFilePickerTemplate(k)" aria-hidden="true"
                                           data-toggle="tooltip" data-placement="top"
                                           v-if="canSend"
                                           title="Adjuntar" alt="Adjuntar"/>
                          </div>
                          <div class="flex" v-if="isUploading">
                            <p>Subiendo imagen ...</p>
                          </div>
                          <input :ref="'templateInput'+k" type="file"
                                 :id="key"
                                 :aria-label="k" @change="onInputChangeTemplates" class="hidden"/>
                        </template>
                        <!--eslint-disable-next-line-->

                      </template>
                    </div>
                    <p><b>Vista previa</b></p>
                    <p v-if="dataTemplate.type === 'masivo'">
                      <b>Nota: </b> Se muestra un ejemplo con el primer registro del listado, este
                      se repetirá para todos los destinatarios de la lista
                    </p>
                    <p><b>HEADER</b></p>
                    <div class="flex w-full"
                         v-for="(head, key) in dataTemplate.header"
                         v-bind:key="key">
                      <p v-if="head.type === 'text'">{{ head.value }}</p>
                      <img v-if="head.type === 'image'" :src="head.value" alt="image"/>
                      <!--                    eslint-disable-next-line-->
                      <video v-if="head.type === 'video'" controls>
                        <source :src="head.value">
                        Your browser does not support the video tag.
                      </video>
                    </div>
                    <p><b>BODY</b></p>
                    <div class="flex w-full"
                         v-for="(text, key) in dataTemplate.body"
                         v-bind:key="key">
                      <p v-if="text.type === 'text'">{{ text.value }}</p>
                    </div>
                    <template v-if="dataTemplate.type === 'individual'">
                      <!--eslint-disable-next-line-->
                      <label for="destination" class="my-2">Número de destino</label>
                      <input id="destination" class="p-2 my-1 bg-gray-100 text-sm
                      focus:outline-none"
                             v-model="dataTemplate.destination" type="tel"
                             placeholder="Número de destino">
                      <p>
                        <b>Nota: </b> para números de Ecuador puedes ingresarlos con 0, ejemplo:
                        0999999999, para otros países, debes ingresar el número con el código del
                        país sin el +, ejemplo: 593999999999.
                      </p>
                      <!--eslint-disable-next-line-->
                      <label for="destination_name" class="my-2">Nombre</label>
                      <input id="destination_name"
                             class="p-2 my-1 bg-gray-100 text-sm focus:outline-none"
                             v-model="dataTemplate.name" type="text"
                             placeholder="Nombre del destinatario">
                    </template>
                    <template v-if="dataTemplate.type === 'masivo'">
                      <!--eslint-disable-next-line-->
                      <label for="column_destination" class="my-2">Columna número de destino</label>
                      <select id="column_destination"
                              class="p-2 my-1 bg-gray-100 text-sm focus:outline-none"
                              v-model="dataTemplate.destination">
                        <option :value="null">--Selecciona la columna del número del destinatario--
                        </option>
                        <option v-for="(header, kh) in dataTemplate.headers"
                                v-bind:key="kh" :value="kh">{{ header }}
                        </option>
                      </select>
                      <!--eslint-disable-next-line-->
                      <label for="column_destination_name" class="my-2">Nombre</label>
                      <select id="column_destination_name"
                              class="p-2 my-1 bg-gray-100 text-sm focus:outline-none"
                              v-model="dataTemplate.name">
                        <option :value="null">--Selecciona la columna del nombre del destinatario--
                        </option>
                        <option v-for="(header, kh) in dataTemplate.headers"
                                v-bind:key="kh" :value="kh">{{ header }}
                        </option>
                      </select>
                    </template>
                    <!--eslint-disable-next-line-->
                    <label for="flow_selected" class="my-2">Flujo de destino</label>
                    <select class="my-1 bg-white appearance-none rounded-none relative block
                    bg-gray-100 px-3 py-2 border border-gray-300 placeholder-gray-500 h-10
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-2.5
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="flow_selected"
                            v-model="dataTemplate.selectedFlow"
                            name="connection_id">
                      <option :value="null" class="px-3 py-2 text-sm text-center">
                        -- Selecciona flujo --
                      </option>
                      <option v-for="(flow, key) in flows"
                              v-bind:key="key" :value="flow"
                              class="px-3 py-2 text-sm">
                        {{ flow.name }}
                      </option>
                    </select>
                    <!--eslint-disable-next-line-->
                    <label for="user_selected" class="my-2">Usuario Asignado</label>
                    <select class="my-1 bg-white appearance-none rounded-none relative block
                    bg-gray-100 px-3 py-2 border border-gray-300 placeholder-gray-500 h-10
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-2.5
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm"
                            v-model="dataTemplate.selectedUser" id="user_selected"
                            name="user_selected">
                      <option :value="null" class="px-3 py-2 text-sm text-center">
                        -- Selecciona asesor --
                      </option>
                      <option v-for="(user) in conversationsUsersList"
                              v-bind:key="user.id" :value="user"
                              class="px-3 py-2 text-sm">
                        {{ user.name }}
                      </option>
                    </select>
                    <p>
                      <b>Nota: </b> Si no seleccionas un usuario, las conversaciones se asignan al
                      usuario que envía el mensaje.
                    </p>
                  </template>
                </template>
              </template>
            </form>
            <div class="flex my-2">
              <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                      @click="showTemplateModal = false"
                      type="button">Cerrar
              </button>
              <button v-if="dataTemplate.selectedTemplate && dataTemplate.type === 'individual'
              && dataTemplate.destination !== null"
                      class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                      @click="createConversation"
              >Aceptar
              </button>
              <button v-if="dataTemplate.selectedTemplate && dataTemplate.type === 'masivo'
              && dataTemplate.to.length > 0 && dataTemplate.name !== null
              && dataTemplate.destination !== null"
                      class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                      @click="createConversations"
              >Aceptar
              </button>
            </div>
          </ModalBox>
          <!--asignar leads-->
          <ModalBox :showing="showAssignModal" @close="showAssignModal = false">
            <div class="flex">
              <p class="text-black"><b>Asignar Conversaciones</b></p>
            </div>
            <template v-if="isLoadingAssignAuto">
              <p class="text-center">Cargando...</p>
            </template>
            <template v-else>
              <div class="flex">
                <p class="text-black">
                  Entregar las conversaciones sin asignar automaticamente a los asesores
                </p>
              </div>
              <div class="w-full flex">
                <div class="m-1">
                  <Toggle v-model="selectAssignUsers"/>
                </div>
                <p class="ml-1">Seleccionar Usuarios?</p>
              </div>
              <template v-if="selectAssignUsers">
                <div class="w-full flex" v-for="(user) in conversationsUsersList"
                     v-bind:key="user.id">
                  <div class="m-1">
                    <Toggle v-model="user.selected"/>
                  </div>
                  <p class="ml-1">{{ user.name }}</p>
                </div>
              </template>
              <div class="flex">
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                        @click="showAssignModal = false"
                        type="button">Cerrar
                </button>
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                        @click="assignConversations()">Asignar equitativamente
                </button>
              </div>
            </template>
          </ModalBox>
          <!--asignar un asesor leads-->
          <ModalBox :showing="showAssignOneModal" @close="showAssignOneModal = false">
            <div class="flex">
              <p class="text-black"><b>Asignar Conversaciones a asesor</b></p>
            </div>
            <template v-if="isLoadingAssign">
              <p class="text-center">Cargando...</p>
            </template>
            <template v-else>
              <div class="flex flex-col">
                <!--eslint-disable-next-line-->
                <label class="w-full text-left" for="assignUser">
                  <b>Usuario:</b>
                </label>
                <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="conversationsUsersList.length < 1"
                        id="assignUser" v-model="assignUserModal" name="assignUser">
                  <option :value="null" class="px-3 py-2 text-sm">
                    -- Selecciona un asesor --
                  </option>
                  <option v-for="(usr) in conversationsUsersList"
                          v-bind:key="usr.id" :value="usr"
                          class="px-3 py-2 text-sm">
                    {{ usr.name }}
                  </option>
                </select>
              </div>
              <div class="flex">
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                        @click="showAssignOneModal = false"
                        type="button">Cerrar
                </button>
                <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                        @click="assignOneLeads()">Asignar
                </button>
              </div>
            </template>
          </ModalBox>
          <!--loading-->
          <LoadingBox :showing="isLoadingConversation">
            <div class="flex flex-col">
              <img alt="loading" src="@/assets/loading.gif" class="w-20 mx-3">
              <p class="text-center text-white">
                {{ activeUser.name }},
              </p>
              <p class="text-center text-white">
                vamos por más...
              </p>
            </div>
          </LoadingBox>
        </div>
        <!--active conversation-->
        <div class="lg:w-3/5 w-full flex-grow h-max flex flex-col bg-white drop-shadow-2xl
        shadow-2xl" v-bind:class="{ 'flex': activeConversation, 'hidden':!activeConversation }">
          <template v-if="isLoadingConversation">
            <p class="text-center my-10">
              {{ activeUser.name }}, vamos por más...
            </p>
          </template>
          <template v-else>
            <template v-if="activeConversation">
              <!--top bar chat-->
              <div class="w-full flex-col flex bg-gray-100">
                <div class="w-full hidden md:flex">
                  <div class="w-full md:w-5/12 flex">
                    <div class="p-1 content-center">
                      <template v-if="activeSender?.profile_image">
                        <img class="w-12 h-12 my-6 rounded-full self-center"
                             :src="activeSender.profile_image"
                             alt="user">
                      </template>
                      <template v-else>
                        <img class="w-12 h-12 my-6 rounded-full self-center"
                             src="@/assets/default-profile.svg"
                             alt="user">
                      </template>

                    </div>
                    <div class="p-1 flex flex-col ">
                      <div class="flex text-sm">
                        <!--eslint-disable-next-line-->
                        <input v-if="editName" class="border-1 p-1"
                               v-model="activeConversation.name"
                               type="text">
                        <b class="p-1" v-else>{{ activeConversation.name }}</b>
                        <PencilAltIcon class="h-5 w-5 m-1 mx-2 cursor-pointer"
                                       @click="editName = !editName" aria-hidden="true"/>
                      </div>
                      <div class="flex my-1 mt-0 content-center">
                        <img class="w-8 h-8 rounded-lg" :src="source" alt="user">
                        <div class="flex-grow">
                          <div class="text-md m-2 my-1 self-center">
                            {{ activeConversation.form_name }}
                          </div>
                        </div>
                      </div>
                      <div class="flex text-sm justify-between m-1">
                        <div>{{ activeConversation.formatted_last_interaction }}</div>
                      </div>
                      <div class="flex text-sm justify-between m-1"
                           v-if="activeConversation.referral_id">
                        <div><b>Anuncio WhatsApp: </b>
                          {{ activeConversation.referral_headline }}
                        </div>
                      </div>
                      <div class="flex text-sm justify-between m-1"
                           v-if="activeConversation.awarded">
                        <div><b>Premiado</b></div>
                      </div>
                      <div class="flex text-sm justify-between m-1"
                           v-if="activeConversation.week_awarded">
                        <div><b>Premiado</b></div>
                      </div>
                      <div class="flex text-sm justify-between m-1"
                           v-if="activeConversation.campaign_name">
                        <div><b>Campaña:</b> {{ activeConversation.campaign_name }}</div>
                      </div>
                    </div>
                  </div>
                  <div class="w-full md:w-7/12 flex">
                    <div class="flex flex-col w-7/12 lg:w-8/12">
                      <div class="w-full flex mt-2">
                        <div class="p-1 text-sm font-medium text-gray-700">
                          <UserIcon class="m-1 h-7 w-7" aria-hidden="true"/>
                        </div>
                        <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500 h-10
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-2.5
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="user" v-model="activeConversation.user_id"
                                name="user">
                          <option :value="null" class="px-3 py-2 text-sm text-center">
                            -- Selecciona un asesor --
                          </option>
                          <option v-for="(user) in conversationsUsersList"
                                  v-bind:key="user.id" :value="user.id"
                                  class="px-3 py-2 text-sm">
                            {{ user.name }}
                          </option>
                        </select>
                      </div>
                      <div class="w-full flex mb-2">
                        <div class="p-1 text-sm font-medium text-gray-700">
                          <ClipboardListIcon class="m-1 h-7 w-7" aria-hidden="true"/>
                        </div>
                        <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500 h-10
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-2.5
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="status"
                                v-model="activeConversation.company_status_id"
                                name="status">
                          <option :value="null" class="px-3 py-2 text-sm text-center">
                            -- Selecciona un estado --
                          </option>
                          <option v-for="(status) in statuses"
                                  v-bind:key="status.id" :value="status.id"
                                  class="px-3 py-2 text-sm">
                            {{ status.name }}
                          </option>
                        </select>
                      </div>
                      <div class="w-full flex">
                        <div class="flex">
                          <img alt="leadbox logo" class="w-7 h-7 mx-2" src="@/assets/bot3.svg">
                          <div class="m-1">
                            <Toggle v-model="activeConversation.active_bot"
                                    @change="save()"/>
                          </div>
                        </div>
                        <template
                          v-if="activeCompany.uuid === '739cca8d-27e2-4fad-9683-90c452e24735'">
                          <div class="flex">
                            <p class="px-2">Premiada</p>
                            <div class="m-1">
                              <Toggle v-model="activeConversation.awarded"
                                      @change="save()"/>
                            </div>
                          </div>
                          <div class="flex">
                            <p class="px-2">Premiada Semanal</p>
                            <div class="m-1">
                              <Toggle v-model="activeConversation.week_awarded"
                                      @change="save()"/>
                            </div>
                          </div>
                        </template>
                      </div>
                      <!--                      <template-->
                      <!--v-if="activeCompany.uuid === 'ef0a6a78-0758-497b-9061-8db75f0cce3e'">-->
                      <!--                        <div class="w-full flex">-->
                      <!--                          <p>bi</p>-->
                      <!--                          <div class="m-1">-->
                      <!--           <Toggle v-model="activeConversation.business_initiated"-->
                      <!--                                    @change="save()"/>-->
                      <!--                          </div>-->
                      <!--                        </div>-->
                      <!--                        <div class="w-full flex">-->
                      <!--                          <p>ci</p>-->
                      <!--                          <div class="m-1">-->
                      <!--             <Toggle v-model="activeConversation.client_initiated"-->
                      <!--                                    @change="save()"/>-->
                      <!--                          </div>-->
                      <!--                        </div>-->
                      <!--                      </template>-->
                    </div>
                    <div class="flex w-5/12 lg:w-4/12 justify-end">
                      <button class="bg-green-700 h-10 flex rounded-lg m-1 my-2 p-2 text-white"
                              @click="save()">
                        <RefreshIcon class="mr-2 h-5 w-5" v-if="saving" aria-hidden="true"/>
                        Guardar
                      </button>
                      <!--eslint-disable-next-line-->
                      <div class="p-2 hover:bg-gray-200 cursor-pointer"
                           @click="closeConversation()">
                        <XIcon class="h-7 w-7 mr-1" aria-hidden="true"/>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="w-full flex flex-col md:hidden">
                  <div class="w-full flex">
                    <div class="flex w-7/12 sm:w-8/12">
                      <div class="p-1 content-center">
                        <template v-if="activeSender?.profile_image">
                          <img class="w-12 h-12 my-2 rounded-full self-center"
                               :src="activeSender.profile_image"
                               alt="user">
                        </template>
                        <template v-else>
                          <img class="w-12 h-12 my-2 rounded-full self-center"
                               src="@/assets/default-profile.svg"
                               alt="user">
                        </template>
                      </div>
                      <div class="p-1 flex flex-col ">
                        <div class="flex text-sm">
                          <!--eslint-disable-next-line-->
                          <input v-if="editName" class="border-1 p-1"
                                 v-model="activeConversation.name"
                                 type="text">
                          <b class="p-1" v-else>{{ activeConversation.name }}</b>
                          <PencilAltIcon class="h-5 w-5 m-1 mx-2 cursor-pointer"
                                         @click="editName = !editName" aria-hidden="true"/>
                        </div>
                        <div class="flex my-1 mt-0 content-center">
                          <img class="w-6 h-6 rounded-lg" :src="source" alt="user">
                          <div class="flex-grow">
                            <div class="text-sm self-center mx-1">
                              {{ activeConversation.form_name }}
                            </div>
                          </div>
                        </div>
                        <div class="flex text-sm justify-between ">
                          <div>{{ activeConversation.formatted_last_interaction }}</div>
                        </div>
                        <div class="flex text-sm justify-between"
                             v-if="activeConversation.referral_id">
                          <div><b>Anuncio WhatsApp: </b>
                            {{ activeConversation.referral_headline }}
                          </div>
                        </div>
                        <div class="flex text-sm justify-between"
                             v-if="activeConversation.awarded">
                          <div><b>Premiada</b></div>
                        </div>
                        <div class="flex text-sm justify-between"
                             v-if="activeConversation.week_awarded">
                          <div><b>Premiada Semanal</b></div>
                        </div>
                        <div class="flex text-sm justify-between"
                             v-if="activeConversation.campaign_name">
                          <div><b>Campaña:</b> {{ activeConversation.campaign_name }}</div>
                        </div>
                      </div>
                    </div>
                    <div class="flex w-5/12 sm:w-4/12 justify-end">
                      <button class="bg-green-700 h-10 flex rounded-lg m-1 my-2 p-2 text-white"
                              @click="save()">
                        <RefreshIcon class="mr-2 h-5 w-5" v-if="saving" aria-hidden="true"/>
                        Guardar
                      </button>
                      <!--eslint-disable-next-line-->
                      <div class="p-2 hover:bg-gray-200 cursor-pointer"
                           @click="closeConversation()">
                        <XIcon class="h-7 w-7 mr-1" aria-hidden="true"/>
                      </div>
                    </div>
                  </div>
                  <div class="w-full flex">
                    <div class="flex w-6/12">
                      <div class="p-1 text-sm font-medium text-gray-700">
                        <UserIcon class="m-1 h-7 w-7" aria-hidden="true"/>
                      </div>
                      <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500 h-10
                                 text-gray-900 rounded-b-md focus:outline-none w-full
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="user1" v-model="activeConversation.user_id"
                              name="user1">
                        <option :value="null" class="px-3 py-2 text-sm">
                          -- Selecciona un asesor --
                        </option>
                        <option v-for="(user) in conversationsUsersList"
                                v-bind:key="user.id" :value="user.id"
                                class="px-3 py-2 text-sm">
                          {{ user.name }}
                        </option>
                      </select>
                    </div>
                    <div class="flex w-6/12">
                      <div class="p-1 text-sm font-medium text-gray-700">
                        <ClipboardListIcon class="m-1 h-7 w-7" aria-hidden="true"/>
                      </div>
                      <select class="my-1 bg-white appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500 h-10
                                 text-gray-900 rounded-b-md focus:outline-none w-full
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="status1"
                              v-model="activeConversation.company_status_id"
                              name="status1">
                        <option :value="null" class="px-3 py-2 text-sm">
                          -- Selecciona un estado --
                        </option>
                        <option v-for="(status) in statuses"
                                v-bind:key="status.id" :value="status.id"
                                class="px-3 py-2 text-sm">
                          {{ status.name }}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div class="w-full flex">
                    <div class="flex p-2 justify-center">
                      <img alt="leadbox logo" class="w-7 h-7 mx-2" src="@/assets/bot3.svg">
                      <div>
                        <Toggle v-model="activeConversation.active_bot"
                                @change="save()"/>
                      </div>
                    </div>
                    <template v-if="activeCompany.uuid === '739cca8d-27e2-4fad-9683-90c452e24735'">
                      <div class="flex p-2 justify-center">
                        <p class="px-2">Premiada</p>
                        <div>
                          <Toggle v-model="activeConversation.awarded"
                                  @change="save()"/>
                        </div>
                      </div>
                      <div class="flex p-2 justify-center">
                        <p class="px-2">Premiada Semanal</p>
                        <div>
                          <Toggle v-model="activeConversation.week_awarded"
                                  @change="save()"/>
                        </div>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
              <!--section selector-->
              <div class="w-full h-12 flex bg-white px-4 ">
                <div class="p-3 mx-2 cursor-pointer border-leadbox-900"
                     @click="selectTab('chat')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'chat' }"
                     @keydown="selectTab('chat')">Chat
                </div>
                <div class="p-3 mx-2 cursor-pointer border-leadbox-900"
                     @click="selectTab('info')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'info' }"
                     @keydown="selectTab('info')">Datos
                </div>
                <div class="p-3 mx-2 cursor-pointer border-leadbox-900"
                     @click="selectTab('history')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'history' }"
                     @keydown="selectTab('history')">Historial
                </div>
                <div class="p-3 mx-2 cursor-pointer border-leadbox-900"
                     @click="selectTab('rule-history')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'rule-history' }"
                     @keydown="selectTab('rule-history')">Historial de bot
                </div>
                <div class="p-3 mx-2 cursor-pointer border-leadbox-900"
                     @click="selectTab('leads')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'leads' }"
                     @keydown="selectTab('leads')">Leads
                </div>
                <div class="p-3 mx-2 cursor-pointer border-leadbox-900"
                     @click="selectTab('appointments')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'appointments' }"
                     @keydown="selectTab('appointments')">Citas
                </div>
                <div class="p-3 mx-2 cursor-pointer border-leadbox-900"
                     @click="selectTab('campaign')" v-if="activeConversation.referral_id"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'campaign' }"
                     @keydown="selectTab('campaign')">Origen
                </div>
                <div class="p-3 mx-2 cursor-pointer border-leadbox-900"
                     @click="selectTab('create-lead')" @keydown="selectTab('create-lead')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'create-lead' }">Crear lead
                </div>
                <div class="p-3 mx-2 cursor-pointer border-leadbox-900"
                     @click="selectTab('create-appointment')"
                     @keydown="selectTab('create-appointment')"
                     v-bind:class="{ 'border-b-4' : selectedTab === 'create-appointment' }">
                  Crear cita
                </div>
              </div>
              <!--conversation content-->
              <div class="w-full h-fit overflow-y-auto flex-grow bg-scroll bg-cover"
                   :style="bgChat" id="messages_container">
                <template v-if="selectedTab === 'chat'">
                  <template v-if="isLoadingConversationMessages">
                    <p class="text-center mt-10">Cargando mensajes...</p>
                  </template>
                  <template v-else>
                    <template v-if="messages.length < 1">
                      <h1 class="text-center mt-10">No hay mensajes</h1>
                    </template>
                    <template v-else>
                      <div class="w-full flex justify-center"
                           v-if="nextPageUrlMessages">
                        <div class="group relative flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600 cursor-pointer"
                             @click="getMoreMessages" @keydown="getMoreMessages">
                          <template v-if="!isLoadingMoreMessages">
                            <p>Ver mensajes más antiguos</p>
                          </template>
                          <template v-else>
                            <p>Cargando...</p>
                          </template>
                        </div>
                      </div>
                      <template v-for="(message) in messages" v-bind:key="message.id">
                        <IncomeMessage :message="message" v-if="message.sender_id"
                                       @get-messages="getMessages"/>
                        <OutcomeMessage :message="message" v-if="!message.sender_id"
                                        @get-messages="getMessages"/>
                      </template>
                    </template>
                  </template>
                  <template v-if="show_quick_replies ">
                    <div class="flex-col w-full ">
                      <div class="flex justify-end">
                        <!--eslint-disable-next-line-->
                        <div class="p-1 hover:bg-gray-200 cursor-pointer rounded"
                             @click="show_quick_replies = false;">
                          <XIcon class="h-7 w-7 mr-1" aria-hidden="true" alt="close"/>
                        </div>
                      </div>
                      <div class="w-full flex bg-transparent justify-center max-h-40 flex-row
                            border-b-2 border-leadbox-900 overflow-x-auto overflow-y-auto">
                        <template v-for="(reply) in replies" v-bind:key="reply.id">
                          <!--eslint-disable-next-line-->
                          <button class="m-1 mx-2 p-2 rounded-2xl border-2 border-leadbox-900
                                    bg-gray-200 text-sm w-1/2 lg:w-1/3 h-max"
                                  data-toggle="tooltip" data-placement="top"
                                  :title="reply.text" :alt="reply.text"
                                  @click="setQuickReply(reply)">
                            {{ reply.title }}
                          </button>
                        </template>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-if="selectedTab === 'info'">
                  <template v-if="isLoadingSender">
                    <p class="text-center mt-6"> Cargando ...</p>
                  </template>
                  <template v-else>
                    <!--<h1 class="font-semibold text-center mt-10">CONTACTO</h1>-->
                    <div class="md:flex">
                      <div class="md:flex md:flex-col md:w-1/2 w-full justify-start p-4"
                           v-if="activeConversation.sender_id">
                        <h6 class="w-full text-left my-1 font-semibold">DATOS PERSONALES</h6>
                        <div class="form-group p-1">
                          <!--eslint-disable-next-line-->
                          <label class="w-full text-left"><b>Nombre:</b></label>
                          <input type="text" v-model="activeSender.name"
                                 class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                        </div>
                        <div class="form-group p-1">
                          <div class="flex">
                            <!--eslint-disable-next-line-->
                            <label class="w-full text-left"><b>Teléfono:</b></label>
                            <!--eslint-disable-next-line-->
                            <a v-if="activeSender.phone" title="Llamar"
                               :href="'tel:'+activeSender.phone">
                              <PhoneOutgoingIcon class="mx-3 h-6 w-6" aria-hidden="true"/>
                            </a>
                            <a v-if="activeSender.phone" title="Enviar mensaje"
                               :href="'https://api.whatsapp.com/send?phone='+activeSender.phone"
                               target="_blank">
                              <img class="w-6 h-6 mx-3" src="@/assets/whatsapp.png"
                                   alt="whatsapp">
                            </a>
                          </div>
                          <!--eslint-disable-next-line-->
                          <input type="text" v-model="activeSender.phone"
                                 class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
                        </div>
                        <div class="form-group p-1" v-if="activeSender.secondary_phone">
                          <div class="flex">
                            <!--eslint-disable-next-line-->
                            <label class="w-full text-left"><b>Teléfono Secundarios:</b></label>
                            <!--eslint-disable-next-line-->
                            <a v-if="activeSender.secondary_phone" title="Llamar"
                               :href="'tel:'+activeSender.secondary_phone">
                              <PhoneOutgoingIcon class="mx-3 h-6 w-6" aria-hidden="true"/>
                            </a>
                            <a v-if="activeSender.secondary_phone" title="Enviar mensaje"
                               :href="'https://api.whatsapp.com/send?phone='
                               +activeSender.secondary_phone"
                               target="_blank">
                              <img class="w-6 h-6 mx-3" src="@/assets/whatsapp.png"
                                   alt="whatsapp">
                            </a>
                          </div>
                          <!--eslint-disable-next-line-->
                          <input type="text" v-model="activeSender.secondary_phone"
                                 class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
                        </div>
                        <div class="form-group p-1">
                          <!--eslint-disable-next-line-->
                          <label class="w-full text-left"><b>Email:</b></label>
                          <input type="email" v-model="activeSender.email"
                                 class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                 focus:border-indigo-500 focus:z-10 sm:text-sm">
                        </div>
                        <div class="form-group p-1">
                          <!--eslint-disable-next-line-->
                          <label class="w-full text-left"><b>Idetificación:</b></label>
                          <input type="text" v-model="activeSender.document"
                                 class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                  rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
                        </div>
                        <div class="form-group p-1">
                          <!--eslint-disable-next-line-->
                          <label class="w-full text-left"><b>Ciudad:</b></label>
                          <input type="text" v-model="activeSender.city"
                                 class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                  rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
                        </div>
                        <div class="form-group p-1">
                          <!--eslint-disable-next-line-->
                          <label class="w-full text-left"><b>Dirección:</b></label>
                          <input type="text" v-model="activeSender.address"
                                 class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
                        </div>
                        <div class="form-group p-1">
                          <!--eslint-disable-next-line-->
                          <label class="w-full text-left"><b>Género:</b></label>
                          <input type="text" v-model="activeSender.gender"
                                 class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
                        </div>
                        <button @click="updateSender()"
                                class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-sky-600 hover:bg-sky-700">
                          <RefreshIcon class="mr-2 h-5 w-5" v-if="savingSender" aria-hidden="true"/>
                          Guardar
                        </button>
                      </div>
                      <div class="md:flex md:flex-col md:w-1/2 w-full justify-start p-4">
                        <h6 class="text-left my-1 font-semibold title-header">NOTAS</h6>
                        <template v-if="isLoadingConversationNotes">
                          Cargando Notas...
                        </template>
                        <template v-else>
                          <template v-for="(note) in notes" v-bind:key="note.id">
                            <div class="p-4 flex flex-col rounded-lg border-2 mt-2 border-black-600
                            shadow-xl shadow-black my-2">
                              <p class="font-semibold">{{ note.data }}</p>
                              <p>{{ note.user?.name }}</p>
                              <p>{{ note.created_at?.formated_date }}</p>
                            </div>
                          </template>
                        </template>
                        <template v-if="showNoteInterface">
                          <div class="form-group">
                            <!--eslint-disable-next-line-->
                            <label for="newNote"><b>Nueva Nota</b></label>
                            <textarea class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md" v-model="note"
                                      name="newNote" id="newNote">
                            </textarea>
                          </div>
                          <div class="flex">
                            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                                    @click="showNoteInterface = false; note = '';"
                                    type="button">Cancelar
                            </button>
                            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                                    @click="createNote()">Aceptar
                            </button>
                          </div>
                        </template>
                        <template v-else>
                          <div class="flex justify-end">
                            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                                    @click="showNoteInterface = true;">Crear nota
                            </button>
                          </div>
                        </template>
                        <template v-if="activeConversation.fields">
                          <h6 class="text-left my-1 font-semibold title-header">CAMPOS BOT</h6>
                          <div class="flex"
                               v-for="(field, key) in activeConversation.fields"
                               v-bind:key="key">
                            <template>
                              <div class="w-4/12 p-2"><b>{{ key }}:</b></div>
                              <div class="w-8/12">
                                <!--eslint-disable-next-line-->
                                <input class="my-2 appearance-none rounded-none relative block
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none w-full
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" type="text"
                                       v-model="activeConversation.fields[key]">
                              </div>
                            </template>
                          </div>
                        </template>
                      </div>
                    </div>
                  </template>
                </template>
                <template v-if="selectedTab === 'history'">
                  <div class="p-4 overflow-y-auto">
                    <template v-if="isLoadingConversationHistory">Cargando...</template>
                    <template v-else>
                      <template v-if="conversationHistories.length>0">
                        <div v-for="(history) in conversationHistories"
                             v-bind:key="history.id"
                             class="p-2 flex rounded-lg border-2 border-black-600 shadow-xl
                               shadow-black my-3 bg-gray-100">
                          <div class="rounded-full text-white self-center p-4"
                               :style="{ 'background-color' : history.status?.color }">
                          </div>
                          <div class="flex flex-col m-4 my-2">
                            <p class="text-md">
                              {{ history.status?.name }}
                              {{ history.user ? ' - ' + history.user.name : '' }}
                            </p>
                            <p class="text-sm">{{ history.created_at.formated_date }}</p>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <p class="text-center text-center">No hay Historial</p>
                      </template>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'rule-history'">
                  <div class="p-4 overflow-y-auto">
                    <template v-if="isLoadingConversationRuleHistory">Cargando...</template>
                    <template v-else>
                      <template v-if="conversationRuleHistories.length>0">
                        <div v-for="(history) in conversationRuleHistories"
                             v-bind:key="history.id"
                             class="p-2 flex rounded-lg border-2 border-black-600 shadow-xl
                               shadow-black my-3 bg-gray-100">
                          <div class="rounded-full text-white self-center p-4"
                               :style="{ 'background-color' : history.status?.color }">
                          </div>
                          <div class="flex flex-col m-4 my-2">
                            <p class="text-md">
                              {{ history.rule?.name }}
                            </p>
                            <p v-for="(message, key) in history.rule?.messages"
                               v-bind:key="key">
                              {{message.body}}
                            </p>
                            <p class="text-sm">{{ history.created_at.formated_date }}</p>
                          </div>
                        </div>
                      </template>
                      <template v-else>
                        <p class="text-center text-center">No hay Historial de Reglas</p>
                      </template>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'leads'">
                  <template v-if="isLoadingConversationLeads">
                    <p class="text-center">Cargando leads...</p>
                  </template>
                  <template v-else>
                    <div class="flex flex-col p-4">
                      <template v-if="conversationLeads.length > 0">
                        <template v-for="(lead) in conversationLeads" v-bind:key="lead.id">
                          <div class="p-4 flex flex-col rounded-lg border-2 border-gray-300
                            shadow-md shadow-black my-2 cursor-pointer bg-gray-100"
                               @click="goToLead(lead?.lead?.uuid);"
                               @keydown="goToLead(lead?.lead?.uuid);">
                            <p class="font-semibold">{{ lead?.lead?.name }}</p>
                            <div class="flex" v-if="lead?.lead?.status">
                              <p><b>Estado: </b></p>
                              <div class="rounded-full text-white p-2 text-xs m-1"
                                   :style="{backgroundColor: lead?.lead?.status?.color}">
                              </div>
                              <p>{{ lead.lead?.status?.name }}</p>
                            </div>
                            <p class="text-small"><b>Fecha de creación: </b>
                              {{ lead.lead?.created_at?.date }}
                            </p>
                          </div>
                        </template>
                      </template>
                      <template v-else>
                        <p class="text-center my-6">Conversación no tiene leads</p>
                      </template>

                    </div>
                  </template>
                </template>
                <template v-if="selectedTab === 'appointments'">
                  <template v-if="isLoadingConversationAppointments">
                    <p class="text-center">Cargando citas...</p>
                  </template>
                  <template v-else>
                    <div class="flex flex-col p-4">
                      <template v-if="conversationAppointments.length < 1">
                        <p class="text-center my-6">Conversación no tiene citas</p>
                      </template>
                      <template v-else>
                        <template v-for="(appointment) in conversationAppointments"
                                  v-bind:key="appointment.id">
                          <div class="p-4 flex flex-col rounded-lg border-2 border-gray-300
                            shadow-md shadow-black my-2 cursor-pointer bg-gray-100"
                               @click="goToAppointment(appointment.appointment.uuid);"
                               v-if="appointment.appointment"
                               @keydown="goToAppointment(appointment.appointment.uuid);">
                            <div class="flex flex-col">
                              <p class="text-md">
                                <b>{{ appointment.appointment?.patient_name }}</b>
                              </p>
                              <p class="text-md" v-if="appointment.appointment?.patient_phone">
                                <b>Teléfono:</b> {{ appointment.appointment?.patient_phone }}
                              </p>
                              <p class="text-md">
                                <b>Email:</b> {{ appointment.appointment?.patient_email }}
                              </p>
                              <p class="text-md" v-if="appointment.appointment?.patient_document">
                                <b>Identificación:</b>
                                {{ appointment.appointment?.patient_document }}
                              </p>
                              <p class="text-md" v-if="appointment.appointment?.medical_center_id">
                                <b>Centro Médico:</b>
                                {{ appointment.appointment?.medical_center_city }} -
                                {{ appointment.appointment?.medical_center_name }}
                              </p>
                              <p class="text-md" v-if="appointment.appointment?.doctor_id">
                                <b>Tratamiento:</b>
                                {{ appointment.appointment?.doctor_service }}
                                {{ appointment.appointment?.doctor_name }}
                              </p>
                              <p class="text-md" v-if="appointment.appointment?.specialty_id">
                                <b>Especialidad:</b>
                                {{ appointment.appointment?.specialty_name }}
                              </p>
                              <p class="text-md" v-if="appointment.appointment?.procedure_id">
                                <b>Zona:</b>
                                {{ appointment.appointment?.procedure_name }}
                              </p>
                              <p class="text-md" v-if="appointment.appointment?.user_id">
                                <b>Asignado a:</b> {{ appointment.appointment?.user?.name }}
                              </p>
                              <p class="text-md">
                                <b>Fecha:</b>
                                {{ appointment.appointment?.date }}
                                {{ appointment.appointment?.start_time }} -
                                {{ appointment.appointment?.end_time }}
                              </p>
                            </div>
                          </div>
                        </template>
                      </template>
                    </div>
                  </template>
                </template>
                <template v-if="selectedTab === 'campaign'">
                  <div class="flex flex-col p-4">
                    <p><b>ID:</b> {{ activeConversation.referral_id }}</p>
                    <p><b>Tipo:</b> {{ activeConversation.referral_type }}</p>
                    <p><b>Url:</b> <a :href="activeConversation.referral_url" target="_blank"
                                      class="text-sky-500 underline">
                      {{ activeConversation.referral_url }}</a></p>
                    <p> {{ activeConversation.referral_headline }}</p>
                    <p v-html="activeConversation.referral_body"></p>
                    <template v-if="activeConversation.campaign_name">
                      <p><b>Campaña:</b> {{ activeConversation.campaign_name }}</p>
                    </template>
                  </div>
                </template>
                <template v-if="selectedTab === 'create-lead'">
                  <div class="justify-start p-4">
                    <template v-if="isLoadingCreateLead">
                      <p class="text-center">Cargando...</p>
                    </template>
                    <template v-else>
                      <h6 class="text-left my-1 font-semibold ">CREAR LEAD</h6>
                      <p class="my-1">Formulario:</p>
                      <select v-model="newLead.form" @change="setFields()"
                              class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                        <option disabled :value="null">-- Seleccione un formulario --</option>
                        <option v-for="(form) in forms" v-bind:key="form.id" :value="form">
                          {{ form.name }}
                        </option>
                      </select>
                      <template v-if="newLead.form">
                        <div class="flex">
                          <div class="w-1/2 p-2"><b>Nombre:</b></div>
                          <div class="w-1/2">
                            <!--eslint-disable-next-line-->
                            <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newLead.name" type="text">
                          </div>
                        </div>
                        <div class="flex">
                          <div class="w-1/2 p-2"><b>Email:</b></div>
                          <div class="w-1/2">
                            <!--eslint-disable-next-line-->
                            <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newLead.email" type="text">
                          </div>
                        </div>
                        <div class="flex">
                          <div class="w-1/2 p-2"><b>Teléfono:</b></div>
                          <div class="w-1/2">
                            <!--eslint-disable-next-line-->
                            <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newLead.phone" type="tel">
                          </div>
                        </div>
                        <template v-if="newLead.fields.length>0">
                          <div class="flex" v-for="(field, key) in newLead.fields" v-bind:key="key">
                            <div class="w-1/2 p-2"><b>{{ field.key }}:</b></div>
                            <div class="w-1/2">
                              <!--eslint-disable-next-line-->
                              <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="field.data" type="text">
                            </div>
                          </div>
                        </template>
                        <div class="w-full flex">
                          <div class="m-1">
                            <Toggle v-model="newLead.addProduct"/>
                          </div>
                          <p class="ml-1">Agregar producto?</p>
                        </div>
                        <template v-if="newLead.addProduct">
                          <div>
                            <!--eslint-disable-next-line-->
                            <label class="w-full text-left" for="newLeadCategory">
                              <b>Categoría:</b>
                            </label>
                            <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm"
                                    :disabled="companyCategories.length < 1"
                                    id="newLeadCategory" v-model="newLead.category"
                                    name="leadCategory">
                              <option :value="null" class="px-3 py-2 text-sm">
                                -- Selecciona categoría --
                              </option>
                              <option v-for="(category) in companyCategories"
                                      v-bind:key="category.id" :value="category"
                                      class="px-3 py-2 text-sm">
                                {{ category.name }}
                              </option>
                            </select>
                          </div>
                          <div>
                            <template v-if="newLead.category">
                              <!--eslint-disable-next-line-->
                              <label class="w-full text-left" for="leadProduct">
                                <b>Producto:</b>
                              </label>
                              <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="newLead.category.products.length < 1"
                                      id="leadProduct" v-model="newLead.product" name="leadProduct">
                                <option :value="null" class="px-3 py-2 text-sm">
                                  -- Selecciona uno --
                                </option>
                                <option v-for="(prod) in newLead.category.products"
                                        v-bind:key="prod.uuid" :value="prod"
                                        class="px-3 py-2 text-sm">
                                  {{ prod.name }}
                                </option>
                              </select>
                            </template>
                          </div>
                        </template>
                        <div class="w-full flex">
                          <div class="m-1">
                            <Toggle v-model="newLead.sameUser"/>
                          </div>
                          <p class="ml-1">asignar al usuario asignado a esta conversación?</p>
                        </div>
                        <div class="w-full flex">
                          <div class="m-1">
                            <Toggle v-model="newLead.addUser"/>
                          </div>
                          <p class="ml-1">Forzar asignación de usuario?</p>
                        </div>
                        <template v-if="newLead.addUser">
                          <!--eslint-disable-next-line-->
                          <label class="w-full text-left" for="leadUser">
                            <b>Usuario:</b>
                          </label>
                          <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="conversationsUsersList.length < 1"
                                  id="leadUser" v-model="newLead.user" name="leadUser">
                            <option :value="null" class="px-3 py-2 text-sm">
                              -- Selecciona uno --
                            </option>
                            <option v-for="(usr) in conversationsUsersList"
                                    v-bind:key="usr.id" :value="usr.id"
                                    class="px-3 py-2 text-sm">
                              {{ usr.name }}
                            </option>
                          </select>
                        </template>
                        <div class="flex">
                          <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                                  @click="createLead()">Crear
                          </button>
                        </div>
                      </template>
                    </template>

                  </div>
                </template>
                <template v-if="selectedTab === 'create-appointment'">
                  <div class="justify-start p-4">
                    <template v-if="isLoadingCreateAppointment">
                      <p class="text-center">Cargando...</p>
                    </template>
                    <template v-else>
                      <h6 class="text-left my-1 font-semibold ">CREAR CITA</h6>
                      <select v-model="newAppointment.connection"
                              class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsCities">
                        <option disabled :value="null">-- Seleccione la conexión --</option>
                        <option v-for="(connection) in appointmentConnections"
                                v-bind:key="connection.id"
                                :value="connection">
                          {{ connection.name }}
                        </option>
                      </select>
                      <template v-if="newAppointment.connection">
                        <div class="flex flex-col md:flex-row">
                          <div class="flex-col w-full md:w-1/2 px-1">
                            <div class="p-2"><b>Nombre y Apellido:</b></div>
                            <!--eslint-disable-next-line-->
                            <input class="m-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.name" type="text">
                          </div>
                        </div>
                        <div class="flex flex-col md:flex-row">
                          <div class="flex-col w-full md:w-1/2 px-1">
                            <div class="p-2"><b>Teléfono:</b></div>
                            <!--eslint-disable-next-line-->
                            <input class="m-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.phone" type="tel">
                          </div>
                          <div class="flex-col w-full md:w-1/2 px-1">
                            <div class="p-2"><b>Email:</b></div>
                            <!--eslint-disable-next-line-->
                            <input class="m-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.email" type="text">
                          </div>
                        </div>
                        <div class="flex">
                          <input type="radio" id="cedula" value="cedula" class="m-3"
                                 v-model="newAppointment.type">
                          <!--eslint-disable-next-line-->
                          <label for="cedula" class="my-4">Cédula</label>
                          <input type="radio" id="ruc" name="ruc" value="ruc" class="m-3"
                                 v-model="newAppointment.type">
                          <!--eslint-disable-next-line-->
                          <label for="ruc" class="my-4">RUC</label>
                          <input type="radio" id="passport" name="passport" value="passport"
                                 class="m-3" v-model="newAppointment.type">
                          <!--eslint-disable-next-line-->
                          <label for="passport" class="my-4 mr-4">Pasaporte</label>
                          <input
                            id="document" name="identificacion"
                            placeholder="Número de identificación" type="text"
                            v-model="newAppointment.document"
                            class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                        </div>
                        <div class="flex-col" >
                          <p class="my-1"><b>Número de Ficha:</b></p>
                          <!--eslint-disable-next-line-->
                          <input class="m-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.number" type="text">
                        </div>
                        <div class="flex-col" v-if="newAppointment.cities.length > 0">
                          <p class="my-1"><b>Ciudad:</b></p>
                          <select v-model="newAppointment.city"
                                  class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsMedicalCenters">
                            <option disabled :value="null">-- Seleccione la ciudad --</option>
                            <option v-for="(city) in newAppointment.cities" v-bind:key="city.id"
                                    :value="city">
                              {{ city.name }}
                            </option>
                          </select>
                        </div>
                        <div class="flex-col"
                             v-if="newAppointment.sectors.length > 0 && newAppointment.city">
                          <p class="my-1"><b>Sector:</b></p>
                          <select v-model="newAppointment.sector"
                                  class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsSectorMedicalCenters">
                            <option disabled :value="null">-- Seleccione el sector --</option>
                            <option v-for="(sector, sk) in newAppointment.sectors"
                                    v-bind:key="sk" :value="sector.sector">
                              {{ sector.sector }}
                            </option>
                          </select>
                        </div>
                        <div class="flex-col"
                             v-if="newAppointment.medicalCenters.length > 0 && newAppointment.city">
                          <p class="my-1"><b>Centro:</b></p>
                          <select v-model="newAppointment.medicalCenter"
                                  class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsDoctorsAndSpecialties">
                            <option disabled :value="null">-- Seleccione el local --</option>
                            <option v-for="(medicalCenter) in newAppointment.medicalCenters"
                                    v-bind:key="medicalCenter.id"
                                    :value="medicalCenter">
                              {{ medicalCenter.name }}
                            </option>
                          </select>
                        </div>
                        <div class="flex-col"
                             v-if="newAppointment.doctors.length > 0 && newAppointment.city
                             && newAppointment.medicalCenter">
                          <p class="my-1"><b>Tratamientos:</b></p>
                          <select v-model="newAppointment.doctor"
                                  class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsSpecialtiesByDoctor">
                            <option disabled :value="null">-- Seleccione el local --</option>
                            <option v-for="(doctor) in newAppointment.doctors"
                                    v-bind:key="doctor.id"
                                    :value="doctor">
                              {{ doctor.name }}
                            </option>
                          </select>
                        </div>
                        <div class="flex-col"
                             v-if="newAppointment.specialties.length > 0 && newAppointment.city
                             && newAppointment.medicalCenter">
                          <p class="my-1"><b>Zonas:</b></p>
                          <select v-model="newAppointment.specialty"
                                  class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="getAppointmentsSchedules">
                            <option disabled :value="null">-- Seleccione el local --</option>
                            <option v-for="(specialty) in newAppointment.specialties"
                                    v-bind:key="specialty.id"
                                    :value="specialty">
                              {{ specialty.name }}
                            </option>
                          </select>
                        </div>
                        <div class="flex-col"
                             v-if="newAppointment.medicalCenter && newAppointment.city">
                          <p class="my-1"><b>Fecha:</b></p>
                          <!--eslint-disable-next-line-->
                          <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newAppointment.date" type="date"
                                 @change="getAppointmentsSchedules">
                        </div>
                        <div class="flex-col"
                             v-if="newAppointment.medicalCenter && newAppointment.city
                             && newAppointment.date && newAppointment.schedules.length > 0">
                          <p class="my-1"><b>Horario:</b></p>
                          <select v-model="newAppointment.schedule"
                                  class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm">
                            <option disabled :value="null">-- Seleccione el horario --</option>
                            <option v-for="(schedule) in newAppointment.schedules"
                                    v-bind:key="schedule.id"
                                    :value="schedule">
                              {{ schedule.startLabel }} - {{ schedule.endLabel }}
                            </option>
                          </select>
                        </div>
                        <div class="flex" v-if="newAppointment.schedule">
                          <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                                  @click="createAppointment()">Crear
                          </button>
                        </div>
                      </template>
                    </template>

                  </div>
                </template>
              </div>

              <!--bottom actions chat-->
              <DropZone @files-dropped="addFiles" @active="setDropZoneActive"
                        v-if="selectedTab === 'chat'">
                <template v-if="dropzoneActive">
                  <div class="w-full flex bg-transparent justify-center ">
                    <div class="rounded-lg m-2 bg-white border-dashed border-2 text-gray-400
                    border-gray-400 p-3 w-full">
                      <p class="text-md text-center">Suelta tus archivos aquí, para enviarlos</p>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="w-full flex bg-transparent ">
                    <PaperClipIcon class="mx-1 my-3 text-gray-400 w-6 h-6 cursor-pointer"
                                   @click="openFilePicker()" aria-hidden="true"
                                   data-toggle="tooltip" data-placement="top"
                                   v-if="canSend"
                                   title="Adjuntar" alt="Adjuntar"/>
                    <!--eslint-disable-next-line-->
                    <img class="w-8 h-8 m-1 my-2 rounded-lg cursor-pointer"
                         src="@/assets/replies.png"
                         @click="showQuickReplies()" v-if="replies.length > 0 && canSend"
                         data-toggle="tooltip" data-placement="top"
                         title="Respuestas Rápidas" alt="Respuestas Rápidas">
                    <template v-if="!canSend">
                      <div class="flex-grow focus:outline-none text-sm bg-white p-2 text-gray-700
                      mx-2 my-1">
                        No puedes enviar mensajes pasadas las 72h de la última interacción
                        <!--eslint-disable-next-line-->
                        <span class="underline text-sky-400 cursor-pointer"
                              @click="showTemplatesModal = true;">
                          Enviar platilla de mensaje</span>
                      </div>
                    </template>
                    <template v-else>
                      <!--eslint-disable-next-line-->
                      <input placeholder="Escribe tu mensaje...." type="text" v-model="text"
                             class="flex-grow focus:outline-none bg-white text-gray-700 mx-2"
                             v-on:keyup.enter="sendMessage('text')" @paste="onPaste" id="text"
                      />
                    </template>
                    <div class="px-1 emoji-mobile" v-if="canSend">
                      <discord-picker :value="text" :apiKey="''"
                                      @update:value="value = $event" @emoji="setEmoji"
                                      @gif="setGif" :categories="emojiCategories" :showEmoji="true"
                      />
                    </div>
                    <template v-if="canSend">
                      <div>
                        <button class="border-l border-white rounded-l-lg m-2 my-1 mr-0 text-white
                              bg-teal-900" data-toggle="tooltip" data-placement="top"
                                title="Mensaje de voz" alt="Mensaje de voz"
                                @click="showRecordModal = true;"
                                :disabled="!canSend"
                                v-if="text === '' || text === null"
                                v-bind:class="{ 'opacity-30' : !canSend}">
                          <MicrophoneIcon class="m-2 mx-3 h-5 w-6 cursor-pointer"
                                          @click="showRecordModal = true;"
                                          aria-hidden="true"/>
                        </button>
                        <button class="border-l border-white rounded-l-lg m-2 my-1 mr-0 text-white
                              bg-teal-900" data-toggle="tooltip" data-placement="top"
                                title="Enviar" alt="Enviar"
                                @click="sendMessage('text')"
                                :disabled="!canSend"
                                v-if="text !== '' && text !== null"
                                v-bind:class="{ 'opacity-30' : text === '' || text === null ||
                            !canSend}">
                          <RefreshIcon class="m-2 mx-3 h-5 w-6" v-if="isLoadingMessage"
                                       aria-hidden="true"/>
                          <img src="@/assets/send.svg" v-else class="m-2 mx-3 h-5 w-6 text-white"
                               alt="send">
                        </button>
                      </div>
                      <MessagesDropDown @open-modal="openModal"/>
                    </template>

                  </div>
                </template>
                <!--eslint-disable-next-line-->
                <input ref="fileInput" type="file" id="file-input" multiple
                       @change="onInputChange" class="hidden"/>
              </DropZone>
              <!--modals-->
              <!--enviar producto-->
              <ModalBox
                :showing="showProductsModal" @close="showProductsModal = false"
              >
                <div class="flex">
                  <p class="text-black"><b>Enviar producto</b></p>
                </div>
                <div class="w-full flex flex-col">
                  <div>
                    <!--eslint-disable-next-line-->
                    <label class="w-full text-left" for="productSection"><b>Secciones:</b></label>
                    <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="setCategories()"
                            :disabled="sections.length < 1"
                            id="productSection" v-model="product.section" name="productSection">
                      <option :value="null" class="px-3 py-2 text-sm">--Seleccione uno--</option>
                      <option :value="'todos'" class="px-3 py-2 text-sm">Todos</option>
                      <option v-for="(section) in sections"
                              v-bind:key="section.id" :value="section"
                              class="px-3 py-2 text-sm">
                        {{ section.name }}
                      </option>
                    </select>
                  </div>
                  <div>
                    <!--eslint-disable-next-line-->
                    <label class="w-full text-left" for="productCategory"><b>Categorias:</b></label>
                    <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="setProducts()"
                            :disabled="categories.length < 1"
                            id="productCategory" v-model="product.category" name="productSection">
                      <option :value="null" class="px-3 py-2 text-sm">--Seleccione uno--</option>
                      <option v-for="(category) in categories"
                              v-bind:key="category.id" :value="category"
                              class="px-3 py-2 text-sm">
                        {{ category.name }}
                      </option>
                    </select>
                  </div>
                  <div>
                    <!--eslint-disable-next-line-->
                    <label class="w-full text-left" for="product"><b>Productos:</b></label>
                    <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="products.length < 1"
                            id="product" v-model="product.product" name="productSection">
                      <option :value="null" class="px-3 py-2 text-sm">--Seleccione uno--</option>
                      <option v-for="(prod) in products"
                              v-bind:key="prod.id" :value="prod"
                              class="px-3 py-2 text-sm">
                        {{ prod.name }}
                      </option>
                    </select>
                  </div>
                  <div v-if="product.product">
                    <!--eslint-disable-next-line-->
                    <label class="w-full text-left" for="product">
                      <b>Seleccionar que compartir:</b>
                    </label>
                    <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm"
                            id="selectedSend" v-model="product.selected" name="productSection">
                      <option :value="null" class="px-3 py-2 text-sm">--Seleccione uno--</option>
                      <option v-if="product.product.file" value="datasheet">Ficha Técnica</option>
                      <option v-if="product.product.video_url" value="video">
                        Video del Producto
                      </option>
                      <option v-if="product.product.image" value="principal">
                        Imágen Principal del Producto
                      </option>
                      <option v-if="product.product.quotation_image" value="quotation">
                        Imágen Cotización del Producto
                      </option>
                      <option v-if="product.product.combo_image" value="promo">
                        Imágen Promo del Producto
                      </option>
                    </select>
                  </div>
                  <div class="form-group" v-if="product.product">
                    <!--eslint-disable-next-line-->
                    <label for="productCaption"><b>Descripción</b></label>
                    <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="product.caption" type="text"
                           name="mapName" id="productCaption">
                  </div>
                  <div class="flex">
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                            @click="closeProductModal()"
                            type="button">Cerrar
                    </button>
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                            @click="sendProduct()">Aceptar
                    </button>
                  </div>
                </div>
                <!-- Put your modal content here -->
              </ModalBox>
              <!--enviar mensaje interactivo-->
              <ModalBox
                :showing="showInteractiveModal" @close="showInteractiveModal = false"
              >
                <div class="flex">
                  <p class="text-black"><b>Enviar mensaje interactivo</b></p>
                </div>
                <div class="w-full flex flex-col">
                  <div>
                    <!--eslint-disable-next-line-->
                    <label class="w-full text-left" for="interactiveType"><b>Tipo:</b></label>
                    <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm"
                            id="interactiveType" v-model="interactive.type" name="interactiveType">
                      <option value="">Seleccione tipo</option>
                      <option :value="'list'">Listado</option>
                      <option :value="'button'">Botones</option>
                    </select>
                  </div>
                  <template v-if="interactive.type === 'list' || interactive.type === 'button'">
                    <div class="form-group">
                      <!--eslint-disable-next-line-->
                      <label for="interactiveTemplateList"><b>Plantilla</b></label>
                      <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="interactive.template"
                              name="interactiveTemplate" id="interactiveTemplateList">
                        <option value="" class="px-3 py-2 text-sm">--Seleccione uno--</option>
                        <option v-for="(template) in interactive.type === 'list'?lists:buttons"
                                v-bind:key="template.id" :value="template.uuid"
                                class="px-3 py-2 text-sm">
                          {{ template.name }}
                        </option>
                      </select>
                    </div>
                  </template>
                  <div class="form-group">
                    <!--eslint-disable-next-line-->
                    <label for="interactiveHeader"><b>Encabezado</b></label>
                    <textarea class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="interactive.header"
                              name="interactiveHeader" id="interactiveHeader">
                    </textarea>
                  </div>
                  <div class="form-group">
                    <!--eslint-disable-next-line-->
                    <label for="interactiveBody"><b>Cuerpo</b></label>
                    <textarea class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="interactive.body" name="interactiveBody"
                              id="interactiveBody">
                    </textarea>
                  </div>
                  <div class="form-group">
                    <!--eslint-disable-next-line-->
                    <label for="interactiveFooter"><b>Footer</b></label>
                    <textarea class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="interactive.footer"
                              name="interactiveFooter" id="interactiveFooter">
                    </textarea>
                  </div>
                  <template v-if="interactive.type === 'list'">
                    <div class="form-group">
                      <!--eslint-disable-next-line-->
                      <label for="interactiveButtonTitle"><b>Texto del botón</b></label>
                      <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="interactive.button_title" type="text"
                             name="interactiveButtonTitle" id="interactiveButtonTitle">
                    </div>
                  </template>
                  <div class="flex">
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                            @click="closeInteractiveModal()"
                            type="button">Cerrar
                    </button>
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                            @click="sendInteractive()">Aceptar
                    </button>
                  </div>
                </div>
                <!-- Put your modal content here -->
              </ModalBox>
              <!--enviar mensaje de voz-->
              <ModalBox
                :showing="showRecordModal" @close="showRecordModal = false"
              >
                <div class="flex flex-col w-full">
                  <audio-widget :time="10" :successfulUpload="success"
                                :failedUpload="failed" :afterRecording="afterRec"
                                :backendEndpoint="voiceUrl"
                                buttonColor="rgb(16, 185, 129)"/>
                </div>
                <!-- Put your modal content here -->
              </ModalBox>
              <!--enviar archivos-->
              <ModalBox
                :showing="showFilesModal" @close="showFilesModal = false"
              >
                <div class="flex">
                  <p class="text-black"><b>Enviar archivos</b></p>
                </div>
                <div class="flex flex-col w-full">
                  <div class="form-group">
                    <!--eslint-disable-next-line-->
                    <label for="file"><b>Archivo</b></label>
                    <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="file.file"
                            name="file" id="file">
                      <option value="" class="px-3 py-2 text-sm">--Seleccione uno--</option>
                      <option v-for="(file) in documents"
                              v-bind:key="file.id" :value="file.uuid"
                              class="px-3 py-2 text-sm">
                        {{ file.name }}
                      </option>
                    </select>
                  </div>
                  <div class="form-group">
                    <!--eslint-disable-next-line-->
                    <label for="fileCaption"><b>Caption</b></label>
                    <textarea class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="file.caption"
                              name="fileCaption" id="fileCaption">
                    </textarea>
                  </div>
                  <div class="flex">
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                            @click="closeFileModal()"
                            type="button">Cerrar
                    </button>
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                            @click="sendFile()">Aceptar
                    </button>
                  </div>
                </div>
                <!-- Put your modal content here -->
              </ModalBox>
              <!--enviar url-->
              <ModalBox
                :showing="showUrlModal" @close="showUrlModal = false"
              >
                <div class="flex">
                  <p class="text-black"><b>Enviar vista previa URL</b></p>
                </div>
                <div class="flex flex-col w-full">
                  <div class="form-group">
                    <!--eslint-disable-next-line-->
                    <label for="messageURL"><b>URL</b></label>
                    <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="messageURL" type="text"
                           name="messageURL" id="messageURL">
                  </div>
                  <div class="flex">
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                            @click="closeUrlModal()"
                            type="button">Cerrar
                    </button>
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                            @click="sendMessage('url')">Aceptar
                    </button>
                  </div>
                </div>
                <!-- Put your modal content here -->
              </ModalBox>
              <!--enviar ubicación-->
              <ModalBox
                :showing="showLocationModal" @close="showLocationModal = false"
              >
                <div class="flex">
                  <p class="text-black"><b>Enviar ubicación</b></p>
                </div>
                <div class="flex flex-col w-full p-1">
                  <div>
                    <GMapAutocomplete
                      class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm"
                      placeholder="Busca una dirección"
                      @place_changed="setPlace"
                    >
                    </GMapAutocomplete>
                    <GMapMap class="h-96"
                             :center="center"
                             :zoom="15"
                             map-type-id="terrain"
                             :options="{
                        zoomControl: true,
                        mapTypeControl: true,
                        scaleControl: true,
                        streetViewControl: true,
                        rotateControl: true,
                        fullscreenControl: true,
                      }"
                             style="width: 100%; height: 300px"
                    >
                      <GMapMarker
                        :position="marker.position"
                        :clickable="true"
                        :draggable="true" @drag="dragged"
                      />
                    </GMapMap>
                  </div>
                  <div class="form-group">
                    <!--eslint-disable-next-line-->
                    <label for="mapName"><b>Nombre</b></label>
                    <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="selectedLocation.name"
                           name="mapName" id="mapName" type="text">
                  </div>
                  <div class="form-group" v-if="selectedLocation.name">
                    <!--eslint-disable-next-line-->
                    <label for="mapAddress"><b>Dirección</b></label>
                    <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="selectedLocation.address"
                           name="mapAddress" id="mapAddress" type="text">
                  </div>
                  <div class="flex">
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                            @click="closeLocationModal()"
                            type="button">Cerrar
                    </button>
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                            @click="sendMap()">Aceptar
                    </button>
                  </div>
                </div>
                <!-- Put your modal content here -->
              </ModalBox>
              <!--enviar files-->
              <ModalBox
                :showing="showUploadFilesModal" @close="showUploadFilesModal = false; files = [];"
              >
                <div class="flex">
                  <p class="text-black"><b>Enviar archivos</b></p>
                </div>
                <div class="flex w-full flex-wrap">
                  <template v-for="(file, key) in files" v-bind:key="key">
                    <div class="flex flex-col w-1/2 justify-center ">
                      <img class="m-1" :src="file.url" :alt="file.file.name"
                           :title="file.file.name"/>
                      <button class="m-1 text-white bg-red-500 rounded-md"
                              @click="removeFile(file)">
                        Eliminar
                      </button>
                    </div>
                  </template>
                </div>
                <div class="flex flex-col w-full p-1">
                  <div class="flex">
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                            @click="showUploadFilesModal = false; files = [];"
                            type="button">Cerrar
                    </button>
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600"
                            @click="sendFiles()" type="button">Aceptar
                    </button>
                  </div>
                </div>
                <!-- Put your modal content here -->
              </ModalBox>
              <!--enviar templates-->
              <ModalBox
                :showing="showTemplatesModal" @close="showTemplatesModal = false">
                <div class="flex">
                  <p class="text-black"><b>Enviar mensaje template</b></p>
                </div>
                <div class="flex flex-col w-full">
                  <div class="form-group">
                    <!--eslint-disable-next-line-->
                    <label for="template"><b>Template</b></label>
                    <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="template.template"
                            name="file" id="template" @change="setComponents">
                      <option :value="null" class="px-3 py-2 text-sm">--Seleccione uno--</option>
                      <option v-for="(template, key) in conversationTemplates"
                              v-bind:key="key" :value="template"
                              class="px-3 py-2 text-sm">
                        {{ template.name }}
                      </option>
                    </select>
                  </div>
                  <template v-if="template.template">
                    <p><b>Texto de la plantilla</b></p>
                    <div class="flex w-full"
                         v-for="(component, key) in template.template.components"
                         v-bind:key="key">
                      <template v-if="component.type === 'BODY'">
                        <div>
                          <b>{{ component.type }}</b> {{ component.text }}
                        </div>
                      </template>
                      <template v-if="component.type === 'HEADER'">
                        <div>
                          <b>{{ component.type }}</b> {{ component.format }}
                        </div>
                      </template>
                    </div>
                    <div class="flex-col w-full " v-for="(component, key) in template.components"
                         v-bind:key="key">
                      <b class="w-full">Variables de {{ component.type }}</b>
                      <template v-if="component.type === 'body'">
                        <template v-for="(parameter, j) in component.parameters"
                                  v-bind:key="j">
                          <div class="flex w-full">
                            <p class="p-3"><b>{{ j + 1 }}</b></p>
                            <!--eslint-disable-next-line-->
                            <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" type="text" v-model="parameter.text"
                                   @input="setTexts">
                          </div>
                        </template>
                      </template>
                      <template v-if="component.type === 'header'">
                        <template v-for="(parameter, k) in component.parameters"
                                  v-bind:key="k">
                          <div class="flex w-full">
                            <p class="p-3 flex-none"><b>{{ k + 1 }}</b></p>
                            <div class="flex bg-transparent w-full grow">
                              <!--eslint-disable-next-line-->
                              <input placeholder="Escríbe el url de la imagen o pega tu imagen"
                                     type="text"
                                     class="my-2 appearance-none rounded-none block
                                       w-full px-3 py-2 border border-gray-300 placeholder-gray-500
                                       text-gray-900 rounded-b-md focus:outline-none
                                       focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                       sm:text-sm" v-model="parameter.image.link"
                                     @input="setTexts"
                                     @paste="onPasteTemplate" :id="key"
                                     :aria-label="k"
                              />
                            </div>
                            <!-- <DropZone @files-dropped="addFilesTemplate" class="grow"-->
                            <!--                      style="flex-grow: 1" :id="key"-->
                            <!--                            :aria-label="k"-->
                            <!--              @active="setDropZoneActiveTemplates">-->
                            <!--                 <template v-if="dropzoneActiveTemplates">-->
                            <!--  <div class="w-full flex bg-transparent justify-center grow">-->
                            <!--       <div class="rounded-lg m-2 bg-white border-dashed border-2-->
                            <!--               text-gray-400 border-gray-400 p-3 w-full">-->
                            <!-- <p class="text-md text-center">Suelta tus archivos aquí</p>-->
                            <!--                                </div>-->
                            <!--                              </div>-->
                            <!--                            </template>-->
                            <!--                            <template v-else>-->
                            <!--                              -->
                            <!--                            </template>-->
                            <!--                          </DropZone>-->
                            <PaperClipIcon class="mx-1 my-3 text-gray-400 w-6 h-6 flex-none
                                                cursor-pointer"
                                           @click="openFilePickerTemplateInitial(k)"
                                           aria-hidden="true"
                                           data-toggle="tooltip" data-placement="top"
                                           v-if="canSend"
                                           title="Adjuntar" alt="Adjuntar"/>
                          </div>
                          <div class="flex" v-if="isUploading">
                            <p>Subiendo imagen ...</p>
                          </div>
                          <input :ref="'templateInputInitial'+k" type="file"
                                 :id="key"
                                 :aria-label="k" @change="onInputChangeTemplateInitial"
                                 class="hidden"/>
                        </template>
                        <!--eslint-disable-next-line-->

                      </template>
                    </div>
                    <p><b>Vista previa</b></p>
                    <p><b>HEADER</b></p>
                    <div class="flex w-full"
                         v-for="(head, key) in template.header"
                         v-bind:key="key">
                      <p v-if="head.type === 'text'">{{ head.value }}</p>
                      <img v-if="head.type === 'image'" :src="head.value" alt="image"/>
                    </div>
                    <p><b>BODY</b></p>
                    <div class="flex w-full"
                         v-for="(text, key) in template.body"
                         v-bind:key="key">
                      <p v-if="text.type === 'text'">{{ text.value }}</p>
                    </div>
                  </template>
                  <div class="flex">
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                            @click="closeTemplateModal()"
                            type="button">Cerrar
                    </button>
                    <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                            @click="sendTemplate()">Aceptar
                    </button>
                  </div>
                </div>
                <!-- Put your modal content here -->
              </ModalBox>
            </template>
            <template v-else>
              <p class="mt-32 text-center">Selecciona una conversación</p>
            </template>
          </template>
        </div>
      </div>
    </template>
  </div>

</template>
<script>
import DiscordPicker from 'vue3-discordpicker';
import Toggle from '@vueform/toggle';
import { ref } from 'vue';
import { utils, read } from 'xlsx';
import LitepieDatepicker from 'litepie-datepicker';
import {
  UserIcon, UsersIcon, ArchiveIcon, CollectionIcon, SearchIcon, XIcon, TrashIcon, ClipboardListIcon,
  PencilAltIcon, RefreshIcon, MicrophoneIcon, MenuIcon, PaperClipIcon, PhoneOutgoingIcon,
  PlusSmIcon, LockClosedIcon,
} from '@heroicons/vue/outline';
import {
  getToken, onMessage,
} from 'firebase/messaging';
import StatusItem from '@/components/leads/StatusItem.vue';
import ConversationItem from '@/components/chat/ConversationItem.vue';
import IncomeMessage from '@/components/chat/IncomeMessage.vue';
import OutcomeMessage from '@/components/chat/OutcomeMessage.vue';
import DropDown from '@/components/DropDown.vue';
import OptionsDropDown from '@/components/OptionsDropDown.vue';
import MessagesDropDown from '@/components/MessagesDropDown.vue';
import ModalBox from '@/components/ModalBox.vue';
import LoadingBox from '@/components/LoadingBox.vue';
import AudioWidget from '@/components/audio/AudioWidget.vue';
import '@dafcoe/vue-collapsible-panel/dist/vue-collapsible-panel.css';
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from '@dafcoe/vue-collapsible-panel';
import AsesorItem from '@/components/chat/AsesorItem.vue';
import CompanyDropDown from '@/components/CompanyDropDown.vue';
import LeadNotificationsDropDown from '@/components/LeadNotificationsDropDown.vue';
import MessageNotificationsDropDown from '@/components/MessageNotificationsDropDown.vue';
import axios from '@/http/axios/index';
import MainDropDown from '@/components/MainDropDown.vue';
import FiltersDropDown from '@/components/FiltersDropDown.vue';
import NewMessagesDropDown from '@/components/NewMessagesDropDown.vue';
import AwardedDropDown from '@/components/AwardedDropDown.vue';
import DropZone from '@/components/files/DropZone.vue';
// import ConversationStatusesDropDown from '@/components/ConversationStatusesDropDown.vue';

class UploadableFile {
  constructor(file) {
    this.file = file;
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
    this.url = URL.createObjectURL(file);
    this.status = null;
  }
}

export default {
  name: 'LeadboxChat',
  components: {
    LoadingBox,
    DropZone,
    LeadNotificationsDropDown,
    MessageNotificationsDropDown,
    Toggle,
    StatusItem,
    MainDropDown,
    FiltersDropDown,
    NewMessagesDropDown,
    AwardedDropDown,
    CompanyDropDown,
    AsesorItem,
    LockClosedIcon,
    DropDown,
    OptionsDropDown,
    ConversationItem,
    IncomeMessage,
    OutcomeMessage,
    ArchiveIcon,
    CollectionIcon,
    UserIcon,
    UsersIcon,
    ClipboardListIcon,
    XIcon,
    PlusSmIcon,
    TrashIcon,
    PencilAltIcon,
    RefreshIcon,
    MicrophoneIcon,
    SearchIcon,
    PaperClipIcon,
    PhoneOutgoingIcon,
    DiscordPicker,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
    MessagesDropDown,
    ModalBox,
    LitepieDatepicker,
    AudioWidget,
    MenuIcon,
  },
  data() {
    return {
      files: [],
      backendEndpoint: '',
      dropzoneActive: false,
      dropzoneActiveTemplates: false,
      showUploadFilesModal: false,
      isShow: true,
      show_quick_replies: false,
      selected: '',
      type: 'active',
      status: null,
      provider: null,
      selectedTab: 'chat',
      showDeleteModal: false,
      showArchiveModal: false,
      showTemplateModal: false,
      showAssignModal: false,
      assignUserModal: null,
      showAssignOneModal: false,
      isLoadingAssignAuto: false,
      isLoadingAssign: false,
      selectAssignUsers: false,
      assignUsersList: [],
      showSuccessModal: false,
      massiveSuccess: [],
      massiveErrors: [],
      isLoadingConversations: false,
      isLoadingStatuses: false,
      isLoadingConversationsUsers: false,
      isLoadingConversation: false,
      isLoadingMore: false,
      isLoadingMoreMessages: false,
      isLoadingConversationMessages: false,
      isLoadingConversationLeads: false,
      isLoadingConversationAppointments: false,
      isLoadingConversationNotes: false,
      isLoadingConversationHistory: false,
      isLoadingConversationRuleHistory: false,
      isLoadingCompanies: false,
      isLoadingMessage: false,
      isLoadingSender: false,
      showProductsModal: false,
      showInteractiveModal: false,
      showFilesModal: false,
      showUrlModal: false,
      showBotModal: false,
      showLocationModal: false,
      showTemplatesModal: false,
      showRecordModal: false,
      isUploading: false,
      saving: false,
      savingSender: false,
      showNoteInterface: false,
      note: '',
      selectedStatusType: 'active',
      selectedStatus: null,
      selectedConversations: [],
      categories: [],
      products: [],
      product: {
        section: null,
        category: null,
        product: null,
        selected: null,
        caption: '',
      },
      text: '',
      templateConnection: null,
      dataTemplate: {
        destination: null,
        name: null,
        templates: [],
        selectedTemplate: null,
        components: [],
        texts: [],
        hmMedic: null,
        selectedFlow: null,
        selectedUser: null,
        type: null,
        to: [],
        headers: [],
      },
      searchMedic: null,
      isLoadingMedics: false,
      medicSearchResults: [],
      showMedicDropdown: false,
      isLoadingCreateLead: false,
      isLoadingCreateAppointment: false,
      dateValue: ref({
        startDate: null,
        endDate: null,
      }),
      formatter: ref({
        date: 'DD-MM-YYYY',
        month: 'MMM',
      }),
      options: ref({
        shortcuts: {
          today: 'Hoy',
          yesterday: 'Ayer',
          past: (period) => `últimos ${period} días`,
          currentMonth: 'este mes',
          pastMonth: 'Mes pasado',
        },
        footer: {
          apply: 'Aplicar',
          cancel: 'Cancelar',
        },
      }),
      emojiCategories: ['people', 'animals', 'foods', 'travel', 'activities', 'objects', 'symbols', 'flags'],
      order: 'DESC',
      filter: null,
      new_messages: null,
      awarded: null,
      allSelected: false,
      search: null,
      editName: false,
      selectedUser: 0,
      value: '',
      captionMap: '',
      cssProps: {
        // eslint-disable-next-line global-require
        backgroundImage: `url(${require('@/assets/bg-leadbox.jpg')})`,
      },
      newLead: {
        form: null,
        fields: [],
        addProduct: false,
        category: null,
        product: null,
        addUser: false,
        sameUser: false,
        user: null,
        name: null,
        email: null,
        phone: null,
      },
      isLoadingConnections: false,
      isLoadingAppointmentConnections: false,
      newAppointment: {
        connection: null,
        name: null,
        surname: null,
        email: null,
        phone: null,
        type: 'cedula',
        document: null,
        cities: [],
        city: null,
        sectors: [],
        sector: null,
        medicalCenters: [],
        medicalCenter: null,
        specialties: [],
        specialty: null,
        date: null,
        schedules: [],
        schedule: null,
        doctors: [],
        doctor: null,
      },
      cssProps2: {
        // eslint-disable-next-line global-require
        background: 'white',
      },
      interactive: {
        type: null,
        template: null,
        button_title: null,
        header: null,
        body: null,
        footer: null,
      },
      file: {
        file: null,
        caption: null,
      },
      categoriesCopy: [],
      template: {
        template: null,
        components: [],
        texts: [],
      },
      selectedLocation: {
        name: null,
        address: null,
        lat: null,
        lng: null,
      },
      savingLead: false,
      messageURL: null,
      center: { lat: -0.1806532, lng: -78.4678382 },
      marker: {
        position: {
          lat: -0.1806532, lng: -78.4678382,
        },
      },
    };
  },
  created() {
    const token = localStorage.getItem('accessToken');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      this.getActualToken();
      if (this.companies.length > 0) {
        if (this.activeConversation) {
          this.getMessages();
          this.getLeads();
          this.getConversationAppointments();
          this.getConversationTemplates();
          this.getSender();
          this.getNotes();
          this.getConversationHistory();
          this.getConversationRuleHistory();
        }
        if (this.activeCompany) {
          this.setActiveCompany();
        }
      } else {
        this.getCompanies();
      }
      if ('Notification' in window) {
        console.log('Notification.permission', Notification.permission);
        if (Notification.permission !== 'granted') {
          Notification.requestPermission().then((permission) => {
            if (permission === 'granted') {
              // permiso concedido
            } else {
              // permiso no concedido
            }
          });
        }
      }
    } else {
      this.$router.push('').catch(() => {
      });
    }
  },
  watch: {
    dateValue(val) {
      console.log('val', val);
      this.getConversations();
      this.getConversationsUsers();
      this.getConversationsStatuses();
    },
    templateConnection(val) {
      if (val) {
        this.getConnectionTemplates(val);
      }
    },
  },
  methods: {
    setStatusType(statusType) {
      this.selectedStatusType = statusType;
    },
    setStatus(status, type) {
      console.log('status', status);
      console.log('type', type);
      console.log('this.selectedStatus', this.selectedStatus);
      this.type = type;
      if (this.selectedStatus) {
        if (this.selectedStatus === status.id) {
          this.selectedStatus = null;
        } else {
          this.selectedStatus = status.id;
        }
      } else {
        this.selectedStatus = status.id;
      }
      console.log('this.selectedStatus', this.selectedStatus);
      this.getConversationsUsers();
      this.getConversationsStatuses();
      this.getConversations();
    },
    getConversationsStatuses() {
      // TODO: send pagination
      this.isLoadingStatuses = true;
      const payload = {
        company: this.activeCompany.uuid,
        user: this.selectedUser,
        type: this.type,
        provider: this.provider,
      };
      this.$store.dispatch('conversationStatuses', payload)
        .then((response) => {
          this.isLoadingStatuses = false;
          console.log('respuesta conversationStatuses', response);
        }, (error) => {
          this.isLoadingStatuses = false;
          console.log('error conversationStatuses', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingStatuses = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversationStatuses', error);
        });
    },
    refresh() {
      this.getConversations();
      this.getConversationsUsers();
      this.getConversationsUsersList();
      this.getConversationsStatuses();
    },
    setComponents() {
      this.template.components = [];
      this.template.body = [];
      this.template.header = [];
      console.log('this.template', this.template);
      this.template.texts = [];
      this.template.template.components.forEach((comp) => {
        const parameters = [];
        let finalText = null;
        if (comp.type === 'BODY') {
          const re = /{{(.*?)}}/g;
          const regs = ((comp.text || '').match(re) || []);
          finalText = comp.text;
          // eslint-disable-next-line
          for (let i = 0; i < regs.length; i++) {
            parameters.push({
              type: 'text',
              text: i === 0 ? this.activeSender.name : '',
            });
            finalText = finalText.replace(`${regs[i]}`, i === 0 ? this.activeSender.name : '');
          }
          this.template.body.push({
            type: 'text',
            value: finalText,
          });
        }
        if (comp.type === 'HEADER') {
          if (comp.format === 'IMAGE') {
            parameters.push({
              type: 'image',
              image: {
                link: '',
              },
            });
            finalText = 'imagen';
            this.template.header.push({
              type: 'image',
              value: finalText,
            });
          }
        }

        // eslint-disable-next-line no-plusplus

        this.template.components.push({
          type: comp.type.toLowerCase(),
          parameters,
        });
        this.template.texts.push(finalText);
      });
      console.log('this.template.texts', this.template.texts);
    },
    setTexts() {
      this.template.texts = [];
      this.template.body = [];
      this.template.header = [];
      this.template.template.components.forEach((comp, key) => {
        let finalText = null;
        if (comp.type === 'BODY') {
          const re = /{{(.*?)}}/g;
          const regs = ((comp.text || '').match(re) || []);
          finalText = comp.text;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < regs.length; i++) {
            finalText = finalText.replace(
              `${regs[i]}`,
              this.template.components[key].parameters[i].text,
            );
          }
          this.template.body.push({
            type: 'text',
            value: finalText,
          });
        }
        if (comp.type === 'HEADER') {
          if (comp.format === 'IMAGE') {
            finalText = this.template.components[key].parameters[0].image.link;
            this.template.header.push({
              type: 'image',
              value: finalText,
            });
          }
        }
        this.template.texts.push(finalText);
      });
      console.log('this.template.texts', this.template.texts);
    },
    setTemplateComponents() {
      this.dataTemplate.components = [];

      this.dataTemplate.body = [];
      this.dataTemplate.header = [];
      console.log('this.dataTemplate', this.dataTemplate);
      this.dataTemplate.selectedTemplate.components.forEach((comp) => {
        const parameters = [];
        const massiveConfig = [];
        let finalText = null;
        if (comp.type === 'BODY') {
          const re = /{{(.*?)}}/g;
          const regs = ((comp.text || '').match(re) || []);
          finalText = comp.text;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < regs.length; i++) {
            parameters.push({
              type: 'text',
              text: '',
            });
            massiveConfig.push({
              fixed: false,
              column: null,
            });
            finalText = finalText.replace(`${regs[i]}`, '');
          }
          this.dataTemplate.body.push({
            type: 'text',
            value: finalText,
          });
        }
        if (comp.type === 'HEADER') {
          if (comp.format === 'IMAGE') {
            parameters.push({
              type: 'image',
              image: {
                link: '',
              },
            });
            finalText = 'imagen';
            this.dataTemplate.header.push({
              type: 'image',
              value: finalText,
            });
          }
          if (comp.format === 'VIDEO') {
            parameters.push({
              type: 'video',
              video: {
                link: '',
              },
            });
            finalText = 'video';
            this.dataTemplate.header.push({
              type: 'video',
              value: finalText,
            });
          }
        }
        this.dataTemplate.components.push({
          type: comp.type.toLowerCase(),
          parameters,
          massiveConfig,
        });
        console.log('this.dataTemplate.components', this.dataTemplate.components);
      });
    },
    setTemplateTexts() {
      this.dataTemplate.body = [];
      this.dataTemplate.header = [];
      this.dataTemplate.selectedTemplate.components.forEach((comp, key) => {
        console.log('comp', comp);
        console.log('key', key);
        let finalText = null;
        if (comp.type === 'BODY') {
          const re = /{{(.*?)}}/g;
          const regs = ((comp.text || '').match(re) || []);
          finalText = comp.text;
          console.log('finalText', finalText);
          console.log('this.dataTemplate.type', this.dataTemplate.type);
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < regs.length; i++) {
            if (this.dataTemplate.type === 'individual') {
              finalText = finalText.replace(
                `${regs[i]}`,
                this.dataTemplate.components[key].parameters[i].text,
              );
            } else {
              // eslint-disable-next-line
              if (this.dataTemplate.components[key].massiveConfig[i].fixed) {
                finalText = finalText.replace(
                  `${regs[i]}`,
                  this.dataTemplate.components[key].massiveConfig[i].column,
                );
              } else {
                finalText = finalText.replace(
                  `${regs[i]}`,
                  // eslint-disable-next-line
                  this.dataTemplate.to[0][this.dataTemplate.components[key].massiveConfig[i].column],
                );
              }
            }
          }
          this.dataTemplate.body.push({
            type: 'text',
            value: finalText,
          });
        }
        if (comp.type === 'HEADER') {
          if (comp.format === 'IMAGE') {
            finalText = this.dataTemplate.components[key].parameters[0].image.link;
            this.dataTemplate.header.push({
              type: 'image',
              value: finalText,
            });
          }
          if (comp.format === 'VIDEO') {
            finalText = this.dataTemplate.components[key].parameters[0].video.link;
            this.dataTemplate.header.push({
              type: 'video',
              value: finalText,
            });
          }
        }
      });
    },
    handleMedicInput() {
      // Make an API call or perform some search logic here
      // and update the searchResults array
      // For example:
      this.showMedicDropdown = true;
      const payload = {
        lang: 'es',
        limit: 1000,
        offset: 1,
        searchField: this.searchMedic,
      };
      this.isLoadingMedics = true;
      this.$store.dispatch('medicSearch', payload)
        .then((response) => {
          this.isLoadingMedics = false;
          console.log('respuesta medicSearch', response);
          this.medicSearchResults = response.data.data;
        }, (error) => {
          this.isLoadingMedics = false;
          console.log('error medicSearch', error);
        })
        .catch((error) => {
          this.isLoadingMedics = false;
          console.log('error medicSearch', error);
        });
    },
    handleSelectMedic(result) {
      this.searchMedic = result.NOMBRES_COMPLETOS;
      this.dataTemplate.hmMedic = result;
      this.dataTemplate.name = result.NOMBRES_COMPLETOS;
      console.log('', this.dataTemplate.components);
      this.dataTemplate.destination = result.TLF_CELULAR;
      this.showMedicDropdown = false;
      const bodyIndex = this.dataTemplate.components.findIndex((comp) => comp.type === 'body');
      if (bodyIndex >= 0) {
        this.dataTemplate.components[bodyIndex].parameters[0].text = result.NOMBRES_COMPLETOS;
      }
      this.setTemplateTexts();
    },
    countRegexs(str) {
      const re = /{(.*?)}/g;
      return ((str || '').match(re) || []).length;
    },
    showQuickReplies() {
      this.show_quick_replies = !this.show_quick_replies;
      setTimeout(() => {
        this.scrollDown();
      }, 300);
    },
    onScroll(e) {
      const { scrollTop, offsetHeight, scrollHeight } = e.target;
      if ((scrollTop + offsetHeight) >= scrollHeight) {
        if (this.selectedTab === 'chat') {
          this.getMessagesAgain();
        }
        console.log('bottom!');
      }
    },
    addHashToLocation(params) {
      // eslint-disable-next-line no-restricted-globals
      history.pushState(
        {},
        null,
        params,
      );
    },
    setFields() {
      this.newLead.fields = [];
      console.log('new field', this.newLead);
      this.newLead.form.fields.forEach((field) => {
        if (field.key !== 'nombre' && field.key !== 'email' && field.key !== 'name'
          && field.key !== 'phone' && field.key !== 'mail' && field.key !== 'telefono') {
          this.newLead.fields.push({
            key: field.key,
            name: field.name,
            data: null,
          });
        }
      });
      this.newLead.name = this.activeSender.name;
      this.newLead.email = this.activeSender.email;
      this.newLead.phone = this.activeSender.phone;
      if (this.activeConversation.user_id) {
        this.newLead.sameUser = true;
        // this.newLead.addUser = true;
        // this.newLead.user = this.activeConversation.user_id;
      }
      this.addExtraFields();
    },
    addExtraFields() {
      Object.keys(this.activeConversation.fields).forEach((fl) => {
        const index = this.findIndex(fl);
        if (index >= 0) {
          this.newLead.fields[index].data = this.activeConversation.fields[fl];
        }
        const indexGender = this.newLead.fields
          .findIndex((field) => field.key === 'gender' || field.key === 'genero');
        if (indexGender >= 0) {
          this.newLead.fields[indexGender].data = this.activeSender.gender;
        }
        const indexDoc = this.newLead.fields
          .findIndex((field) => field.key === 'identificacion' || field.key === 'documento');
        if (indexDoc >= 0) {
          this.newLead.fields[indexDoc].data = this.activeSender.document;
        }
        const indexCity = this.newLead.fields
          .findIndex((field) => field.key === 'ciudad' || field.key === 'city');
        if (indexCity >= 0) {
          this.newLead.fields[indexCity].data = this.activeSender.city;
        }
        const indexAddress = this.newLead.fields
          .findIndex((field) => field.key === 'direccion' || field.key === 'address');
        if (indexAddress >= 0) {
          this.newLead.fields[indexAddress].data = this.activeSender.address;
        }
      });
    },
    findIndex(key) {
      return this.newLead.fields
        .findIndex((field) => field.key === key || field.name.toLowerCase() === key);
    },
    createLead() {
      console.log('new lead', this.newLead);
      const payload = {
        company: this.activeCompany.uuid,
        form: this.newLead.form.uuid,
        name: this.newLead.name,
        email: this.newLead.email,
        phone: this.newLead.phone,
        addProduct: this.newLead.addProduct,
        product: this.newLead.product,
        addUser: this.newLead.addUser,
        sameUser: this.newLead.sameUser,
        user: this.newLead.user,
        conversation_id: this.activeConversation?.id,
      };

      const fields = {};
      this.newLead.fields.forEach((field) => {
        fields[field.key] = field.data;
      });
      payload.fields = fields;
      console.log('payload', payload);
      this.isLoadingCreateLead = true;
      this.$store.dispatch('createLead', payload)
        .then(
          (response) => {
            this.isLoadingCreateLead = false;
            console.log('response createLead', response);
            this.$notify({
              type: 'success',
              title: 'Lead creado',
              text: response.data.message,
            });
            this.getConversation(this.activeConversation.uuid);
            this.newLead = {
              form: null,
              fields: [],
              addProduct: false,
              category: null,
              product: null,
              addUser: false,
              sameUser: false,
              user: null,
              name: null,
              email: null,
              phone: null,
            };
          },
          (error) => {
            this.isLoadingCreateLead = false;
            console.log('error createLead', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingCreateLead = false;
          console.log('error createLead', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    // dropzone
    sendFiles() {
      console.log('files', this.files);
      try {
        this.isLoadingConversation = true;
        this.files.forEach((file) => {
          console.log('file', file);
          this.uploadFile(file);
        });
        this.$notify({
          type: 'success',
          title: 'archivos enviados',
          text: `Se enviaron ${this.files.length} archivos`,
        });
        this.files = [];
        this.showUploadFilesModal = false;
        this.isLoadingConversation = false;
      } catch (e) {
        this.isLoadingConversation = false;
        this.$notify({
          type: 'error',
          title: 'Error',
          text: 'hubo un error',
        });
      }
    },
    async uploadFile(file) {
      const formData = new FormData();
      formData.set('type', file.file.type);
      formData.set('name', file.file.name);
      formData.append('file', file.file);
      this.isLoading = true;
      console.log('formData', formData);
      this.axios.post(
        this.filesUrl,
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Company: this.activeCompany.uuid,
          },
        },
      ).then((data) => {
        console.log('data files', data);
        this.getMessagesAgain();
      })
        .catch((e) => {
          console.log('error', e);
        });
    },
    async uploadFileTemplate(file, key, k) {
      this.isUploading = true;
      const formData = new FormData();
      formData.set('type', file.file.type);
      formData.set('name', file.file.name);
      formData.append('file', file.file);
      console.log('formData', formData);
      this.axios.post(
        'https://leadbox.ec/api/v1/templates/uploadFile',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Company: this.activeCompany.uuid,
          },
        },
      ).then((data) => {
        console.log('data files template', data);
        console.log('key', key);
        console.log('k', k);
        console.log('this.dataTemplate.components[key]', this.dataTemplate.components[key]);
        console.log(
          ' this.dataTemplate.components[key].parameters[k]',
          this.dataTemplate.components[key].parameters[k],
        );
        this.isUploading = false;
        if (this.dataTemplate.components[key].parameters[k].type === 'image') {
          this.dataTemplate.components[key].parameters[k].image.link = data.data.success;
          this.dataTemplate.header = [];
          this.dataTemplate.header.push({
            type: 'image',
            value: data.data.success,
          });
        }
        if (this.dataTemplate.components[key].parameters[k].type === 'video') {
          this.dataTemplate.components[key].parameters[k].video.link = data.data.success;
          this.dataTemplate.header = [];
          this.dataTemplate.header.push({
            type: 'video',
            value: data.data.success,
          });
        }
      })
        .catch((e) => {
          this.isUploading = false;
          console.log('error upload file template', e);
        });
    },
    async uploadFileTemplateInitial(file, key, k) {
      this.isUploading = true;
      const formData = new FormData();
      formData.set('type', file.file.type);
      formData.set('name', file.file.name);
      formData.append('file', file.file);
      console.log('formData', formData);
      this.axios.post(
        'https://leadbox.ec/api/v1/templates/uploadFile',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Company: this.activeCompany.uuid,
          },
        },
      ).then((data) => {
        console.log('data files template', data);
        console.log('key', key);
        console.log('k', k);
        console.log('this.dataTemplate.components[key]', this.template.components[key]);
        console.log(
          ' this.dataTemplate.components[key].parameters[k]',
          this.template.components[key].parameters[k],
        );
        console.log(
          'this.dataTemplate.components[key].parameters[k].image',
          this.template.components[key].parameters[k].image,
        );
        console.log(
          'this.dataTemplate.components[key].parameters[k].image.link',
          this.template.components[key].parameters[k].image.link,
        );

        this.isUploading = false;
        this.template.components[key].parameters[k].image.link = data.data.success;
        this.template.header = [];
        this.template.header.push({
          type: 'image',
          value: data.data.success,
        });
      })
        .catch((e) => {
          this.isUploading = false;
          console.log('error upload file template', e);
        });
    },
    onPaste(evt) {
      console.log('on paste', evt);
      const files = [];
      const { items } = evt.clipboardData || evt.originalEvent.clipboardData;
      // eslint-disable-next-line
      for (const index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          files.push(item.getAsFile());
        }
      }
      if (files.length > 0) {
        this.addFiles(files);
      }
    },
    onPasteTemplates(evt) {
      console.log('on paste', evt);
      const files = [];
      const { items } = evt.clipboardData || evt.originalEvent.clipboardData;
      // eslint-disable-next-line
      for (const index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          files.push(item.getAsFile());
        }
      }
      if (files.length > 0) {
        this.addFilesTemplate(files, evt.target.id, evt.target.ariaLabel);
      }
    },
    onPasteTemplate(evt) {
      console.log('on paste', evt);
      const files = [];
      const { items } = evt.clipboardData || evt.originalEvent.clipboardData;
      // eslint-disable-next-line
      for (const index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          files.push(item.getAsFile());
        }
      }
      if (files.length > 0) {
        this.addFilesTemplate(files, evt.target.id, evt.target.ariaLabel);
      }
    },
    openFilePicker() {
      const element = this.$refs.fileInput;
      element.click();
    },
    openFilePickerTemplate(k) {
      console.log('refs', this.$refs);
      console.log('this.$refs.templateInput', this.$refs[`templateInput${k}`]);
      const element = this.$refs[`templateInput${k}`][0];
      element.click();
    },
    openFilePickerTemplateInitial(k) {
      console.log('refs', this.$refs);
      console.log('this.$refs.templateInputInitial', this.$refs[`templateInputInitial${k}`]);
      const element = this.$refs[`templateInputInitial${k}`][0];
      element.click();
    },
    deleteDestinatary(key) {
      this.dataTemplate.to.splice(key, 1);
      if (this.dataTemplate.to.length > 0) {
        const bodyIndex = this.dataTemplate.components.findIndex((comp) => comp.type === 'body');
        if (bodyIndex >= 0) {
          // eslint-disable-next-line prefer-destructuring
          this.dataTemplate.components[bodyIndex].parameters[0].text = this.dataTemplate.to[0][0];
          this.setTemplateTexts();
        }
      } else {
        const bodyIndex = this.dataTemplate.components.findIndex((comp) => comp.type === 'body');
        if (bodyIndex >= 0) {
          // eslint-disable-next-line prefer-destructuring
          this.dataTemplate.components[bodyIndex].parameters[0].text = '';
          this.setTemplateTexts();
        }
      }
    },
    clearTo() {
      this.dataTemplate.to = [];
      const bodyIndex = this.dataTemplate.components.findIndex((comp) => comp.type === 'body');
      if (bodyIndex >= 0) {
        // eslint-disable-next-line prefer-destructuring
        this.dataTemplate.components[bodyIndex].parameters[0].text = '';
        this.setTemplateTexts();
      }
    },
    onMassiveFile(event) {
      console.log('files', event.target.files);
      const file = event.target.files[0];
      console.log('file', file);
      const reader = new FileReader();

      reader.onload = (e) => {
        const workbook = read(e.target.result, { type: 'binary' });
        console.log('workbook', workbook);
        // Obtener la primera hoja del libro
        const sheetName = workbook.SheetNames[0];
        console.log('sheetName', sheetName);
        const worksheet = workbook.Sheets[sheetName];
        console.log('worksheet', worksheet);
        // Convertir el archivo XLSX a un array de objetos
        const arrayObjetos = utils.sheet_to_json(worksheet, { header: 1, range: 0 });
        const headers = arrayObjetos[0];
        this.dataTemplate.to = arrayObjetos.slice(1);
        this.dataTemplate.headers = headers;
        console.log('headers', this.dataTemplate.headers);
        console.log('arrayObjetos', this.dataTemplate.to);
        this.$refs.massive.value = '';
        if (this.dataTemplate.to.length > 0) {
          // const bodyIndex = this.dataTemplate.components.findIndex(
          // (comp) => comp.type === 'body');
          // if (bodyIndex >= 0) {
          //   // eslint-disable-next-line prefer-destructuring
          //   this.dataTemplate.components[bodyIndex].parameters[0].text =
          //   this.dataTemplate.to[0][2];
          //   this.setTemplateTexts();
          // }

        }
      };
      reader.readAsBinaryString(file);
    },
    onInputChange(e) {
      this.addFiles(e.target.files);
      e.target.value = null;
      // reset so that selecting the same file again will still cause it to fire this change
    },
    onInputChangeTemplates(e) {
      console.log('event, ', e);
      this.addFilesTemplate(e.target.files, e.target.id, e.target.ariaLabel);
      e.target.value = null;
      // reset so that selecting the same file again will still cause it to fire this change
    },
    onInputChangeTemplateInitial(e) {
      console.log('event, ', e);
      this.addFilesTemplateInitial(e.target.files, e.target.id, e.target.ariaLabel);
      e.target.value = null;
      // reset so that selecting the same file again will still cause it to fire this change
    },
    addFiles(newFiles) {
      const newUploadableFiles = [...newFiles]
        .map((file) => new UploadableFile(file)).filter((file) => !this.fileExists(file.id));
      this.files = this.files.concat(newUploadableFiles);
      this.showUploadFilesModal = true;
    },
    addFilesTemplate(newFiles, key, k) {
      const newUploadableFiles = [...newFiles]
        .map((file) => new UploadableFile(file)).filter((file) => !this.fileExists(file.id));
      console.log('files', newUploadableFiles);
      this.uploadFileTemplate(newUploadableFiles[0], key, k);
    },
    addFilesTemplateInitial(newFiles, key, k) {
      const newUploadableFiles = [...newFiles]
        .map((file) => new UploadableFile(file)).filter((file) => !this.fileExists(file.id));
      console.log('files', newUploadableFiles);
      this.uploadFileTemplateInitial(newUploadableFiles[0], key, k);
    },
    fileExists(otherId) {
      return this.files.some(({ id }) => id === otherId);
    },
    removeFile(file) {
      const index = this.files.indexOf(file);
      if (index > -1) this.files.splice(index, 1);
      if (this.files.length < 1) this.showUploadFilesModal = false;
    },
    setDropZoneActive(active) {
      this.dropzoneActive = active;
    },
    setDropZoneActiveTemplates(active) {
      this.dropzoneActiveTemplates = active;
    },
    deleteConversations() {
      const payload = {
        company: this.activeCompany.uuid,
        ids: this.selectedConversations,
      };
      this.$store.dispatch('deleteConversations', payload)
        .then((response) => {
          console.log('respuesta deleteConversations', response);
          const { success } = response.data;
          this.$notify({
            type: 'success',
            title: 'conversaciones eliminados',
            text: success,
          });
          this.showDeleteModal = false;
          this.selectedConversations = [];
          this.getConversations();
        }, (error) => {
          console.log('error deleteConversations', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error deleteConversations', error);
        });
    },
    archiveConversations() {
      const payload = {
        company: this.activeCompany.uuid,
        ids: this.selectedConversations,
      };
      this.isLoadingConversation = true;
      this.$store.dispatch('archiveConversations', payload)
        .then((response) => {
          console.log('respuesta archiveConversations', response);
          const { success } = response.data;
          this.isLoadingConversation = false;
          this.$notify({
            type: 'success',
            title: 'conversaciones archivados',
            text: success,
          });
          this.showArchiveModal = false;
          this.selectedConversations = [];
          this.getConversations();
        }, (error) => {
          console.log('error archiveConversations', error);
          this.isLoadingConversation = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.isLoadingConversation = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error archiveConversations', error);
        });
    },
    success() {
      console.log('SUCCESS UPLOAD!!');
      this.showRecordModal = false;
      this.$notify({
        type: 'success',
        title: 'mensaje enviado',
        text: 'audio correctamente enviado',
      });
      this.getMessagesAgain();
    },
    failed() {
      console.log('FAILED!');
    },
    afterRec() {
      console.log('Recorded successfully!');
    },
    setCategories() {
      if (this.product.section === 'todos') {
        this.categories = this.companyCategories;
      } else {
        this.categories = this.product.section.categories;
      }
    },
    setProducts() {
      this.products = this.product.category.products;
    },
    getLeadEvents() {
      const payload = {
        lead: this.activeLead.uuid,
      };
      this.$store.dispatch('leadEvents', payload)
        .then((response) => {
          console.log('respuesta leadEvents', response);
        }, (error) => {
          console.log('error leadEvents', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadEvents', error);
        });
    },
    getConversationHistory() {
      const payload = {
        conversation: this.activeConversation.uuid,
      };
      this.isLoadingConversationHistory = true;
      this.$store.dispatch('conversationHistory', payload)
        .then((response) => {
          this.isLoadingConversationHistory = false;
          console.log('respuesta conversationHistory', response);
        }, (error) => {
          this.isLoadingConversationHistory = false;
          console.log('error conversationHistory', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingConversationHistory = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversationHistory', error);
        });
    },
    getConversationRuleHistory() {
      const payload = {
        conversation: this.activeConversation.uuid,
      };
      this.isLoadingConversationRuleHistory = true;
      this.$store.dispatch('conversationRuleHistory', payload)
        .then((response) => {
          this.isLoadingConversationRuleHistory = false;
          console.log('respuesta conversationRuleHistory', response);
        }, (error) => {
          this.isLoadingConversationRuleHistory = false;
          console.log('error conversationRuleHistory', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingConversationRuleHistory = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversationRuleHistory', error);
        });
    },
    goToLead(uuid) {
      this.$router.push(`/leads?lead=${uuid}`).catch(() => {
      });
    },
    goToAppointment(uuid) {
      this.$router.push(`/dates?date=${uuid}`).catch(() => {
      });
    },
    getLead(uuid) {
      const payload = {
        uuid,
        company: this.activeCompany.uuid,
      };
      // this.isLoadingLead = true;
      this.$store.dispatch('lead', payload)
        .then((response) => {
          // this.isLoadingLead = false;
          console.log('respuesta lead', response);
          // this.getLeadEvents();
          // this.getLeadHistory();
          this.$router.push('/leads').catch(() => {
          });
        }, (error) => {
          // this.isLoadingLead = false;
          console.log('error lead', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          // this.isLoadingLead = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error lead', error);
        });
    },

    save() {
      if (this.saving) return;
      console.log('guarda', this.activeConversation);
      const payload = {
        conversation: this.activeConversation,
        company: this.activeCompany.uuid,
      };
      this.saving = true;
      this.isLoadingConversation = true;
      this.$store.dispatch('updateConversation', payload)
        .then(
          (response) => {
            this.saving = false;
            this.isLoadingConversation = false;
            console.log('response message', response);
            this.$notify({
              type: 'success',
              title: 'conversación actualizada',
              text: response.data.message,
            });
            this.getMessages();
            this.getConversationHistory();
            this.getConversationRuleHistory();
          },
          (error) => {
            this.saving = false;
            this.isLoadingConversation = false;
            console.log('error message', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.saving = false;
          this.isLoadingConversation = false;
          console.log('error message', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    setPlace(place) {
      const lat = place.geometry.location.lat();
      const lng = place.geometry.location.lng();
      this.selectedLocation.name = place.formatted_address;
      this.selectedLocation.address = place.formatted_address;
      this.selectedLocation.lat = lat;
      this.selectedLocation.lng = lng;
      this.center = { lat, lng };
      this.marker = {
        position: {
          lat, lng,
        },
      };
    },
    dragged(event) {
      this.selectedLocation.lat = event.latLng.lat();
      this.selectedLocation.lng = event.latLng.lng();
    },
    updateSender() {
      if (this.savingSender) return;
      console.log('guarda', this.activeSender);
      const payload = {
        conversation: this.activeConversation,
        company: this.activeCompany.uuid,
        sender: this.activeSender,
      };
      this.savingSender = true;
      this.isLoadingConversation = true;
      this.$store.dispatch('updateSender', payload)
        .then(
          (response) => {
            this.savingSender = false;
            this.isLoadingConversation = false;
            console.log('response sender', response);
            this.$notify({
              type: 'success',
              title: 'contacto actualizado',
              text: response.data.message,
            });
            this.getMessages();
          },
          (error) => {
            this.savingSender = false;
            this.isLoadingConversation = false;
            console.log('error sender', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.savingSender = false;
          this.isLoadingConversation = false;
          console.log('error sender', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    setFilter(filter) {
      this.filter = filter;
      this.getConversations();
    },
    setNewMessages(filter) {
      this.new_messages = filter;
      this.getConversations();
    },
    setAwarded(filter) {
      this.awarded = filter;
      this.getConversations();
    },
    sendMessage(type) {
      if (this.isLoadingMessage) return;
      if (!this.canSend) return;

      const payload = {
        body: this.text,
        type: 'text',
        id: this.activeConversation.uuid,
      };
      if (type === 'url') {
        payload.url = this.messageURL;
        payload.body = this.messageURL;
      }
      this.isLoadingMessage = true;

      this.$store.dispatch('sendMessage', payload)
        .then(
          (response) => {
            this.isLoadingMessage = false;
            console.log('response message', response);
            this.text = '';
            this.$notify({
              type: 'success',
              title: 'Mensaje enviado',
              text: response.data.message,
            });
            this.getMessages();
            if (type === 'url') {
              this.showUrlModal = false;
              this.messageURL = null;
            }
          },
          (error) => {
            this.isLoadingMessage = false;
            console.log('error message', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error.data.message,
            });
          },
        ).catch((error) => {
          this.isLoadingMessage = false;
          console.log('error message', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
        });
    },
    openCreateModal() {
      this.templateConnection = null;
      this.searchMedic = null;
      this.isLoadingMedics = false;
      this.medicSearchResults = [];
      this.showMedicDropdown = false;
      this.dataTemplate = {
        destination: null,
        name: null,
        templates: [],
        type: null,
        selectedTemplate: null,
        components: [],
        texts: [],
        hmMedic: null,
        selectedFlow: null,
        selectedUser: null,
        to: [],
        headers: [],
      };
      this.showTemplateModal = !this.showTemplateModal;
      if (this.activeCompany.uuid === 'ef0a6a78-0758-497b-9061-8db75f0cce3e') {
        // eslint-disable-next-line prefer-destructuring
        this.dataTemplate.selectedFlow = this.flows[2];
      }
    },
    createConversation() {
      const payload = {
        template: this.dataTemplate.selectedTemplate,
        flow_id: this.dataTemplate.selectedFlow?.id,
        user_id: this.dataTemplate.selectedUser?.id,
        id: this.templateConnection.uuid,
        destination: this.dataTemplate.destination,
        name: this.dataTemplate.name,
        type: this.dataTemplate.type,
        to: this.dataTemplate.to,
        company: this.activeCompany.uuid,
      };
      if (this.dataTemplate.components.length > 0) {
        payload.components = this.dataTemplate.components;
      }
      if (this.dataTemplate.body.length > 0) {
        payload.body = this.dataTemplate.body;
      }
      if (this.dataTemplate.header.length > 0) {
        payload.header = this.dataTemplate.header;
      }
      this.isLoadingConversation = true;
      this.$store.dispatch('createConversation', payload)
        .then(
          (response) => {
            this.isLoadingConversation = false;
            console.log('response createConversation', response);
            this.showTemplateModal = false;
            this.dataTemplate = {
              destination: null,
              name: null,
              type: null,
              templates: [],
              selectedTemplate: null,
              components: [],
              texts: [],
              hmMedic: null,
              to: [],
              headers: [],
            };
            this.templateConnection = null;
            this.$notify({
              type: 'success',
              title: 'Mensaje enviado',
              text: response.data.message,
            });
            this.getConversations();
            this.getConversation(response.data.conversation.uuid);
          },
          (error) => {
            this.isLoadingConversation = false;
            console.log('error sendTemplate', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error.data.message,
            });
          },
        ).catch((error) => {
          this.isLoadingConversation = false;
          console.log('error createConversation', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
        });
    },
    createConversations() {
      const payload = {
        template: this.dataTemplate.selectedTemplate,
        flow_id: this.dataTemplate.selectedFlow?.id,
        user_id: this.dataTemplate.selectedUser?.id,
        id: this.templateConnection.uuid,
        destination: this.dataTemplate.destination,
        name: this.dataTemplate.name,
        type: this.dataTemplate.type,
        to: this.dataTemplate.to,
        company: this.activeCompany.uuid,
      };
      // console.log('payload', payload);
      // generate all the templates to pass backgroun
      // console.log(' this.dataTemplate.to', this.dataTemplate.to);
      const generatedTemplates = this.dataTemplate.to.map((destiny, init) => {
        const objectCreated = {
          name: destiny[this.dataTemplate.name],
          phone: destiny[this.dataTemplate.destination],
          components: [],
          body: [],
          header: [],
        };
        if (this.dataTemplate.components.length > 0) {
          // console.log('this.dataTemplate.components', this.dataTemplate.components);
          const comps = this.dataTemplate.components.map((component, index) => {
            const insideComponent = {
              type: component.type,
            };
            if (component.type === 'body') {
              insideComponent.parameters = component.parameters.map((item, key) => {
                const element = {
                  type: item.type,
                };
                if (this.dataTemplate.components[index].massiveConfig[key].fixed) {
                  element.text = `${this.dataTemplate.components[index].massiveConfig[key].column}`;
                } else {
                  // eslint-disable-next-line max-len
                  element.text = `${this.dataTemplate.to[init][this.dataTemplate.components[index].massiveConfig[key].column]}`;
                }
                return element;
              });
            } else {
              insideComponent.parameters = component.parameters;
            }
            return insideComponent;
          });
          objectCreated.components = comps;
          const bodys = [];
          const headers = [];
          this.dataTemplate.selectedTemplate.components.forEach((comp, key) => {
            if (comp.type === 'BODY') {
              const re = /{{(.*?)}}/g;
              const regs = (comp.text || '').match(re) || [];
              let finalText = comp.text;
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < regs.length; i++) {
                if (this.dataTemplate.type === 'individual') {
                  finalText = finalText.replace(
                    `${regs[i]}`,
                    objectCreated.components[key].parameters[i].text,
                  );
                } else {
                  // eslint-disable-next-line
                  if (this.dataTemplate.components[key].massiveConfig[i].fixed) {
                    finalText = finalText.replace(
                      `${regs[i]}`,
                      this.dataTemplate.components[key].massiveConfig[i].column,
                    );
                  } else {
                    finalText = finalText.replace(
                      `${regs[i]}`,
                      // eslint-disable-next-line
                      this.dataTemplate.to[init][this.dataTemplate.components[key].massiveConfig[i].column],
                    );
                  }
                }
              }
              console.log('final text', finalText);
              bodys.push({
                type: 'text',
                value: finalText,
              });
            }
            if (comp.type === 'HEADER') {
              if (comp.format === 'IMAGE') {
                const finalText = objectCreated.components[key].parameters[0].image.link;
                headers.push({
                  type: 'image',
                  value: finalText,
                });
              }
              if (comp.format === 'VIDEO') {
                const finalText = objectCreated.components[key].parameters[0].video.link;
                headers.push({
                  type: 'video',
                  value: finalText,
                });
              }
            }
          });

          if (bodys.length > 0) {
            objectCreated.body = bodys;
          }

          if (headers.length > 0) {
            objectCreated.header = headers;
          }
        }
        // console.log('objectCreated', objectCreated);
        return objectCreated;
      });

      payload.generated = generatedTemplates;
      // console.log('generatedTemplates', generatedTemplates);
      this.isLoadingConversation = true;
      this.$store.dispatch('createConversations', payload)
        .then(
          (response) => {
            this.isLoadingConversation = false;
            console.log('response createConversations', response);
            this.showTemplateModal = false;
            this.dataTemplate = {
              destination: null,
              name: null,
              type: null,
              templates: [],
              selectedTemplate: null,
              selectedFlow: null,
              selectedUser: null,
              components: [],
              texts: [],
              hmMedic: null,
              to: [],
              headers: [],
            };
            this.templateConnection = null;
            this.$notify({
              type: 'success',
              title: 'Mensajes enviados',
              text: response.data.message,
            });
            this.getConversations();
            this.massiveSuccess = response.data.success;
            this.massiveErrors = response.data.errors;
            this.showSuccessModal = true;
            // this.getConversation(response.data.conversation.uuid);
          },
          (error) => {
            this.isLoadingConversation = false;
            console.log('error sendTemplates masive', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error.data.message,
            });
          },
        ).catch((error) => {
          this.isLoadingConversation = false;
          console.log('error createConversations', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
        });
    },
    sendTemplate() {
      if (this.isLoadingMessage) return;
      const payload = {
        template: this.template.template,
        id: this.activeConversation.uuid,
      };
      if (this.template.components.length > 0) {
        payload.components = this.template.components;
      }
      payload.texts = [];
      if (this.template.header.length > 0) {
        this.template.header.forEach((item) => {
          if (item.type === 'text') {
            payload.texts.push(item.value);
          }
        });
      }
      if (this.template.body.length > 0) {
        this.template.body.forEach((item) => {
          if (item.type === 'text') {
            payload.texts.push(item.value);
          }
        });
      }
      console.log('payload', payload);
      this.isLoadingMessage = true;
      this.$store.dispatch('sendTemplate', payload)
        .then(
          (response) => {
            this.isLoadingMessage = false;
            console.log('response sendTemplate', response);
            this.showTemplatesModal = false;
            this.template = {
              template: null,
              components: [],
              texts: [],
              body: [],
              header: [],
            };
            this.$notify({
              type: 'success',
              title: 'Mensaje enviado',
              text: response.data.message,
            });
            this.getMessages();
          },
          (error) => {
            this.isLoadingMessage = false;
            console.log('error sendTemplate', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error.data.message,
            });
          },
        ).catch((error) => {
          this.isLoadingMessage = false;
          console.log('error sendTemplate', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
        });
    },
    sendInteractive() {
      console.log('interactive', this.interactive);
      if (this.isLoadingMessage) return;
      if (!this.canSend) return;
      const payload = this.interactive;
      payload.id = this.activeConversation.uuid;
      this.isLoadingMessage = true;
      this.$store.dispatch('sendInteractive', payload)
        .then(
          (response) => {
            this.isLoadingMessage = false;
            console.log('response sendInteractive', response);
            this.showInteractiveModal = false;
            this.interactive = {
              type: null,
              template: null,
              button_title: null,
              header: null,
              body: null,
              footer: null,
            };
            this.$notify({
              type: 'success',
              title: 'Mensaje interactivo enviado',
              text: response.data.message,
            });
            this.getMessages();
          },
          (error) => {
            this.isLoadingMessage = false;
            console.log('error sendInteractive', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingMessage = false;
          console.log('error sendInteractive', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    sendProduct() {
      console.log('product', this.product);
      if (this.isLoadingMessage) return;
      if (!this.canSend) return;
      const payload = {
        product: this.product.product.uuid,
        caption: this.product.caption,
        config: this.product.selected,
        id: this.activeConversation.uuid,
      };
      this.isLoadingMessage = true;
      this.$store.dispatch('sendProduct', payload)
        .then(
          (response) => {
            this.isLoadingMessage = false;
            console.log('response sendProduct', response);
            this.showProductsModal = false;
            this.interactive = {
              type: null,
              template: null,
              button_title: null,
              header: null,
              body: null,
              footer: null,
            };
            this.$notify({
              type: 'success',
              title: 'Producto enviado',
              text: response.data.message,
            });
            this.getMessages();
          },
          (error) => {
            this.isLoadingMessage = false;
            console.log('error sendProduct', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingMessage = false;
          console.log('error sendProduct', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    sendMap() {
      console.log('map', this.marker);
      if (this.isLoadingMessage) return;
      if (!this.canSend) return;
      const payload = this.selectedLocation;
      payload.id = this.activeConversation.uuid;
      console.log('payload', payload);
      this.isLoadingMessage = true;
      this.$store.dispatch('sendMap', payload)
        .then(
          (response) => {
            this.isLoadingMessage = false;
            console.log('response sendMap', response);
            this.showLocationModal = false;
            this.selectedLocation = {
              name: null,
              address: null,
              lat: null,
              lng: null,
            };
            this.$notify({
              type: 'success',
              title: 'Mensaje con mapa enviado',
              text: response.data.message,
            });
            this.getMessages();
          },
          (error) => {
            this.isLoadingMessage = false;
            console.log('error sendMap', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingMessage = false;
          console.log('error sendMap', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    sendFile() {
      console.log('file', this.file);
      if (this.isLoadingMessage) return;
      if (!this.canSend) return;
      const payload = this.file;
      payload.id = this.activeConversation.uuid;
      this.isLoadingMessage = true;
      this.$store.dispatch('sendFile', payload)
        .then(
          (response) => {
            this.isLoadingMessage = false;
            console.log('response sendFile', response);
            this.showFilesModal = false;
            this.file = {
              file: null,
              caption: null,
            };
            this.$notify({
              type: 'success',
              title: 'Archivo enviado',
              text: response.data.message,
            });
            this.getMessages();
          },
          (error) => {
            this.isLoadingMessage = false;
            console.log('error sendFile', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingMessage = false;
          console.log('error sendFile', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    setQuickReply(reply) {
      this.text = reply.text;
      this.show_quick_replies = false;
      const input = document.getElementById('text');
      const end = reply.text.length;
      // ✅ Move focus to END of input field
      input.setSelectionRange(end, end);
      input.focus();
    },
    quitBlur() {
      setTimeout(() => {
        this.show_quick_replies = false;
      }, 300);
    },
    sendFlow() {
      if (this.isLoadingMessage) return;
      if (!this.canSend) return;
      const payload = {};
      payload.id = this.activeConversation.uuid;
      this.isLoadingMessage = true;
      this.$store.dispatch('sendFlow', payload)
        .then(
          (response) => {
            this.isLoadingMessage = false;
            console.log('response sendFlow', response);
            this.showFilesModal = false;
            this.file = {
              file: null,
              caption: null,
            };
            this.$notify({
              type: 'success',
              title: 'Bot iniciado',
              text: response.data.message,
            });
            this.getMessages();
          },
          (error) => {
            this.isLoadingMessage = false;
            console.log('error sendFlow', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingMessage = false;
          console.log('error sendFlow', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },

    assignConversations() {
      const usersList = [];
      if (this.selectAssignUsers) {
        this.conversationsUsersList.forEach((user) => {
          if (user.selected) {
            usersList.push(user.id);
          }
        });
      }
      const payload = {
        company: this.activeCompany.uuid,
        selectUsers: this.selectAssignUsers,
        usersList,
      };
      console.log('payload', payload);
      this.isLoadingAssignAuto = true;
      this.$store.dispatch('assignAutoConversations', payload)
        .then(
          (response) => {
            this.isLoadingAssignAuto = false;
            console.log('response assignAuto', response);
            this.$notify({
              type: 'success',
              title: 'Conversaciones asignadas',
              text: response.data.success,
            });
            // this.getLeads();
            // this.getConversationAppointments();
            // this.getStatuses();
            // this.getAreas();
            // this.getLeadsUsers();
            this.showAssignModal = false;
          },
          (error) => {
            this.isLoadingAssignAuto = false;
            console.log('error assignAuto', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingAssignAuto = false;
          console.log('error assignAuto', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    assignOneLeads() {
      const payload = {
        company: this.activeCompany.uuid,
        user: this.assignUserModal.uuid,
        ids: this.selectedConversations,
      };
      console.log(payload);
      this.isLoadingAssign = true;
      this.$store.dispatch('assignOneConversations', payload)
        .then(
          (response) => {
            this.isLoadingAssign = false;
            console.log('response assignOne', response);
            this.$notify({
              type: 'success',
              title: `Conversaciones asignadas a ${this.assignUserModal.name}`,
              text: response.data.success,
            });
            // this.getLeads();
            // this.getConversationAppointments();
            // this.getStatuses();
            // this.getAreas();
            // this.getLeadsUsers();
            this.isLoadingAssign = false;
            this.showAssignOneModal = false;
            this.selectedConversations = [];
            this.assignUserModal = null;
          },
          (error) => {
            this.isLoadingAssign = false;
            console.log('error assignOne', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingAssignAuto = false;
          console.log('error assignOne', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    createNote() {
      const payload = {
        note: this.note,
        conversation: this.activeConversation.uuid,
      };
      this.isLoadingCreateNote = true;
      this.$store.dispatch('createNote', payload)
        .then(
          (response) => {
            this.isLoadingCreateNote = false;
            this.showNoteInterface = false;
            this.note = '';
            console.log('response createNote', response);
            this.$notify({
              type: 'success',
              title: 'Nota creada',
              text: response.data.message,
            });
            this.getNotes();
          },
          (error) => {
            this.isLoadingCreateNote = false;
            console.log('error createNote', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingCreateNote = false;
          console.log('error createNote', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    sendGif(url) {
      if (this.isLoadingMessage) return;
      if (!this.canSend) return;
      const payload = {
        body: url,
        id: this.activeConversation.uuid,
      };
      this.isLoadingMessage = true;
      this.$store.dispatch('sendGif', payload)
        .then(
          (response) => {
            this.isLoadingMessage = false;
            console.log('response image', response);
            this.text = '';
            this.$notify({
              type: 'success',
              title: 'Mensaje enviado',
              text: response.data.message,
            });
            this.getMessages();
          },
          (error) => {
            this.isLoadingMessage = false;
            console.log('error image', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error.data.message,
            });
          },
        ).catch((error) => {
          this.isLoadingMessage = false;
          console.log('error message', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
        });
    },
    setUser(user) {
      if (this.selectedUser === user.id) {
        this.selectedUser = 0;
      } else {
        this.selectedUser = user.id;
      }
      this.getConversations();
      this.getConversationsUsers();
      this.getConversationsStatuses();
    },
    selectTab(tab) {
      this.selectedTab = tab;
      if (tab === 'create-appointment') {
        if (this.appointmentConnections.length === 1) {
          const [firstConnection] = this.appointmentConnections;
          this.newAppointment.connection = firstConnection;
          this.getAppointmentsCities();
          this.newAppointment.name = this.activeSender.name;
          this.newAppointment.phone = this.activeSender.phone;
          this.newAppointment.email = this.activeSender.email;
        }
      }
    },
    openModal(type) {
      console.log('type', type);
      // eslint-disable-next-line default-case
      switch (type) {
        case 'interactive':
          this.showInteractiveModal = true;
          break;
        case 'file':
          this.showFilesModal = true;
          break;
        case 'product':
          this.showProductsModal = true;
          break;
        case 'url':
          this.showUrlModal = true;
          break;
        case 'location':
          this.showLocationModal = true;
          break;
        case 'bot':
          this.sendFlow();
          break;
      }
    },
    closeInteractiveModal() {
      this.showInteractiveModal = false;
      this.interactive = {
        type: null,
        template: null,
        button_title: null,
        header: null,
        body: null,
        footer: null,
      };
    },
    closeProductModal() {
      this.showProductsModal = false;
      this.product = {
        section: null,
        category: null,
        product: null,
        selected: null,
        caption: '',
      };
    },
    closeFileModal() {
      this.showFilesModal = false;
      this.text = '';
    },
    closeTemplateModal() {
      this.showTemplatesModal = false;
    },
    closeLocationModal() {
      this.showLocationModal = false;
      this.text = '';
    },
    closeUrlModal() {
      this.showUrlModal = false;
      this.messageURL = null;
    },
    changeOrder(direction) {
      this.order = direction;
      this.getConversations();
    },
    selectAll() {
      if (this.allSelected) {
        this.selectedConversations = [];
      } else {
        this.selectedConversations = this.conversations.map((conversation) => conversation.uuid);
      }
      this.allSelected = !this.allSelected;
    },
    setEmoji(emoji) {
      console.log(emoji);
      this.text += emoji;
    },
    setGif(gif) {
      const url = gif.replace('!(alt)[', '').replace(']', '');
      console.log(gif);
      console.log(url);
      this.sendGif(url);
    },
    selectConversation(conversation) {
      if (!this.isLoadingConversation) {
        this.selectedTab = 'chat';
        this.newLead = {
          form: null,
          fields: [],
          addProduct: false,
          category: null,
          product: null,
          addUser: false,
          sameUser: false,
          user: null,
          name: null,
          email: null,
          phone: null,
        };
        this.getConversation(conversation);
      }
    },
    selectGroupConversation(conversation) {
      if (this.selectedConversations.includes(conversation.uuid)) {
        const index = this.selectedConversations.indexOf(conversation.uuid);
        this.selectedConversations.splice(index, 1);
      } else {
        this.selectedConversations.push(conversation.uuid);
      }
    },
    scrollDown() {
      const element = document.getElementById('messages_container');
      element.scrollTop = element.scrollHeight;
    },
    scrollDownHalf() {
      const element = document.getElementById('messages_container');
      element.scrollTop = element.scrollHeight / 2;
    },
    closeConversation() {
      this.$store.dispatch('setActiveConversation', null)
        .then((response) => {
          console.log('respuesta', response);
          this.addHashToLocation('/chat');
        }, (error) => {
          console.log('error', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error?.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
    changedCompany() {
      this.selectedUser = 0;
      this.closeConversation();
      this.getConversationsUsers();
      this.getConversationsUsersList();
      this.getConversations();
      this.getConversationsStatuses();
      this.getTemplates();
      this.getReplies();
      this.getProducts();
      this.getProductCategories();
      this.getProductSections();
      this.getFiles();
      this.getFlows();
      this.getForms();
      this.getConnections();
      this.getAppointmentConnections();
      this.getSaleProbabilities();
      this.getLeadNotifications();
      this.getMessageNotifications();
    },
    getForms() {
      // TODO: send pagination
      this.isLoadingForms = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('forms', payload)
        .then((response) => {
          this.isLoadingForms = false;
          console.log('respuesta forms', response);
        }, (error) => {
          this.isLoadingForms = false;
          console.log('error forms', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingForms = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error forms', error);
        });
    },
    getConnections() {
      // TODO: send pagination
      this.isLoadingConnections = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('connections', payload)
        .then((response) => {
          this.isLoadingConnections = false;
          console.log('respuesta connections', response);
        }, (error) => {
          this.isLoadingConnections = false;
          console.log('error connections', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingConnections = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error connections', error);
        });
    },
    // appointments
    getAppointmentConnections() {
      // TODO: send pagination
      this.isLoadingAppointmentConnections = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('appointmentConnections', payload)
        .then((response) => {
          this.isLoadingAppointmentConnections = false;
          console.log('respuesta Appointmentconnections', response);
          console.log('respuesta2 Appointmentconnections', this.appointmentConnections);
        }, (error) => {
          this.isLoadingAppointmentConnections = false;
          console.log('error Appointmentconnections', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingAppointmentConnections = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error Appointmentconnections', error);
        });
    },
    getAppointmentsCities() {
      if (!this.newAppointment.connection) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
      };
      this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsCities', payload)
        .then((response) => {
          this.isLoadingAppointmentsCities = false;
          console.log('respuesta AppointmentsCities', response);
          this.newAppointment.cities = response.data;
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        }, (error) => {
          this.isLoadingAppointmentsCities = false;
          console.log('error AppointmentsCities', error);
          this.newAppointment.cities = [];
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          this.isLoadingAppointmentsCities = false;
          console.log('error AppointmentsCities', error);
          this.newAppointment.cities = [];
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsMedicalCenters() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsMedicalCenters', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsMedicalCenters', response);
          const medicalCenters = response.data;
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
          if (medicalCenters.length > 10) {
            this.getAppointmentsSectors();
          } else {
            this.newAppointment.medicalCenters = medicalCenters;
          }
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsMedicalCenters', error);
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsMedicalCenters', error);
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sectors = [];
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsDoctorsAndSpecialties() {
      this.getAppointmentsDoctors();
      this.getAppointmentsSpecialties();
      // esto
    },
    getAppointmentsDoctors() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      if (!this.newAppointment.medicalCenter) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
        medical_center_id: this.newAppointment.city?.id,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsDoctors', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsDoctors', response);
          this.newAppointment.doctors = response.data;
          this.newAppointment.doctor = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsDoctors', error);
          this.newAppointment.doctors = [];
          this.newAppointment.doctor = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsDoctors', error);
          this.newAppointment.doctors = [];
          this.newAppointment.doctor = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsSpecialties() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      if (!this.newAppointment.medicalCenter) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
        medical_center_id: this.newAppointment.city?.id,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSpecialties', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSpecialties', response);
          this.newAppointment.specialties = response.data;
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSpecialties', error);
          this.newAppointment.specialties = [];
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSpecialties', error);
          this.newAppointment.specialties = [];
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsSpecialtiesByDoctor() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      if (!this.newAppointment.medicalCenter) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
        doctor_id: this.newAppointment.doctor?.id,
        medical_center_id: this.newAppointment.city?.id,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSpecialtiesByDoctor', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSpecialtiesByDoctor', response);
          this.newAppointment.specialties = response.data;
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSpecialtiesByDoctor', error);
          this.newAppointment.specialties = [];
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSpecialtiesByDoctor', error);
          this.newAppointment.specialties = [];
          this.newAppointment.specialty = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsSectors() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSectors', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSectors', response);
          this.newAppointment.sectors = response.data;
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSectors', error);
          this.newAppointment.sectors = [];
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSectors', error);
          this.newAppointment.sectors = [];
          this.newAppointment.medicalCenters = [];
          this.newAppointment.medicalCenter = null;
          this.newAppointment.sector = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.date = null;
        });
    },
    getAppointmentsSectorMedicalCenters() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      if (!this.newAppointment.sector) {
        return;
      }
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id,
        sector: this.newAppointment.sector,
      };
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSectorMedicalCenters', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSectorMedicalCenters', response);
          this.newAppointment.medicalCenter = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.medicalCenters = response.data;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSectorMedicalCenters', error);
          this.newAppointment.medicalCenter = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.medicalCenters = [];
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSectorMedicalCenters', error);
          this.newAppointment.medicalCenter = null;
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
          this.newAppointment.medicalCenters = [];
        });
    },
    getAppointmentsSchedules() {
      if (!this.newAppointment.connection) {
        return;
      }
      if (!this.newAppointment.city) {
        return;
      }
      if (!this.newAppointment.medicalCenter) {
        return;
      }
      if (!this.newAppointment.date) {
        return;
      }
      console.log('this.company', this.activeCompany.uuid);
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        medical_center_id: this.newAppointment.medicalCenter?.id,
        date: this.parsedAppointmentDate,
        endpoint: 'schedules',
      };
      if (this.activeCompany.uuid === '1c5ec80e-6736-4589-ae86-edd30b2da67f') {
        payload.doctor_id = this.newAppointment.doctor?.id;
        payload.endpoint = 'schedules-aura';
      }
      // this.isLoadingAppointmentsCities = true;
      this.$store.dispatch('appointmentsSchedules', payload)
        .then((response) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('respuesta appointmentsSchedules', response);
          this.newAppointment.schedules = response.data;
          this.newAppointment.schedule = null;
        }, (error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSchedules', error);
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
        })
        .catch((error) => {
          // this.isLoadingAppointmentsCities = false;
          console.log('error appointmentsSchedules', error);
          this.newAppointment.schedules = [];
          this.newAppointment.schedule = null;
        });
    },
    createAppointment() {
      console.log('new lead', this.newLead);
      const payload = {
        connection: this.newAppointment.connection?.uuid,
        city_id: this.newAppointment.city?.id?.toString(),
        medical_center_id: this.newAppointment.medicalCenter?.id?.toString(),
        date: this.parsedAppointmentDate,
        schedule: `${this.newAppointment.schedule?.startLabel
        }-${this.newAppointment.schedule?.endLabel}`,
        name: `${this.newAppointment.name}`,
        email: this.newAppointment.email,
        phone: this.newAppointment.phone,
        document: this.newAppointment.document,
        number: this.newAppointment.number,
        conversation_id: this.activeConversation?.uuid,
        endpoint: 'appointment',
      };
      if (this.newAppointment.doctor) {
        payload.doctor_id = this.newAppointment.doctor?.id;
      }
      if (this.newAppointment.specialty) {
        payload.specialty_id = this.newAppointment.specialty?.id;
      }
      const products = [];
      this.categoriesCopy.forEach((category) => {
        category.products.forEach((product) => {
          products.push(product);
        });
      });
      payload.products = products;
      if (this.activeCompany.uuid === '1c5ec80e-6736-4589-ae86-edd30b2da67f') {
        payload.endpoint = 'appointment-aura';
      }
      console.log('payload', payload);
      this.isLoadingCreateAppointment = true;
      this.$store.dispatch('createAppointment', payload)
        .then(
          (response) => {
            this.isLoadingCreateAppointment = false;
            console.log('response createAppointment', response);
            this.$notify({
              type: 'success',
              title: 'Cita creada',
            });
            this.getConversation(this.activeConversation.uuid);
            this.newAppointment = {
              connection: null,
              name: null,
              surname: null,
              email: null,
              phone: null,
              type: 'cedula',
              document: null,
              cities: [],
              city: null,
              sectors: [],
              sector: null,
              medicalCenters: [],
              medicalCenter: null,
              specialties: [],
              specialty: null,
              date: null,
              schedules: [],
              schedule: null,
              doctors: [],
              doctor: null,
            };
            this.selectedTab = 'appointments';
          },
          (error) => {
            this.isLoadingCreateAppointment = false;
            console.log('error createAppointment', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.isLoadingCreateAppointment = false;
          console.log('error createAppointment', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    /// space
    /// space
    /// space
    /// space
    /// space
    getSaleProbabilities() {
      // TODO: send pagination
      this.isLoadingSaleProbabilies = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('probabilities', payload)
        .then((response) => {
          this.isLoadingSaleProbabilies = false;
          console.log('respuesta probabilities', response);
        }, (error) => {
          this.isLoadingSaleProbabilies = false;
          console.log('error probabilities', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingSaleProbabilies = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error probabilities', error);
        });
    },
    getTemplates() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('templates', payload)
        .then((response) => {
          console.log('respuesta templates', response);
        }, (error) => {
          console.log('error templates', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error templates', error);
        });
    },
    getReplies() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('replies', payload)
        .then((response) => {
          console.log('respuesta replies', response);
        }, (error) => {
          console.log('error replies', error);
          // this.$notify({
          //   type: 'error',
          //   title: 'Error',
          //   text: error.data?.message,
          // });
        })
        .catch((error) => {
          // this.$notify({
          //   type: 'error',
          //   title: 'Error',
          //   text: 'hubo un error',
          // });
          console.log('error replies', error);
        });
    },
    getFiles() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('files', payload)
        .then((response) => {
          console.log('respuesta files', response);
        }, (error) => {
          console.log('error files', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error files', error);
        });
    },
    getFlows() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('flows', payload)
        .then((response) => {
          console.log('respuesta flows', response);
        }, (error) => {
          console.log('error flows', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error flows', error);
        });
    },
    getProducts() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('products', payload)
        .then((response) => {
          console.log('respuesta products', response);
        }, (error) => {
          console.log('error products', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error products', error);
        });
    },
    getProductCategories() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('productCategories', payload)
        .then((response) => {
          this.categoriesCopy = this.companyCategories.map((category) => {
            category.products.map((product) => {
              // eslint-disable-next-line no-param-reassign
              product.selected = false;
              // eslint-disable-next-line no-param-reassign
              product.quantity = 1;
              // eslint-disable-next-line no-param-reassign
              product.note = null;
              return product;
            });
            return category;
          });
          console.log('respuesta productCategories', response);
        }, (error) => {
          console.log('error productCategories', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error productCategories', error);
        });
    },
    getProductSections() {
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('productSections', payload)
        .then((response) => {
          console.log('respuesta productSections', response);
        }, (error) => {
          console.log('error productSections', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error productSections', error);
        });
    },
    setToken() {
      const payload = {};
      this.isLoadingCompanies = true;
      this.$store.dispatch('companies', payload)
        .then(
          (response) => {
            console.log('response token', response);
            this.getCompanies();
            this.getActualToken();
          },
          (error) => {
            console.log('error token', error);
          },
        ).catch((error) => {
          console.log('error token', error);
        });
    },
    getCompanies() {
      const payload = {};
      this.isLoadingCompanies = true;
      this.$store.dispatch('companies', payload)
        .then((response) => {
          console.log('respuesta companies', response);
          this.setActiveCompany();
          this.isLoadingCompanies = false;
        }, (error) => {
          console.log('error companies', error);
          this.isLoadingCompanies = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error?.data?.message,
          });
        })
        .catch((error) => {
          this.isLoadingCompanies = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error company', error);
        });
    },
    setActiveCompany() {
      const selectedCompany = localStorage.getItem('companyUuid');
      let company = null;
      if (selectedCompany) {
        const index = this.companies.findIndex((comany) => comany.uuid === selectedCompany);
        company = this.companies[index];
      } else {
        [company] = this.companies;
        localStorage.setItem('companyUuid', company.uuid);
      }
      this.$store.dispatch('setActiveCompany', company)
        .then((response) => {
          console.log('respuesta', response);
          this.getConversations();
          this.getConversationsUsers();
          this.getConversationsUsersList();
          this.getConversationsStatuses();
          this.getTemplates();
          this.getReplies();
          this.getFiles();
          this.getFlows();
          this.getProducts();
          this.getProductCategories();
          this.getProductSections();
          this.getForms();
          this.getConnections();
          this.getAppointmentConnections();
          this.getSaleProbabilities();
          this.getMessageNotifications();
          this.getLeadNotifications();
          const id = this.$route.query.conversation;
          if (id) {
            this.selectConversation(id);
          }
        }, (error) => {
          this.isLoading = false;
          console.log('status', error.data.statusCode);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
    getConversations() {
      // TODO: send pagination
      console.log('this.selectedStatus', this.selectedStatus);
      const payload = {
        type: this.type,
        company: this.activeCompany.uuid,
        order: this.order,
        search: this.search,
        status: this.selectedStatus,
        user: this.selectedUser,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
        has: this.filter,
        provider: this.provider,
        new_messages: this.new_messages,
        awarded: this.awarded,
      };
      console.log('payload', payload);
      this.isLoadingConversations = true;
      this.$store.dispatch('conversations', payload)
        .then((response) => {
          console.log('respuesta conversations', response);
          this.isLoadingConversations = false;
        }, (error) => {
          console.log('error conversations', error);
          this.isLoadingConversations = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingConversations = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversations', error);
        });
    },
    loadMore() {
      if (this.isLoadingMore) return;
      const payload = {
        type: this.type,
        company: this.activeCompany.uuid,
        order: this.order,
        search: this.search,
        status: this.status,
        user: this.selectedUser,
        url: this.nextPageUrl,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
        has: this.filter,
        provider: this.provider,
        new_messages: this.new_messages,
        awarded: this.awarded,
      };
      this.isLoadingMore = true;
      this.$store.dispatch('nextPageConversations', payload)
        .then((response) => {
          console.log('respuesta conversations', response);
          this.isLoadingMore = false;
        }, (error) => {
          this.isLoadingMore = false;
          console.log('error conversations', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingMore = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversations', error);
        });
    },
    getLeadNotifications() {
      // send pagination
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.isLoadingLeadNotifications = true;
      this.$store.dispatch('leadNotifications', payload)
        .then((response) => {
          console.log('respuesta leadNotifications', response);
          this.isLoadingLeadNotifications = false;
        }, (error) => {
          console.log('error leadNotifications', error);
          this.isLoadingLeadNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingLeadNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error leadNotifications', error);
        });
    },
    getMessageNotifications() {
      // send pagination
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.isLoadingMessageNotifications = true;
      this.$store.dispatch('messageNotifications', payload)
        .then((response) => {
          console.log('respuesta messageNotifications', response);
          this.isLoadingMessageNotifications = false;
        }, (error) => {
          console.log('error messageNotifications', error);
          this.isLoadingMessageNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingMessageNotifications = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error messageNotifications', error);
        });
    },
    getConversationsUsers() {
      this.isLoadingConversationsUsers = true;
      const payload = {
        company: this.activeCompany.uuid,
        type: this.type,
        provider: this.provider,
        start: this.dateValue.startDate,
        end: this.dateValue.endDate,
        has: this.filter,
        new_messages: this.new_messages,
        awarded: this.awarded,
        status: this.status,
      };
      this.$store.dispatch('conversationUsers', payload)
        .then((response) => {
          this.isLoadingConversationsUsers = false;
          console.log('respuesta conversationUsers', response);
        }, (error) => {
          this.isLoadingConversationsUsers = error;
          console.log('error conversationUsers', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingConversationsUsers = true;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversationUsers', error);
        });
    },
    getConversationsUsersList() {
      const payload = {
        company: this.activeCompany.uuid,
        type: this.type,
      };
      this.$store.dispatch('conversationUsersList', payload)
        .then((response) => {
          console.log('respuesta conversationUsersList', response);
        }, (error) => {
          console.log('error conversationUsersList', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversationUsersList', error);
        });
    },
    getNumbers() {
      this.$store.dispatch('conversations', this.activeCompany.uuid)
        .then((response) => {
          console.log('respuesta', response);
          const { conversations } = response.data.success;
          console.log('conversations', conversations);
        }, (error) => {
          console.log('status', error.data.statusCode);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })

        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
    getConversation(uuid) {
      const payload = {
        uuid,
        company: this.activeCompany.uuid,
      };
      this.addHashToLocation(`/chat?conversation=${uuid}`);
      this.isLoadingConversation = true;
      this.$store.dispatch('conversation', payload)
        .then((response) => {
          this.isLoadingConversation = false;
          this.template = {
            template: null,
            components: [],
            texts: [],
          };
          console.log('respuesta conversation', response);
          this.getMessages();
          this.getLeads();
          this.getConversationAppointments();
          this.getConversationHistory();
          this.getConversationRuleHistory();
          this.getConversationTemplates();
          this.getSender();
          this.getNotes();
          // this.getConversations();
        }, (error) => {
          this.isLoadingConversation = false;
          console.log('error conversation', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingConversation = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversation', error);
        });
    },
    getSender() {
      const payload = {
        uuid: this.activeConversation.uuid,
        company: this.activeCompany.uuid,
      };
      this.isLoadingSender = true;
      this.$store.dispatch('sender', payload)
        .then((response) => {
          this.isLoadingSender = false;
          console.log('respuesta conversation', response);
        }, (error) => {
          this.isLoadingSender = false;
          console.log('error conversation', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingConversation = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversation', error);
        });
    },
    getLeads() {
      const payload = {
        conversation: this.activeConversation.uuid,
      };
      this.isLoadingConversationLeads = true;
      this.$store.dispatch('conversationLeads', payload)
        .then((response) => {
          this.isLoadingConversationLeads = false;
          console.log('respuesta conversationLeads', response);
          console.log('respuesta conversationLeads 2', this.conversationLeads);
        }, (error) => {
          this.isLoadingConversationLeads = false;
          console.log('error conversationLeads', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingConversationLeads = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversationLeads', error);
        });
    },
    getConversationAppointments() {
      const payload = {
        conversation: this.activeConversation.uuid,
      };
      this.isLoadingConversationAppointments = true;
      this.$store.dispatch('conversationAppointments', payload)
        .then((response) => {
          this.isLoadingConversationAppointments = false;
          console.log('respuesta messages', response);
          setTimeout(() => {
            this.scrollDown();
          }, 150);
        }, (error) => {
          this.isLoadingConversationAppointments = false;
          console.log('error messages', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingConversationAppointments = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error messages', error);
        });
    },
    getConversationTemplates() {
      const payload = {
        conversation: this.activeConversation.uuid,
      };
      this.isLoadingConversationTemplates = true;
      this.$store.dispatch('conversationTemplates', payload)
        .then((response) => {
          this.isLoadingConversationTemplates = false;
          console.log('respuesta conversationTemplates', response);
        }, (error) => {
          this.isLoadingConversationTemplates = false;
          console.log('error conversationTemplates', error);
        })
        .catch((error) => {
          this.isLoadingConversationTemplates = false;
          console.log('error conversationTemplates', error);
        });
    },
    getConnectionTemplates(connection) {
      const payload = {
        connection: connection.uuid,
      };
      this.isLoadingConnectionTemplates = true;
      this.$store.dispatch('connectionTemplates', payload)
        .then((response) => {
          this.isLoadingConnectionTemplates = false;
          console.log('respuesta connectionTemplates', response);
          this.dataTemplate.templates = response.data.success.waba_templates;
        }, (error) => {
          this.isLoadingConnectionTemplates = false;
          console.log('error connectionTemplates', error);
        })
        .catch((error) => {
          this.isLoadingConnectionTemplates = false;
          console.log('error connectionTemplates', error);
        });
    },
    getNotes() {
      const payload = {
        conversation: this.activeConversation.uuid,
      };
      this.isLoadingConversationNotes = true;
      this.$store.dispatch('conversationNotes', payload)
        .then((response) => {
          this.isLoadingConversationNotes = false;
          console.log('respuesta conversationNotes', response);
          setTimeout(() => {
            this.scrollDown();
          }, 150);
        }, (error) => {
          this.isLoadingConversationNotes = false;
          console.log('error conversationNotes', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingConversationNotes = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error conversationNotes', error);
        });
    },
    getMessages() {
      const payload = {
        conversation: this.activeConversation.uuid,
      };
      this.isLoadingConversationMessages = true;
      this.$store.dispatch('messagesPaginated', payload)
        .then((response) => {
          this.isLoadingConversationMessages = false;
          console.log('respuesta messages', response);
          setTimeout(() => {
            this.scrollDown();
          }, 150);
        }, (error) => {
          this.isLoadingConversationMessages = false;
          console.log('error messages', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingConversationMessages = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error messages', error);
        });
    },
    getMoreMessages() {
      if (this.isLoadingMoreMessages) return;
      const payload = {
        conversation: this.activeConversation.uuid,
        url: this.nextPageUrlMessages,
      };
      this.isLoadingMoreMessages = true;
      this.$store.dispatch('nextPageMessagesPaginated', payload)
        .then((response) => {
          console.log('respuesta more messages', response);
          this.isLoadingMoreMessages = false;
          setTimeout(() => {
            this.scrollDownHalf();
          }, 150);
        }, (error) => {
          this.isLoadingMoreMessages = false;
          console.log('error more messages', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoadingMoreMessages = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error more messages', error);
        });
    },
    getMessagesAgain() {
      const payload = {
        conversation: this.activeConversation.uuid,
      };
      this.$store.dispatch('messagesPaginated', payload)
        .then((response) => {
          console.log('respuesta messages', response);
          setTimeout(() => {
            this.scrollDown();
          }, 150);
        }, (error) => {
          console.log('error messages', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error messages', error);
        });
    },
    setType(type) {
      this.type = type;
      this.selectedUser = 0;
      this.getConversationsUsers();
      this.getConversations();
      this.getConversationsStatuses();
    },
    setProvider(provider) {
      if (this.provider === provider) {
        this.provider = null;
      } else {
        this.provider = provider;
      }
      this.getConversationsUsers();
      this.getConversations();
      this.getConversationsStatuses();
    },
    setOnMessage() {
      onMessage(this.$messaging, (payload) => {
        if (payload.data.for === 'user') {
          this.$notify({
            type: 'success',
            title: payload.notification.title,
            text: payload.notification.body,
          });
        }
        this.getConversations();
        this.getMessageNotifications();
        console.log('Message received. ', payload);
        const sound = new Audio('/sounds/tono-mensaje.mp3');
        sound.play();
        if (this.activeConversation) {
          if (this.activeConversation.uuid === payload.data.conversation_id) {
            this.selectedTab = 'chat';
            setTimeout(() => {
              this.getMessagesAgain();
            }, 100);
          }
        } else {
          console.log('no esta activa');
          console.log(payload.data.conversation_id);
          // TODO: check if is in correct company, if no set the correct company
          this.getConversation(payload.data.conversation_id);
        }
      });
    },
    getActualToken() {
      getToken(this.$messaging, {
        vapidKey: 'BLfWeuuIdNsHrWx-2XIUiXfn_PEWjUMI6oUwLRSerdWw4JmKxE6O22nxcJ6dO60ZPahGMSBlyUTasq1OkgYogdc',
      }).then((currentToken) => {
        if (currentToken) {
          console.log('token', currentToken);
          this.$store.dispatch('tokens', [currentToken])
            .then((response) => {
              console.log('respuesta', response);
              this.setOnMessage();
            }, (error) => {
              console.log('status', error);
              this.$notify({
                type: 'error',
                title: 'Error',
                text: error?.data?.message,
              });
            })
            .catch((error) => {
              this.$notify({
                type: 'error',
                title: 'Error',
                text: 'hubo un error',
              });
              console.log('error', error);
            });
          // Send the token to your server and update the UI if necessary
          // ...
        } else {
          // Show permission request UI
          console.log('No registration token available. Request permission to generate one.');
          // ...
        }
      }).catch((err) => {
        console.log('An error occurred while retrieving token. ', err);
        // ...
      });
    },

  },
  computed: {
    total_products() {
      let total = 0;
      this.categoriesCopy.forEach((category) => {
        category.products.forEach((product) => {
          if (product.selected) {
            total += product.price * product.quantity;
          }
        });
      });
      return total;
    },
    total_dates() {
      let total = 0;
      this.categoriesCopy.forEach((category) => {
        category.products.forEach((product) => {
          if (product.selected && product.has_date) {
            total += product.number_dates;
          }
        });
      });
      return total;
    },
    activeStatuses() {
      if (this.statuses) {
        return this.statuses.filter((status) => status.type === 'assigned' || status.type === 'initial');
      }
      return [];
    },
    closedStatuses() {
      if (this.statuses) {
        return this.statuses.filter((status) => status.type === 'final_positive' || status.type === 'final_negative');
      }
      return [];
    },
    companies() {
      return this.$store.state.companies;
    },
    activeCompany() {
      return this.$store.state.activeCompany;
    },
    activeUser() {
      return this.$store.state.activeUser;
    },
    messages() {
      return this.$store.state.conversationMessages;
    },
    conversationLeads() {
      return this.$store.state.conversationLeads;
    },
    conversationAppointments() {
      return this.$store.state.conversationAppointments;
    },
    flows() {
      return this.$store.state.flows;
    },
    total() {
      return this.$store.state.total;
    },
    conversations() {
      return this.$store.state.conversations;
    },
    activeConversation() {
      return this.$store.state.activeConversation;
    },
    conversationsUsers() {
      return this.$store.state.conversationsUsers;
    },
    conversationHistories() {
      return this.$store.state.conversationHistories;
    },
    conversationRuleHistories() {
      return this.$store.state.conversationRuleHistories;
    },
    conversationTemplates() {
      return this.$store.state.conversationTemplates;
    },
    conversationsUsersList() {
      return this.$store.state.conversationsUsersList;
    },
    nextPageUrl() {
      return this.$store.state.next_page_url;
    },
    nextPageUrlMessages() {
      return this.$store.state.next_page_url_messages;
    },
    activeSender() {
      return this.$store.state.activeSender;
    },
    lists() {
      return this.$store.state.templates.filter((template) => template.type === 'list');
    },
    buttons() {
      return this.$store.state.templates.filter((template) => template.type === 'button');
    },
    templates() {
      return this.$store.state.templates;
    },
    statuses() {
      return this.$store.state.conversationStatuses;
    },
    replies() {
      return this.$store.state.replies;
    },
    documents() {
      return this.$store.state.files;
    },
    forms() {
      return this.$store.state.forms;
    },
    connections() {
      return this.$store.state.connections;
    },
    appointmentConnections() {
      return this.$store.state.appointmentConnections;
    },
    notes() {
      return this.$store.state.conversationNotes;
    },
    sections() {
      return this.$store.state.productSections;
    },
    companyCategories() {
      return this.$store.state.productCategories;
    },
    activeLead() {
      return this.$store.state.activeLead;
    },
    bgChat() {
      if (this.selectedTab === 'chat') {
        return {
          // eslint-disable-next-line global-require
          backgroundImage: `url(${require('@/assets/bg-chat.jpg')})`,
        };
      }
      return {};
    },
    canSend() {
      if (this.activeConversation) {
        switch (this.activeConversation.form_provider) {
          case 'facebook-messenger':
          case 'leadbox-chat':
          case 'some-other':
            return true;
          case 'whatsapp':
          case 'dialog360':
          default:
            return !this.activeConversation.is_after;
        }
      }
      return true;
    },
    source() {
      if (this.activeConversation) {
        let url = '';
        switch (this.activeConversation.form_provider) {
          case 'facebook-messenger':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-messenger.png');
            break;
          case 'instagram-messenger':
            // eslint-disable-next-line global-require
            url = require('@/assets/logo-instagram.png');
            break;
          case 'leadbox-chat':
            // eslint-disable-next-line global-require
            url = require('@/assets/lead.png');
            break;
          case 'dialog360':
          case 'whatsapp':
          case 'manychat-whatsapp':
          case 'autoresponder':
          case 'autoresponder-fundefa':
            // eslint-disable-next-line global-require
            url = require('@/assets/whatsapp.png');
            break;
          default:
            break;
        }
        return url;
      }
      return '';
    },
    voiceUrl() {
      if (this.activeConversation) {
        return `https://leadbox.ec/api/v1/conversations/${this.activeConversation.uuid}/sendVoice`;
      }
      return '';
    },
    filesUrl() {
      if (this.activeConversation) {
        return `https://leadbox.ec/api/v1/conversations/${this.activeConversation.uuid}/uploadFile`;
      }
      return '';
    },
    parsedAppointmentDate() {
      if (this.newAppointment.date) {
        const fechaObjeto = new Date(`${this.newAppointment.date}T00:00:00-05:00`);
        // Obtener los componentes de la fecha
        const dia = fechaObjeto.getDate().toString().padStart(2, '0');
        const mes = (fechaObjeto.getMonth() + 1).toString().padStart(2, '0');
        const year = fechaObjeto.getFullYear();
        // Formatear la fecha en el formato 'DD/MM/YYYY'
        return `${dia}/${mes}/${year}`;
      }
      return '';
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

.bg-header {
  background-color: #353E4B;
}

.border-leadbox-600 {
  border-color: #2A6F7F !important;
}

.text-left {
  text-align: left;
}

.bg-indigo-600 {
  background: #205662 !important;
}

button.bg-sky-600 {
  background-color: #2A6F7F !important;
}

select {
  padding: 7px;
  border: 1px solid #6b7280;
  width: 100%;
  border-radius: 5px;
}

.border-gray-500.border-b-4 {
  border-bottom: 4px solid #2a6f7f;
}

button:focus {
  outline: none !important;
}

.focus\:border-indigo-500:focus {
  border-color: #e5e7eb;
}

.focus\:ring-1:focus {
  --tw-ring-offset-shadow: none;
  --tw-ring-shadow: none;
  box-shadow: none;
}

.text-sidebar {
  font-family: "Avenir Next W01", "Proxima Nova W01", "Rubik", -apple-system, system-ui,
  BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
}

p.text-small {
  font-size: 13px;
}

.notShown {
  visibility: hidden !important;
}

.sidebar-menu.test-sidebar.hidden {
  max-width: 40px;
}

/*Scroll*/
.overflow-auto::-webkit-scrollbar-thumb {
  background-color: #22d3c5;
}

.overflow-auto::-webkit-scrollbar-track {
  background: #fff;
}

.overflow-auto::-webkit-scrollbar {
  width: 5px;
}

.overflow-y-auto::-webkit-scrollbar-thumb {
  background-color: #22d3c5;
}

.overflow-y-auto::-webkit-scrollbar-track {
  background: #fff;
}

.overflow-y-auto::-webkit-scrollbar {
  width: 5px;
}

.border-leadbox-900 {
  border-color: #22d3c5;
}

.text-sidebar {
  font-family: "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  font-size: 0.9rem;
  font-weight: 400;
  line-height: 1.5;
}

@media (max-width: 410px) {
  input.flex-grow.focus\:outline-none.bg-white.text-gray-700.mx-2 {
    max-width: 160px !important;
  }
}

@media (max-width: 600px) {
  .area-message {
    left: -115px;
  }

  .area-notification {
    left: -71px;
  }

  .filter-date {
    padding-right: 0px;
    padding-top: 7px;
    padding-bottom: 11px;
    margin-right: 0px;
    border: none;
  }

  button.mr-1.focus\:outline-none.text-litepie-secondary-400.dark\:text-opacity-70.rounded-md {
    margin-right: 0px;
  }
}

</style>
<style src="@vueform/toggle/themes/default.css"></style>
