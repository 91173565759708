<template>
  <Menu as="div" class="relative inline-block text-left ">
    <div>
      <MenuButton
        class="inline-flex justify-center w-full p-1 text-sm cursor-pointer font-medium">
        <div class="flex-col my-1">
          <img v-if="type === 'chat'" alt="leadbot" class="w-36 h-auto "
               src="@/assets/leadbot.png">
          <img v-if="type === 'dates'" alt="leadbot" class="w-36 h-auto "
               src="@/assets/leaddate.png">
          <img v-if="type === 'leads' || type === 'calendar'
          || type === 'reports' || type === 'contacts'"
               alt="leadbox" class="w-36 h-auto " src="@/assets/leadgo.png">
          <p class="text-xs md:text-sm text-white" v-if="type === 'chat'">Gestiona tus chats</p>
          <p class="text-xs md:text-sm text-white" v-if="type === 'reports'">Reportes</p>
          <p class="text-xs md:text-sm text-white" v-if="type === 'contacts'">
            Gestiona tus contactos</p>
          <p class="text-xs md:text-sm text-white" v-if="type === 'leads' || type === 'calendar'">
            Gestiona tus leads</p>
          <p class="text-xs md:text-sm text-white" v-if="type === 'dates'">
            Gestiona tus citas</p>
        </div>
        <ChevronDownIcon class="ml-3 my-2 h-5 w-5 icon-chevron" aria-hidden="true"/>
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="origin-top-left absolute left-0 w-full rounded-md shadow-lg bg-header ring-1
        ring-black ring-opacity-5 focus:outline-none z-10">
        <div class="py-1" >
          <MenuItem v-slot="{ active }" @click="goToBlank('https://leadbox.ec/app')">
            <div class="flex flex-col justify-center cursor-pointer w-full">
              <div :class="[active ? 'bg-gray-800 text-white' : 'text-white',
              'w-full block p-2 text-sm flex justify-center']">
                <div class="flex-col">
                  <img alt="leadshop" class="w-44 h-auto my-1" src="@/assets/leadbox.png">
                  <p class="text-xs md:text-sm text-white">Family Solutions</p>
                </div>
              </div>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="goTo('/chat')" >
            <div class="flex justify-center cursor-pointer w-full" >
              <div :class="[active ? 'bg-gray-800 text-white' : 'text-white',
              'block p-2 w-full text-sm flex justify-center']">
                <CheckIcon class="h-5 w-5 mx-2 self-center" v-if="type === 'chat'"
                                 aria-hidden="true"/>
                <div class="flex-col">
                  <img alt="leadbot" class="w-44 h-auto my-1" src="@/assets/leadbot.png">
                  <p class="text-xs md:text-sm text-white">Gestiona tus chats</p>
                </div>
              </div>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="goTo('/dates')" >
            <div class="flex justify-center cursor-pointer w-full" >
              <div :class="[active ? 'bg-gray-800 text-white' : 'text-white',
              'block p-2 w-full text-sm flex justify-center']">
                <CheckIcon class="h-5 w-5 mx-2 self-center" v-if="type === 'dates'"
                                 aria-hidden="true"/>
                <div class="flex-col">
                  <img alt="leaddate" class="w-44 h-auto my-1" src="@/assets/leaddate.png">
                  <p class="text-xs md:text-sm text-white">Gestiona tus citas</p>
                </div>
              </div>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="goTo('/leads')" >
            <div class="flex flex-col justify-center cursor-pointer w-full">
              <div :class="[active ? 'bg-gray-800 text-white' : 'text-white',
              'w-full block p-2 text-sm flex justify-center']">
                <CheckIcon class="h-5 w-5 mx-2 self-center" v-if="type === 'leads'"
                           aria-hidden="true"/>
                <div class="flex-col">
                  <img alt="leadbox" class="w-44 h-auto my-1" src="@/assets/leadgo.png">
                  <p class="text-xs md:text-sm text-white">Gestiona tus leads</p>
                </div>
              </div>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="goToBlank('https://comprodesde.casa')">
            <div class="flex flex-col justify-center cursor-pointer w-full">
              <div :class="[active ? 'bg-gray-800 text-white' : 'text-white',
              'w-full block p-2 text-sm flex justify-center']">
                <div class="flex-col">
                  <img alt="leadshop" class="w-44 h-auto my-1" src="@/assets/leadshop.png">
                  <p class="text-xs md:text-sm text-white">Gestiona tus productos</p>
                </div>
              </div>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="goToBlank('/reportes')">
            <div class="flex flex-col justify-center cursor-pointer w-full">
              <div :class="[active ? 'bg-gray-800 text-white' : 'text-white',
              'w-full block p-2 text-sm flex justify-center']">
                <div class="flex-col">
                  <img alt="leadsreports" class="w-44 h-auto my-1" src="@/assets/leadgo.png">
                  <p class="text-xs md:text-sm text-white">Reportes</p>
                </div>
              </div>
            </div>
          </MenuItem>
          <MenuItem v-slot="{ active }" @click="goToBlank('/contacts')">
            <div class="flex flex-col justify-center cursor-pointer w-full">
              <div :class="[active ? 'bg-gray-800 text-white' : 'text-white',
              'w-full block p-2 text-sm flex justify-center']">
                <div class="flex-col">
                  <img alt="leadsreports" class="w-44 h-auto my-1" src="@/assets/leadgo.png">
                  <p class="text-xs md:text-sm text-white">Gestiona tus contactos</p>
                </div>
              </div>
            </div>
          </MenuItem>
<!--          <MenuItem v-slot="{ active }">-->
<!--            <a class="flex justify-center cursor-pointer w-full">-->
<!--              <div :class="[active ? 'bg-gray-800 text-white' : 'text-white',-->
<!--              'w-full block p-2 text-sm flex justify-center']">-->
<!--                <img alt="leadbox" class="w-24 h-auto my-2" src="@/assets/leadplay.png">-->
<!--              </div>-->
<!--            </a>-->
<!--          </MenuItem>-->
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import {
  Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';

import {
  ChevronDownIcon, CheckIcon,
} from '@heroicons/vue/outline';

export default {
  name: 'MainDropDown',
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    CheckIcon,
  },
  props: {
    type: String,
  },
  methods: {
    goTo(address) {
      this.$router.push(address).catch(() => {
      });
    },
    goToBlank(address) {
      window.open(address, '_blank');
    },
  },
};
</script>
<style>
  .bg-teal-900 {
    background: #2A6F7F;
  }
  .bg-header {
    background-color: #353E4B;
  }
  .icon-chevron {
    color: #ffffff;
  }
</style>
