<template>
  <div class="flex flex-col h-screen max-h-screen bg-white">
    <template v-if="isLoadingCompanies">
      <div class="w-full h-full bg-header flex items-center justify-center">
        <div class="flex flex-col">
          <div class="w-full flex justify-center">
            <img alt="loading" src="@/assets/loading.gif" class="w-20">
          </div>
          <p class="text-lg text-white text-center object-none object-center ">
            Vamos por más
          </p>
        </div>
      </div>
    </template>
    <template v-else>
      <!--top bar-->
      <div class="w-full h-16 flex justify-between bg-header">
        <div class="md:w-1/5 w-36 flex justify-items-center cursor-pointer">
          <MainDropDown :type="'reports'" class="w-full"/>
        </div>
        <CompanyDropDown class="p-1" :type="'leads'" @changed-company="changedCompany"/>
        <FormDropDown class="p-1" :type="'leads'" @changed-form="changedForm"/>
                <AreaDropDown v-if="areas?.length > 0"
                              class="p-1" :type="'leads'" @changed-form="changedArea"/>

        <div class="flex-grow"></div>
        <DropDown class="p-1 lg:p-3"/>
      </div>
      <div class="w-full flex justify-between bg-gray-200">
        <div class="flex px-4 overflow-x-auto py-2 w-full md:w-2/3">
          <div class="p-2 mx-2 py-2 cursor-pointer border-leadbox-900"
               @click="selectTab('leads')"
               v-bind:class="{ 'border-b-4' : selectedTab === 'leads' }"
               @keydown="selectTab('leads')">
            <p>Leads</p>
          </div>
          <div class="p-2 mx-2 py-2 cursor-pointer border-leadbox-900 "
               @click="selectTab('chats')"
               v-bind:class="{ 'border-b-4' : selectedTab === 'chats' }"
               @keydown="selectTab('chats')">
            <p>Chats</p>
          </div>
<!--          <div class="p-2 mx-2 cursor-pointer border-leadbox-900 "-->
<!--               @click="selectTab('contacts')"-->
<!--               v-bind:class="{ 'border-b-4' : selectedTab === 'contacts' }"-->
<!--               @keydown="selectTab('contacts')">-->
<!--            <p>Contactos</p>-->
<!--          </div>-->
<!--          <div class="p-2 mx-2 cursor-pointer border-leadbox-900 "-->
<!--               @click="selectTab('users')"-->
<!--               v-bind:class="{ 'border-b-4' : selectedTab === 'users' }"-->
<!--               @keydown="selectTab('users')">-->
<!--            <p>Asesores</p>-->
<!--          </div>-->
<!--          <div class="p-2 mx-2 cursor-pointer border-leadbox-900 "-->
<!--               @click="selectTab('integrations')"-->
<!--               v-bind:class="{ 'border-b-4' : selectedTab === 'integrations' }"-->
<!--               @keydown="selectTab('integrations')">-->
<!--            <p>Integraciones</p>-->
<!--          </div>-->
        </div>
        <div class="w-full md:w-1/3 p-3 py-2">
          <litepie-datepicker class="filter-date" placeholder="Ver por fecha"
                              separator=" hasta " i18n="es" overlay :auto-apply="false"
                              :formatter="formatter" :options="options" v-model="dateValue">
          </litepie-datepicker>
        </div>
      </div>
      <div class="w-full overflow-y-auto bg-scroll bg-contain h-fit"
           id="messages_container">
        <template v-if="selectedTab === 'leads'">
          <div class="p-4 flex flex-col">
            <leads-by-channels :date-value="dateValue" :tab="selectedTab"/>
            <leads-by-forms :date-value="dateValue" :tab="selectedTab"/>

          </div>
        </template>
        <template v-if="selectedTab === 'chats'">
          <div class="p-4 flex flex-col">
            <chats-by-connections :date-value="dateValue" :tab="selectedTab"/>
            <pie-conversation-leads :date-value="dateValue" :tab="selectedTab"/>
            <pie-conversation-by-cities :date-value="dateValue" :tab="selectedTab"/>
            <bar-conversation-by-users :date-value="dateValue" :tab="selectedTab"/>
          </div>
        </template>
        <template v-if="selectedTab === 'contacts'">
          <p>Aqui veras reportes de contactos</p>
        </template>
        <template v-if="selectedTab === 'users'">
          <p>Aqui veras reportes de asesores</p>
        </template>
        <template v-if="selectedTab === 'integrations'">
          <p>Aqui veras reportes de intregraciones </p>
        </template>
        <!--export modal-->
        <ModalBox :showing="showExportModal" @close="showExportModal = false">
          <div class="flex">
            <p class="text-black">
              <b>Enviar documento a:</b></p>
          </div>
          <div class="flex">
            <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                    @click="showExportModal = false"
                    type="button">Cerrar
            </button>
            <button class="group relative w-full flex justify-center py-2 px-4 border
                                border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-sky-600" type="button"
                    @click="showExportModal = true;">Aceptar
            </button>
          </div>
        </ModalBox>

      </div>
    </template>
  </div>

</template>

<script>
import ModalBox from '@/components/ModalBox.vue';
import MainDropDown from '@/components/MainDropDown.vue';
import CompanyDropDown from '@/components/CompanyDropDown.vue';
import FormDropDown from '@/components/FormDropDown.vue';
import AreaDropDown from '@/components/AreaDropDown.vue';
import DropDown from '@/components/DropDown.vue';
import LeadsByChannels from '@/components/reports/leads/LeadsByChannels.vue';
import LeadsByForms from '@/components/reports/leads/LeadsByForms.vue';
import ChatsByConnections from '@/components/reports/chats/ChatsByConnections.vue';
import { ref } from 'vue';
import LitepieDatepicker from 'litepie-datepicker';
import dayjs from '@/plugin/Dayjs';
import axios from '@/http/axios/index';
import PieConversationLeads from '@/components/reports/chats/PieConversationLeads.vue';
import PieConversationByCities from '@/components/reports/chats/PieConversationByCities.vue';
import BarConversationByUsers from '@/components/reports/chats/BarConversationsByUsers.vue';

export default {
  name: 'ReportsView',
  displayName: 'Reportes',
  components: {
    BarConversationByUsers,
    PieConversationLeads,
    PieConversationByCities,
    LeadsByChannels,
    LeadsByForms,
    ChatsByConnections,
    DropDown,
    AreaDropDown,
    FormDropDown,
    CompanyDropDown,
    MainDropDown,
    ModalBox,
    LitepieDatepicker,
  },
  created() {
    const token = localStorage.getItem('accessToken');
    if (token) {
      axios.defaults.headers.common.Authorization = `Bearer ${token}`;
      if (this.companies.length > 0) {
        if (this.activeCompany) {
          this.setActiveCompany();
        }
      } else {
        this.getCompanies();
      }
    } else {
      this.$router.push('').catch(() => {
      });
    }
  },
  data() {
    return {
      selectedTab: 'leads',
      showExportModal: false,
      isLoadingCompanies: false,
      dateValue: ref({
        startDate: dayjs().startOf('month').format('DD-MM-YYYY'),
        endDate: dayjs().endOf('month').format('DD-MM-YYYY'),
      }),
      formatter: ref({
        date: 'DD-MM-YYYY',
        month: 'MMM',
      }),
      options: ref({
        shortcuts: {
          today: 'Hoy',
          yesterday: 'Ayer',
          past: (period) => `últimos ${period} días`,
          currentMonth: 'este mes',
          pastMonth: 'Mes pasado',
        },
        footer: {
          apply: 'Aplicar',
          cancel: 'Cancelar',
        },
      }),
    };
  },
  watch: {
    dateValue(val) {
      console.log('val', val);
    },
  },
  methods: {
    getCompanies() {
      const payload = {};
      this.isLoadingCompanies = true;
      this.$store.dispatch('companies', payload)
        .then((response) => {
          console.log('respuesta companies', response);
          this.setActiveCompany();
          this.isLoadingCompanies = false;
        }, (error) => {
          console.log('error companies', error);
          this.isLoadingCompanies = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error?.data?.message,
          });
        })
        .catch((error) => {
          this.isLoadingCompanies = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error company', error);
        });
    },
    setActiveCompany() {
      const selectedCompany = localStorage.getItem('companyUuid');
      let company = null;
      if (selectedCompany) {
        const index = this.companies.findIndex((comany) => comany.uuid === selectedCompany);
        company = this.companies[index];
      } else {
        [company] = this.companies;
        localStorage.setItem('companyUuid', company.uuid);
      }
      this.$store.dispatch('setActiveCompany', company)
        .then((response) => {
          console.log('respuesta', response);
          this.getForms();
          this.getAreas();
        }, (error) => {
          this.isLoading = false;
          console.log('status', error.data.statusCode);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.isLoading = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
    getForms() {
      // TODO: send pagination
      this.isLoadingForms = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('forms', payload)
        .then((response) => {
          this.isLoadingForms = false;
          console.log('respuesta forms', response);
        }, (error) => {
          this.isLoadingForms = false;
          console.log('error forms', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.isLoadingForms = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error forms', error);
        });
    },
    getAreas() {
      // TODO: send pagination
      this.isLoadingAreas = true;
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('areas', payload)
        .then((response) => {
          this.isLoadingAreas = false;
          console.log('respuesta areas', response);
        }, (error) => {
          this.isLoadingAreas = false;
          console.log('error areas', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data?.message,
          });
        })
        .catch((error) => {
          this.isLoadingAreas = false;
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error areas', error);
        });
    },
    selectTab(tabName) {
      this.selectedTab = tabName;
    },
    changedForm() {
    },
    changedCompany() {
      this.getForms();
      this.getAreas();
    },
    changedArea() {
      this.getForms();
    },
  },
  computed: {
    areas() {
      return this.$store.state.areas;
    },
    companies() {
      return this.$store.state.companies;
    },
    activeCompany() {
      return this.$store.state.activeCompany;
    },
  },
};
</script>
