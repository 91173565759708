<template>
  <Transition name="fade">
<!--eslint-disable-next-line-->
    <div
      v-if="showing"
      class="fixed inset-0 z-10 w-full h-screen flex items-center justify-center"
      :style="{'background-color': 'rgba(0,0,0,0.8)'}"
    >
      <div class="relative w-full max-w-6xl max-h-full bg-white border border-black shadow-lg
      rounded-lg p-8 overflow-y-auto">
        <button aria-label="close" class="absolute top-0 right-0 text-xl m-4 text-black"
                @click.prevent="close">
          <XIcon class="m-3 h-5 w-5 cursor-pointer" aria-hidden="true"/>
        </button>
        <slot />
      </div>
    </div>
  </Transition>
</template>

<script>

import {
  XIcon,
} from '@heroicons/vue/outline';

export default {
  name: 'ModalBox',
  components: {
    XIcon,
  },
  props: {
    showing: {
      required: true,
      type: Boolean,
    },
  },
  watch: {
    showing(value) {
      if (value) {
        return document.querySelector('body').classList.add('overflow-hidden');
      }
      return document.querySelector('body').classList.remove('overflow-hidden');
    },
  },
  methods: {
    close() {
      this.$emit('close');
    },
  },
};
</script>

<style scoped>
.fade-enter-active,
.fade-leave-active {
  transition: all 0.4s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
