<template>
  <ModalBox
    :showing="showModal" @close="close()"
  >
    <div class="flex">
      <p class="text-black text-xl"><b>Cambiar estado a {{ status.name }} - {{
          date.patient_name
        }}</b></p>
    </div>
    <div class="flex flex-col">
      <div class="my-1" v-if="status.activate_products">
        <vue-collapsible-panel-group accordion base-color="white"
                                     v-for="category in categoriesCopy" v-bind:key="category.id">
          <vue-collapsible-panel :expanded="false">
            <template #title>
              <div class="py-1 flex w-full cursor-pointer">
                <div class="pl-1 p-2 w-10/12 text-left text-white text-sidebar">
                  {{ category.name }}
                </div>
              </div>
            </template>
            <template #content>
              <div class="p-2 flex rounded-lg border border-black"
                   v-for="product in category.products" v-bind:key="product.id">
                <div class="w-3/12 flex flex-col text-white">
                  <div class="flex justify-center my-3">
                    <Toggle v-model="product.selected"/>
                  </div>
                  <div class="w-1/2 my-3 flex justify-center flex-col">
                    <div class="flex justify-center">
                      <img v-if="product.image" class="w-14"
                           :src="'https://leadbox.ec/storage/' + product.image"
                           :alt="product.name">
                    </div>
                    <br>
                    <p class="my-2 text-center">{{ product.name }}</p>
                  </div>
                </div>
                <div class="w-9/12 flex flex-col">
                  <div class="w-full flex text-white">
                    <div class="w-1/2">
                      <!--eslint-disable-next-line-->
                      <label>Precio</label>
                      <input class="m-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="product.price" type="text"
                             name="price" :placeholder="company.money_sign+' 00.00'">
                    </div>
                    <div class="w-1/2">
                      <!--eslint-disable-next-line-->
                      <label>Cantidad</label>
                      <input type="number"
                             class="m-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" placeholder="0"
                             v-model="product.quantity" min="1">
                    </div>
                  </div>
                  <div class="w-full flex text-white flex-col">
                    <!--eslint-disable-next-line-->
                    <label>Nota</label>
                    <textarea class="m-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="product.note"
                              name="note">
                    </textarea>
                  </div>
                  <div class="w-full flex text-white text-md justify-center">
                    <p>
                      {{ company.money_sign }} {{ product.quantity * product.price }}</p>
                  </div>
                </div>
              </div>
            </template>
          </vue-collapsible-panel>
        </vue-collapsible-panel-group>
        <div class="w-full flex text-lg justify-center">
          <p><b>TOTAL: </b>
            {{ company.money_sign }} {{ total_products }}</p>
        </div>
        <div class="w-full flex flex-col">
          <!--eslint-disable-next-line-->
          <label for="title"><b>fecha de venta:</b></label>
          <input type="date" v-model="sell_date" id="sell_date"
                 class="my-1 appearance-none rounded-none relative block w-full
                                         p-2 border border-gray-300 placeholder-gray-500
                                         text-gray-900
                                      rounded-b-md focus:outline-none focus:ring-indigo-500
                                          focus:border-indigo-500 focus:z-10 sm:text-sm">
        </div>
      </div>
      <div class="my-1" v-if="status.activate_attachments">
        <p class="w-full text-left">
          <b>Comparte información importante para el cliente:</b>
        </p>
        <div>
          <!--eslint-disable-next-line-->
          <label class="w-full text-left" for="productSection"><b>Secciones:</b></label>
          <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="setCategories()"
                  id="productSection" v-model="section" name="productSection">
            <option :value="null" class="px-3 py-2 text-sm">--Seleccione uno--</option>
            <option :value="'todas'" class="px-3 py-2 text-sm">Todas</option>
            <option v-for="(section) in sections"
                    v-bind:key="section.id" :value="section"
                    class="px-3 py-2 text-sm">
              {{ section.name }}
            </option>
          </select>
        </div>
        <div>
          <!--eslint-disable-next-line-->
          <label class="w-full text-left" for="productCategory"><b>Categorias:</b></label>
          <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="setProducts()"
                  id="productCategory" v-model="category" name="productSection">
            <option :value="null" class="px-3 py-2 text-sm">--Seleccione uno--</option>
            <option v-for="(category) in categories"
                    v-bind:key="category.id" :value="category"
                    class="px-3 py-2 text-sm">
              {{ category.name }}
            </option>
          </select>
        </div>
        <div>
          <!--eslint-disable-next-line-->
          <label class="w-full text-left" for="product"><b>Productos:</b></label>
          <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="products.length < 1"
                  @change="setShareProduct()"
                  id="product" v-model="product" name="productSection">
            <option :value="null" class="px-3 py-2 text-sm">--Seleccione uno--</option>
            <option v-for="(prod) in products"
                    v-bind:key="prod.id" :value="prod"
                    class="px-3 py-2 text-sm">
              {{ prod.name }}
            </option>
          </select>
        </div>
        <template v-if="product">
          <p class="w-full text-center text-md">
            <b>Selecciona los medios para compartir con el cliente</b>
          </p>
          <!--datasheet-->
          <div class="flex justify-center my-2" v-if="shareDatasheet.active">
            <div class="w-4/12 py-3">
              <div class="text-left ml-1"><b>Ficha Técnica</b></div>
            </div>
            <div class="w-2/12 m-1">
              <div class="rounded-full cursor-pointer w-10/12"
                   v-bind:class="{'bg-gray-300': !shareDatasheet.email,
                   'bg-blue-500': shareDatasheet.email }"
                   @click="shareDatasheet.email = !shareDatasheet.email"
                   @keydown="shareDatasheet.email = !shareDatasheet.email"
                   data-toggle="tooltip" data-placement="top" title="Enviar por email">
                <div class="text-center p-2 flex justify-center">
                  <MailIcon class="h-7 w-7 text-white" aria-hidden="true"/>
                </div>
              </div>
            </div>
            <div class="w-2/12 m-1">
              <div class="rounded-full cursor-pointer w-10/12 bg-gray-300"
                   @click="copyUrl(shareDatasheet.url)"
                   @keydown="copyUrl(shareDatasheet.url)"
                   data-toggle="tooltip" data-placement="top" title="Copiar enlace">
                <div class="text-center p-2 flex justify-center">
                  <ClipboardCopyIcon class="h-7 w-7 text-white" aria-hidden="true"/>
                </div>
              </div>
            </div>
            <div class="w-2/12 m-1" v-if="lead.phone_is_valid">
              <!--eslint-disable-next-line-->
              <a :href="shareDatasheet.wp"
                 target="_blank"
                 data-toggle="tooltip" data-placement="top" title="Enviar por whatsapp">
                <div class="rounded-full cursor-pointer w-10/12 bg-wp">
                  <div class="text-center p-2 flex justify-center">
                    <MailIcon class="h-7 w-7 text-white" aria-hidden="true"/>
                  </div>
                </div>
              </a>
            </div>
            <a class="w-2/12 cursor-pointer py-3" :href="shareDatasheet.url" target="_blank">
              <span class="self-center text-center"><b>Ver</b></span>
            </a>
          </div>
          <!--video-->
          <div class="flex justify-center my-2" v-if="shareVideo.active">
            <div class="w-4/12 py-3">
              <div class="text-left ml-1"><b>Video del Producto</b></div>
            </div>
            <div class="w-2/12 m-1">
              <div class="rounded-full cursor-pointer w-10/12"
                   v-bind:class="{'bg-gray-300': !shareVideo.email,
                   'bg-blue-500': shareVideo.email }"
                   @click="shareVideo.email = !shareVideo.email"
                   @keydown="shareVideo.email = !shareVideo.email"
                   data-toggle="tooltip" data-placement="top" title="Enviar por email">
                <div class="text-center p-2 flex justify-center">
                  <MailIcon class="h-7 w-7 text-white" aria-hidden="true"/>
                </div>
              </div>
            </div>
            <div class="w-2/12 m-1">
              <div class="rounded-full cursor-pointer w-10/12 bg-gray-300"
                   @click="copyUrl(shareVideo.url)"
                   @keydown="copyUrl(shareVideo.url)"
                   data-toggle="tooltip" data-placement="top" title="Copiar enlace">
                <div class="text-center p-2 flex justify-center">
                  <ClipboardCopyIcon class="h-7 w-7 text-white" aria-hidden="true"/>
                </div>
              </div>
            </div>
            <div class="w-2/12 m-1" v-if="lead.phone_is_valid">
              <!--eslint-disable-next-line-->
              <a class="" :href="shareVideo.wp"
                 target="_blank"
                 data-toggle="tooltip" data-placement="top" title="Enviar por whatsapp">
                <div class="rounded-full cursor-pointer w-10/12 bg-wp">
                  <div class="text-center p-2 flex justify-center">
                    <MailIcon class="h-7 w-7 text-white" aria-hidden="true"/>
                  </div>
                </div>

              </a>
            </div>
            <a class="w-2/12 cursor-pointer py-3" :href="shareVideo.url" target="_blank">
              <span class="self-center text-center"><b>Ver</b></span>
            </a>
          </div>
          <!--catalog-->
          <div class="flex justify-center my-2" v-if="catalog.active">
            <div class="w-4/12 py-3">
              <div class="text-left ml-1"><b>Catálogo general</b></div>
            </div>
            <div class="w-2/12 m-1">
              <div class="rounded-full cursor-pointer w-10/12"
                   v-bind:class="{'bg-gray-300': !catalog.email,
                   'bg-blue-500': catalog.email }"
                   @click="catalog.email = !catalog.email"
                   @keydown="catalog.email = !catalog.email"
                   data-toggle="tooltip" data-placement="top" title="Enviar por email">
                <div class="text-center p-2 flex justify-center">
                  <MailIcon class="h-7 w-7 text-white" aria-hidden="true"/>
                </div>
              </div>
            </div>
            <div class="w-2/12 m-1">
              <div class="rounded-full cursor-pointer w-10/12 bg-gray-300"
                   @click="copyUrl(catalog.url)"
                   @keydown="copyUrl(catalog.url)"
                   data-toggle="tooltip" data-placement="top" title="Copiar enlace">
                <div class="text-center p-2 flex justify-center">
                  <ClipboardCopyIcon class="h-7 w-7 text-white" aria-hidden="true"/>
                </div>
              </div>
            </div>
            <div class="w-2/12 m-1" v-if="lead.phone_is_valid">
              <!--eslint-disable-next-line-->
              <a class="" :href="catalog.wp"
                 target="_blank"
                 data-toggle="tooltip" data-placement="top" title="Enviar por whatsapp">
                <div class="rounded-full cursor-pointer w-10/12 bg-wp">
                  <div class="text-center p-2 flex justify-center">
                    <MailIcon class="h-7 w-7 text-white" aria-hidden="true"/>
                  </div>
                </div>
              </a>
            </div>
            <a class="w-2/12 cursor-pointer py-3" :href="catalog.url" target="_blank">
              <span class="self-center text-center"><b>Ver</b></span>
            </a>
          </div>
          <!--video general-->
          <div class="flex justify-center my-2" v-if="generalVideo.active">
            <div class="w-4/12 py-3">
              <div class="text-left ml-1"><b>Video general</b></div>
            </div>
            <div class="w-2/12 m-1">
              <div class="rounded-full cursor-pointer w-10/12"
                   v-bind:class="{'bg-gray-300': !generalVideo.email,
                   'bg-blue-500': generalVideo.email }"
                   @click="generalVideo.email = !generalVideo.email"
                   @keydown="generalVideo.email = !generalVideo.email"
                   data-toggle="tooltip" data-placement="top" title="Enviar por email">
                <div class="text-center p-2 flex justify-center">
                  <MailIcon class="h-7 w-7 text-white" aria-hidden="true"/>
                </div>
              </div>
            </div>
            <div class="w-2/12 m-1">
              <div class="rounded-full cursor-pointer w-10/12  bg-gray-300"
                   @click="copyUrl(generalVideo.url)"
                   @keydown="copyUrl(generalVideo.url)"
                   data-toggle="tooltip" data-placement="top" title="Copiar enlace">
                <div class="text-center p-2 flex justify-center">
                  <ClipboardCopyIcon class="h-7 w-7 text-white" aria-hidden="true"/>
                </div>
              </div>
            </div>
            <div class="w-2/12 m-1" v-if="lead.phone_is_valid">
              <!--eslint-disable-next-line-->
              <a class="" :href="generalVideo.wp"
                 target="_blank"
                 data-toggle="tooltip" data-placement="top" title="Enviar por whatsapp">
                <div class="rounded-full cursor-pointer w-10/12 bg-wp">
                  <div class="text-center p-2 flex justify-center">
                    <MailIcon class="h-7 w-7 text-white" aria-hidden="true"/>
                  </div>
                </div>
              </a>
            </div>
            <a class="w-2/12 cursor-pointer py-3" :href="generalVideo.url" target="_blank">
              <span class="self-center text-center"><b>Ver</b></span>
            </a>
          </div>

          <p class="w-full text-center text-md">
            <b>Emails de destinatarios</b>
          </p>
          <div class="flex w-full py-1" v-for="(email, key) in fileReceptors" v-bind:key="key">
            <!--eslint-disable-next-line-->
            <input type="email" class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm w-10/12"
                   v-model="fileReceptors[key]" placeholder="ingresa el email">
            <!--eslint-disable-next-line-->
            <div class="w pt-2" @click="deleteFileReceptorEmail(key)"
                 @keydown="deleteFileReceptorEmail(key)">
              <TrashIcon class="h-8 w-8 text-black" aria-hidden="true"/>
            </div>
          </div>
          <template v-if="showCreateEmail">
            <div class="flex flex-col w-full">
              <!--eslint-disable-next-line-->
              <label for="newEmail"><b>Agregar destinatario:</b></label>
              <input type="email" v-model="selectedEmail" id="newEmail"
                     class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
            </div>
            <div class="flex w-full">
              <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                      @click="showCreateEmail = false; selectedEmail = null;"
                      type="button">Cancelar
              </button>
              <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                      @click="addEmail()">Aceptar
              </button>
            </div>
          </template>
          <template v-else>
            <div class="flex justify-end w-full">
              <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                      @click="showCreateEmail = true;">Agregar email
              </button>
            </div>
          </template>
        </template>
      </div>
      <div class="my-1" v-if="status.activate_options">
        <p class="w-full text-left">
          <b>Ingresa las opciones del estado:</b>
        </p>
        <div class="flex" v-for="(option, key) in options"
             v-bind:key="key">
          <div class="w-4/12">
            <p><b>{{ option.key }} : </b></p>
          </div>
          <div class="w-8/12">
            <!--eslint-disable-next-line-->
            <input type="text" v-model="option.value"
                   class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
          </div>
        </div>
        <template v-if="showOptionInterface">
          <div class="flex flex-col">
            <!--eslint-disable-next-line-->
            <label for="newOption"><b>Agregar nueva opción:</b></label>
            <input type="text" v-model="newOption.key" id="newOption"
                   class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
          </div>
          <div class="flex">
            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                    @click="showOptionInterface = false; newOption = {key: null, value: null};"
                    type="button">Cancelar
            </button>
            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                    @click="createOption()">Aceptar
            </button>
          </div>
        </template>
        <template v-else>
          <div class="flex justify-end">
            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                    @click="showOptionInterface = true;">Agregar Opción
            </button>
          </div>
        </template>
      </div>
      <div class="my-1" v-if="status.activate_whatsapp">
        <div class="flex">
          <p class="text-black"><b>Enviar template por whatsapp</b></p>
        </div>
        <form class="flex-col flex">
          <!--eslint-disable-next-line-->
          <label for="sender_phone" class="my-2">Número de origen</label>
          <select class="my-1 bg-white appearance-none rounded-none relative block bg-gray-100
                                 px-3 py-2 border border-gray-300 placeholder-gray-500 h-10
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-2.5
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="sender_phone"
                  v-model="connection"
                  name="connection_id">
            <option :value="null" class="px-3 py-2 text-sm text-center">
              -- Selecciona el número de destino --
            </option>
            <option v-for="(connection) in connections"
                    v-bind:key="connection.id" :value="connection"
                    class="px-3 py-2 text-sm">
              {{ connection.name }} {{ connection.phone_number }}
            </option>
          </select>
          <template v-if="connection">
            <!--eslint-disable-next-line-->
            <label for="template_selected" class="my-2">Plantillas</label>
            <select class="my-1 bg-white appearance-none rounded-none relative block bg-gray-100
                                 px-3 py-2 border border-gray-300 placeholder-gray-500 h-10
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-2.5
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="template_selected"
                    v-model="dataTemplate.selectedTemplate" @change="setTemplateComponents"
                    name="connection_id">
              <option :value="null" class="px-3 py-2 text-sm text-center">
                -- Selecciona la plantilla --
              </option>
              <option v-for="(template, key) in dataTemplate.templates"
                      v-bind:key="key" :value="template"
                      class="px-3 py-2 text-sm">
                {{ template.name }}
              </option>
            </select>

            <template v-if="dataTemplate.selectedTemplate">

              <p><b>Texto de la plantilla</b></p>
              <div class="flex w-full"
                   v-for="(component, key) in dataTemplate.selectedTemplate.components"
                   v-bind:key="key">
                <template v-if="component.type === 'BODY'">
                  <div>
                    <b>{{ component.type }}</b> {{ component.text }}
                  </div>
                </template>
                <template v-if="component.type === 'HEADER'">
                  <div>
                    <b>{{ component.type }}</b> {{ component.format }}
                  </div>
                </template>
              </div>
              <div class="flex-col w-full "
                   v-for="(component, key) in dataTemplate.components"
                   v-bind:key="key">
                <b class="w-full">Variables de {{ component.type }}</b>
                <template v-if="component.type === 'body'">
                  <template v-for="(parameter, j) in component.parameters"
                            v-bind:key="j">
                    <div class="flex w-full">
                      <p class="p-3"><b>{{ j + 1 }}</b></p>
                      <input class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" type="text" v-model="parameter.text"
                             @input="setTemplateTexts">
                    </div>
                  </template>
                </template>
                <template v-if="component.type === 'header'">
                  <template v-for="(parameter, k) in component.parameters"
                            v-bind:key="k">
                    <div class="flex w-full">
                      <p class="p-3 flex-none"><b>{{ k + 1 }}</b></p>
                      <div class="flex bg-transparent w-full grow">
                        <!--eslint-disable-next-line-->
                        <input placeholder="Escríbe el url de la imagen o pega tu imagen"
                               type="text"
                               class="my-2 appearance-none rounded-none block
                                       w-full px-3 py-2 border border-gray-300 placeholder-gray-500
                                       text-gray-900 rounded-b-md focus:outline-none
                                       focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                       sm:text-sm" v-model="parameter.image.link"
                               @input="setTemplateTexts"
                               @paste="onPasteTemplates" :id="key"
                               :aria-label="k"
                        />
                      </div>
                      <!-- <DropZone @files-dropped="addFilesTemplate" class="grow"-->
                      <!--                      style="flex-grow: 1" :id="key"-->
                      <!--                            :aria-label="k"-->
                      <!--              @active="setDropZoneActiveTemplates">-->
                      <!--                 <template v-if="dropzoneActiveTemplates">-->
                      <!--  <div class="w-full flex bg-transparent justify-center grow">-->
                      <!--       <div class="rounded-lg m-2 bg-white border-dashed border-2-->
                      <!--               text-gray-400 border-gray-400 p-3 w-full">-->
                      <!-- <p class="text-md text-center">Suelta tus archivos aquí</p>-->
                      <!--                                </div>-->
                      <!--                              </div>-->
                      <!--                            </template>-->
                      <!--                            <template v-else>-->
                      <!--                              -->
                      <!--                            </template>-->
                      <!--                          </DropZone>-->
                      <PaperClipIcon class="mx-1 my-3 text-gray-400 w-6 h-6 flex-none
                                                cursor-pointer"
                                     @click="openFilePickerTemplate(k)" aria-hidden="true"
                                     data-toggle="tooltip" data-placement="top"
                                     v-if="canSend"
                                     title="Adjuntar" alt="Adjuntar"/>
                    </div>
                    <div class="flex" v-if="isUploading">
                      <p>Subiendo imagen ...</p>
                    </div>
                    <input :ref="'templateInput'+k" type="file"
                           :id="key"
                           :aria-label="k" @change="onInputChangeTemplates" class="hidden"/>
                  </template>
                  <!--eslint-disable-next-line-->

                </template>
              </div>
              <p><b>Vista previa</b></p>
              <p><b>HEADER</b></p>
              <div class="flex w-full"
                   v-for="(head, key) in dataTemplate.header"
                   v-bind:key="key">
                <p v-if="head.type === 'text'">{{ head.value }}</p>
                <img v-if="head.type === 'image'" :src="head.value" alt="image"/>
              </div>
              <p><b>BODY</b></p>
              <div class="flex w-full"
                   v-for="(text, key) in dataTemplate.body"
                   v-bind:key="key">
                <p v-if="text.type === 'text'">{{ text.value }}</p>
              </div>

            </template>
            <!--eslint-disable-next-line-->
            <label for="destination" class="my-2">Número de destino</label>
            <input id="destination" class="p-2 my-1 bg-gray-100 text-sm focus:outline-none"
                   v-model="dataTemplate.destination" type="tel"
                   placeholder="Número de destino">
            <!--eslint-disable-next-line-->
            <label for="destination_name" class="my-2">Nombre</label>
            <input id="destination_name"
                   class="p-2 my-1 bg-gray-100 text-sm focus:outline-none"
                   v-model="dataTemplate.name" type="text"
                   placeholder="Nombre del destinatario">
            <!--eslint-disable-next-line-->
            <label for="flow_selected" class="my-2">Flujo de destino</label>
            <select class="my-1 bg-white appearance-none rounded-none relative block bg-gray-100
                                 px-3 py-2 border border-gray-300 placeholder-gray-500 h-10
                                 text-gray-900 rounded-b-md focus:outline-none w-full mr-2.5
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" id="flow_selected"
                    v-model="dataTemplate.selectedFlow"
                    name="connection_id">
              <option :value="null" class="px-3 py-2 text-sm text-center">
                -- Selecciona flujo --
              </option>
              <option v-for="(flow, key) in flows"
                      v-bind:key="key" :value="flow"
                      class="px-3 py-2 text-sm">
                {{ flow.name }}
              </option>
            </select>
          </template>
        </form>
      </div>
      <div class="my-1" v-if="status.activate_reasons">
        <p class="w-full text-left">
          <b>Selecione el motivo de cierre:</b>
        </p>
        <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm"
                id="reason" v-model="reason" name="reason">
          <option :value="null" class="px-3 py-2 text-sm">--Seleccione uno--</option>
          <option v-for="(reason) in status.reasons"
                  v-bind:key="reason.id" :value="reason"
                  class="px-3 py-2 text-sm">
            {{ reason.name }}
          </option>
        </select>
      </div>
      <div class="my-1" v-if="status.activate_custom_transfer">
        <p class="w-full text-left">
          <b>transferir este lead a la empresa {{ selectedTransferCompany.name }}</b>
        </p>
        <!--eslint-disable-next-line-->
        <label for="transferForm">Formulario:</label>
        <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm"
                id="transferForm" v-model="transferForm" name="transferForm">
          <option :value="null" class="px-3 py-2 text-sm">--Seleccione formulario--</option>
          <option v-for="(form) in transferForms"
                  v-bind:key="form.id" :value="form"
                  class="px-3 py-2 text-sm">
            {{ form.name }}
          </option>
        </select>
        <!--eslint-disable-next-line-->
        <label for="transferStatus">Estado:</label>
        <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm"
                id="transferStatus" v-model="transferStatus" name="transferStatus">
          <option :value="null" class="px-3 py-2 text-sm">--Seleccione estado de destino--</option>
          <option v-for="(status) in transferStatuses"
                  v-bind:key="status.id" :value="status"
                  class="px-3 py-2 text-sm">
            {{ status.name }}
          </option>
        </select>
        <!--eslint-disable-next-line-->
        <label for="transferUser">Usuario:</label>
        <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm"
                id="transferUser" v-model="transferUser" name="transferUser">
          <option :value="null" class="px-3 py-2 text-sm">--Seleccione usuario--</option>
          <option v-for="(user) in transferUsers"
                  v-bind:key="user.id" :value="user"
                  class="px-3 py-2 text-sm">
            {{ user.name }}
          </option>
        </select>
      </div>
      <div class="my-1" v-if="status.activate_transfer">
        <p class="w-full text-left">
          <b>Transferir este lead:</b>
        </p>
        <p v-if="this.transferType === 'user'">
          Este lead se va a trasferir al usuario: {{ this.selectedTransfer.name }}
        </p>
        <p v-if="this.transferType === 'form'">
          Este lead se va a trasferir al formulario: {{ this.selectedTransfer.name }}
        </p>
      </div>
      <div class="my-1" v-if="status.activate_documents">
        <p class="w-full text-left">
          <b>Ingresa los datos para generar un documento PDF:</b>
        </p>
        <div>
          <div class="flex flex-col">
            <!--eslint-disable-next-line-->
            <label for="title"><b>Título:</b></label>
            <input type="text" v-model="document.name" id="title"
                   class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
          </div>
        </div>
        <p class="w-full text-center text-md">
          <b>Emails de destinatarios</b>
        </p>
        <div class="flex w-full py-1" v-for="(email, key) in documentReceptors" v-bind:key="key">
          <!--eslint-disable-next-line-->
          <input type="email" class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm w-10/12"
                 v-model="documentReceptors[key]" placeholder="ingresa el email">
          <!--eslint-disable-next-line-->
          <div class="w pt-2" @click="deleteDocumentReceptorEmail(key)"
               @keydown="deleteDocumentReceptorEmail(key)">
            <TrashIcon class="h-8 w-8 text-black" aria-hidden="true"/>
          </div>
        </div>
        <template v-if="showCreateDocumentEmail">
          <div class="flex flex-col w-full">
            <!--eslint-disable-next-line-->
            <label for="newDocumentEmail"><b>Agregar destinatario:</b></label>
            <input type="email" v-model="selectedDocumentEmail" id="newDocumentEmail"
                   class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
          </div>
          <div class="flex w-full">
            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                    @click="showCreateDocumentEmail = false; selectedDocumentEmail = null;"
                    type="button">Cancelar
            </button>
            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                    @click="addDocumentEmail()">Aceptar
            </button>
          </div>
        </template>
        <template v-else>
          <div class="flex justify-end w-full">
            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                    @click="showCreateDocumentEmail = true;">Agregar email
            </button>
          </div>
        </template>
        <p class="w-full text-center">
          <b>Seleccione categorias y produtos:</b>
        </p>
        <div>
          <!--eslint-disable-next-line-->
          <label class="w-full text-left" for="documentCategory"><b>Categorias:</b></label>
          <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" @change="setDocumentProducts()"
                  id="documentCategory" v-model="document.category" name="productSection">
            <option :value="null" class="px-3 py-2 text-sm">--Seleccione uno--</option>
            <option v-for="(category) in companyCategories"
                    v-bind:key="category.id" :value="category"
                    class="px-3 py-2 text-sm">
              {{ category.name }}
            </option>
          </select>
        </div>
        <div>
          <!--eslint-disable-next-line-->
          <label class="w-full text-left" for="documentProduct"><b>Productos:</b></label>
          <select class="my-2 appearance-none rounded-none relative block w-full
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" :disabled="documentProducts?.length < 1"
                  @change="setDocumentProduct()"
                  id="documentProduct" v-model="document.product" name="productSection">
            <option :value="null" class="px-3 py-2 text-sm">--Seleccione uno--</option>
            <option v-for="(prod) in documentProducts"
                    v-bind:key="prod.id" :value="prod"
                    class="px-3 py-2 text-sm">
              {{ prod.name }}
            </option>
          </select>
        </div>
        <div>
          <div class="flex flex-col">
            <!--eslint-disable-next-line-->
            <label for="price"><b>Precio:</b></label>
            <input type="text" v-model="document.price" id="price"
                   class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
          </div>
        </div>
        <p class="w-full text-center">
          <b>Campos del formulario:</b>
        </p>
        <div>
          <div class="flex flex-col">
            <!--eslint-disable-next-line-->
            <label for="ci"><b>CI/RUC:</b></label>
            <input type="text" v-model="document.CI" id="ci" placeholder="ingresa el CI/RUC"
                   class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
          </div>
        </div>
        <div>
          <div class="flex flex-col">
            <!--eslint-disable-next-line-->
            <label for="address"><b>Dirección:</b></label>
            <input type="text" v-model="document.address" id="address"
                   placeholder="ingresa la dirección"
                   class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
          </div>
        </div>
        <div class="flex" v-for="(field, key) in document.fields"
             v-bind:key="key">
          <div class="w-4/12">
            <p><b>{{ field.name }} : </b></p>
          </div>
          <div class="w-8/12">
            <!--eslint-disable-next-line-->
            <input type="text" v-model="field.value"
                   class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
          </div>
        </div>
        <template v-if="showFieldInterface">
          <div class="flex flex-col">
            <!--eslint-disable-next-line-->
            <label for="newField"><b>Agregar nuevo campo:</b></label>
            <input type="text" v-model="newField.name" id="newField"
                   class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
          </div>
          <div class="flex">
            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                    @click="showFieldInterface = false;
                    newField = {key: null, value: null, name: null};"
                    type="button">Cancelar
            </button>
            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                    @click="createField()">Aceptar
            </button>
          </div>
        </template>
        <template v-else>
          <div class="flex justify-end">
            <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                    @click="showFieldInterface = true;">Agregar campo
            </button>
          </div>
        </template>
        <div class="w-full flex">
          <div class="m-1">
            <Toggle v-model="document.addNote"/>
          </div>
          <p class="ml-1">Agregar Nota al documento?</p>
        </div>
      </div>
      <div class="my-1" v-if="status.activate_calendar">
        <p class="w-full text-left">
          <b>Agendar un recordatorio:</b>
        </p>
        <div>
          <div class="flex flex-col">
            <!--eslint-disable-next-line-->
            <label for="eventTitle"><b>Título:</b></label>
            <input type="text" v-model="newEvent.title" id="eventTitle"
                   class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
          </div>
        </div>
        <div>
          <div class="flex flex-col">
            <!--eslint-disable-next-line-->
            <label for="eventDate"><b>Fecha:</b></label>
            <input type="date" v-model="newEvent.date" id="eventDate"
                   class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
          </div>
        </div>
        <div>
          <div class="flex flex-col">
            <!--eslint-disable-next-line-->
            <label for="eventHour"><b>Hora:</b></label>
            <input type="time" v-model="newEvent.time" id="eventHour" step="60"
                   class="my-1 appearance-none rounded-none relative block w-full
                                 p-2 border border-gray-300 placeholder-gray-500 text-gray-900
                                 rounded-b-md focus:outline-none focus:ring-indigo-500
                                  focus:border-indigo-500 focus:z-10 sm:text-sm">
          </div>
        </div>
        <p class="w-full text-center">
          <b>notificaciones:</b>
        </p>
        <div class="flex w-full">
          <div class="w-1/2 flex" v-for="(notification, key) in notifications"
               v-bind:key="key">
            <select class="my-2 appearance-none rounded-none relative block w-full w-10/12
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="notifications[key]">
              <option :value="null" class="px-3 py-2 text-sm">--Seleccione uno--</option>
              <option v-for="(type, k) in notificationTypes"
                      v-bind:key="k" :value="type"
                      class="px-3 py-2 text-sm">
                {{ type.label }}
              </option>
            </select>
            <!--eslint-disable-next-line-->
            <div class="w-2/12 text-red-500" @click="deleteNotification(key)"
                 @keydown="deleteNotification(key)">
              <TrashIcon class="h-8 w-8 my-2 text-black" aria-hidden="true"/>
            </div>
          </div>
        </div>
        <div class="flex justify-end w-full">
          <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                  @click="addNotification()">Agregar notificación
          </button>
        </div>
      </div>
      <div class="my-1" v-if="status.activate_integrations">
        <template v-for="integration in companyIntegrations" v-bind:key="integration.id">
          <div class="w-full text-left flex" v-if="status.integrations[integration.type]">
            <img class="w-40" :src="getImage(integration.type)" :alt="integration.type">
            <p class="m-2">Este lead se enviará a la integración {{ integration.name }}</p>
          </div>
          <div v-if="integration.type === 'vtiger' || integration.type === 'teojama'">
            <!--eslint-disable-next-line-->
            <label for="integrationUser"><b>Seleccione usuario</b></label>
            <select class="my-2 appearance-none rounded-none relative block w-full w-10/12
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newIntegration.user"
                    id="integrationUser">
              <option :value="null" class="px-3 py-2 text-sm">--Seleccione uno--</option>
              <option v-for="(user, w) in integrationUsers[integration.type]"
                      v-bind:key="w" :value="user"
                      class="px-3 py-2 text-sm">
                {{ user.first_name }} {{ user.last_name }}
              </option>
            </select>
          </div>
        </template>
        <div class="my-1">
          <div class="flex w-full">
            <!--eslint-disable-next-line-->
            <input type="checkbox" class="my-2" v-model="newIntegration.reassignUser">
            <p class="m-1">Reasignar Lead</p>
          </div>
          <div v-if="newIntegration.reassignUser">
            <!--eslint-disable-next-line-->
            <label for="reasignedLead"><b>Seleeccione usuario para reasignar</b></label>
            <select class="my-2 appearance-none rounded-none relative block w-full w-10/12
                                 px-3 py-2 border border-gray-300 placeholder-gray-500
                                 text-gray-900 rounded-b-md focus:outline-none
                                 focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                                  sm:text-sm" v-model="newIntegration.reassignedUser"
                    id="reasignedLead">
              <option :value="null" class="px-3 py-2 text-sm">--Seleccione uno--</option>
              <option v-for="(user, y) in leadsUsersList"
                      v-bind:key="y" :value="user"
                      class="px-3 py-2 text-sm">
                {{ user.name }}
              </option>
            </select>
          </div>
        </div>
      </div>
      <div class="form-group my-1">
        <!--eslint-disable-next-line-->
        <label for="statusNote"><b>Nota</b></label>
        <textarea class="my-2 appearance-none rounded-none relative block w-full
                         px-3 py-2 border border-gray-300 placeholder-gray-500
                         text-gray-900 rounded-b-md focus:outline-none
                         focus:ring-indigo-500 focus:border-indigo-500 focus:z-10
                         sm:text-sm" v-model="note"
                  name="statusNote" id="statusNote">
                    </textarea>
      </div>
      <!--      <div class="flex w-full my-1">-->
      <!--        <button class="group relative w-full flex justify-center py-2 px-4-->
      <!--                            border border-transparent text-sm font-medium rounded-md-->
      <!--                            text-white mx-1-->
      <!--                                bg-sky-600 hover:bg-sky-900 " type="button"-->
      <!--                @click="saveJustStatus()">Solo cambiar de estado-->
      <!--        </button>-->
      <!--      </div>-->
      <div class="flex w-full my-1">
        <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white
                                bg-gray-300 hover:bg-gray-400 focus:outline-none focus:ring-2
                                focus:ring-offset-2 focus:ring-gray-500 mx-1"
                @click="close()"
                type="button">Cancelar
        </button>
        <button class="group relative w-full flex justify-center py-2 px-4
                            border border-transparent text-sm font-medium rounded-md text-white mx-1
                                bg-sky-600 hover:bg-sky-900 " type="button"
                @click="saveStatus()">Aceptar
        </button>
      </div>
    </div>
  </ModalBox>
</template>
<script>

import ModalBox from '@/components/ModalBox.vue';
import Toggle from '@vueform/toggle';
import dayjs from '@/plugin/Dayjs';
import {
  VueCollapsiblePanelGroup,
  VueCollapsiblePanel,
} from '@dafcoe/vue-collapsible-panel';
import {
  MailIcon, ClipboardCopyIcon, TrashIcon,
} from '@heroicons/vue/outline';

class UploadableFile {
  constructor(file) {
    this.file = file;
    this.id = `${file.name}-${file.size}-${file.lastModified}-${file.type}`;
    this.url = URL.createObjectURL(file);
    this.status = null;
  }
}

export default {
  name: 'StatusModal',
  components: {
    ModalBox,
    VueCollapsiblePanelGroup,
    VueCollapsiblePanel,
    MailIcon,
    ClipboardCopyIcon,
    TrashIcon,
    Toggle,
  },
  props: {
    showModal: {
      required: true,
      type: Boolean,
    },
    status: Object,
  },
  data() {
    return {
      note: null,
      reason: null,
      transferForm: null,
      transferUser: null,
      transferStatus: null,
      selectedTransfer: null,
      selectedTransferCompany: null,
      transferForms: [],
      transferStatuses: [],
      transferUsers: [],
      transferType: null,
      connection: null,
      dataTemplate: {
        destination: null,
        name: null,
        templates: [],
        selectedTemplate: null,
        components: [],
        texts: [],
        hmMedic: null,
        selectedFlow: null,
        to: [],
      },
      canSend: true,
      isUploading: false,
      section: null,
      category: null,
      product: null,
      sell_date: null,
      showOptionInterface: false,
      showFieldInterface: false,
      categoriesCopy: [],
      categories: [],
      products: [],
      fileReceptors: [],
      documentReceptors: [],
      options: [],
      saving: false,
      newOption: { key: null, value: null },
      newField: { key: null, value: null, name: null },
      selectedEmail: null,
      selectedDocumentEmail: null,
      showCreateEmail: false,
      showCreateDocumentEmail: false,
      shareDatasheet: {
        active: false,
        selected: false,
        email: false,
        url: null,
        path: null,
        wp: null,
      },
      shareVideo: {
        active: false,
        selected: false,
        email: false,
        url: null,
        wp: null,
      },
      catalog: {
        active: false,
        selected: false,
        email: false,
        url: null,
        wp: null,
      },
      generalVideo: {
        active: false,
        selected: false,
        email: false,
        url: null,
        wp: null,
      },
      newEvent: {
        time: null,
        title: null,
        date: null,
        hour: null,
      },
      notifications: [],
      notificationTypes: [
        { value: 5, label: '5 minutos antes' },
        { value: 10, label: '10 minutos antes' },
        { value: 20, label: '20 minutos antes' },
        { value: 30, label: '30 minutos antes' },
        { value: 60, label: '1 hora antes' },
        { value: 120, label: '2 horas antes' },
        { value: 1440, label: '1 día antes' },
      ],
      documentFields: [],
      documentProducts: [],
      document: {
        fields: [],
        name: null,
        CI: null,
        category: null,
        product: null,
        address: null,
        price: null,
        addNote: false,
      },
      newIntegration: {
        user: null,
        reassignUser: false,
        reassignedUser: null,
      },
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    getImage(type) {
      let image = null;
      switch (type) {
        case 'pilot':
          image = 'pilot.png';
          break;
        case 'vtiger':
          image = 'vtiger.svg';
          break;
        case 'oracle-sales':
          image = 'oracle-sales.png';
          break;
        case 'mailer-lite':
          image = 'mailer-lite.svg';
          break;
        case 'induauto':
          image = 'induauto.jpg';
          break;
        case 'email':
          image = 'email.svg';
          break;
        case 'teojama':
          image = 'teojama.png';
          break;
        case 'nova-scoring':
          image = 'nova-scoring.jpg';
          break;
        default:
          image = 'lead.png';
          break;
      }
      // eslint-disable-next-line global-require,import/no-dynamic-require
      return require(`@/assets/${image}`);
    },
    saveStatus() {
      if (this.saving) return;
      const payload = {
        id: this.status.uuid,
        appointmentId: this.date.uuid,
        company: this.company.uuid,
        note: this.note,
      };

      if (this.status.activate_products) {
        const products = [];
        this.categoriesCopy.forEach((category) => {
          category.products.forEach((product) => {
            products.push(product);
          });
        });
        payload.products = products;
        payload.reasignLead = false;
        payload.sold_date = this.sell_date;
      }

      if (this.status.activate_whatsapp) {
        payload.template = this.dataTemplate.selectedTemplate;
        payload.flow_id = this.dataTemplate.selectedFlow?.id;
        payload.connection_id = this.connection.uuid;
        payload.destination = this.dataTemplate.destination;
        payload.name = this.dataTemplate.name;
        payload.type = this.dataTemplate.type;
        payload.to = this.dataTemplate.to;
        if (this.dataTemplate.components.length > 0) {
          payload.components = this.dataTemplate.components;
        }
        if (this.dataTemplate.body.length > 0) {
          payload.body = this.dataTemplate.body;
        }
        if (this.dataTemplate.header.length > 0) {
          payload.header = this.dataTemplate.header;
        }
      }

      if (this.status.activate_note) {
        if (this.note === null || this.note === '') {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'La nota es requerida para este estado',
          });
          return;
        }
      }

      if (this.status.activate_calendar) {
        if (!this.date.user_id) {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'el lead debe estar asignado a un asesor',
          });
          return;
        }
        console.log('time', this.newEvent);
        const timeArr = this.newEvent.time.split(':');
        console.log('time', timeArr);
        const date = dayjs(this.newEvent.date).hour(timeArr[0]).minute(timeArr[1]);
        console.log('date', date);
        payload.event = {
          title: this.newEvent.title,
          start_date: date.format('YYYY-MM-DD HH:mm'),
          end_date: date.add(30, 'minutes').format('YYYY-MM-DD HH:mm'),
          notifications: this.notifications,
        };
      }

      if (this.status.activate_options) {
        payload.customFields = this.options;
      }

      if (this.status.activate_arcgis) {
        // TODO: finish this
      }

      if (this.status.activate_documents) {
        payload.documentFields = this.document.fields.map((field) => {
          const newField = field;
          if (!field.label) {
            newField.label = field.key.replace('_', ' ');
            newField.name = field.key.replace('_', ' ');
          }
          return newField;
        });
        payload.documentName = this.document.name;
        payload.documentReceptors = this.documentReceptors;
        payload.documentCategory = this.document.category;
        payload.documentProduct = this.document.product;
        payload.documentDate = new Date();
        payload.documentId = this.document.CI;
        payload.documentAddress = this.document.address;
        payload.documentPrice = this.document.price;
        payload.addNote = this.document.addNote;
      }

      if (this.status.activate_attachments) {
        payload.fileReceptors = this.fileReceptors;
        payload.share = true;
        payload.shareDatasheet = this.shareDatasheet;
        payload.shareVideo = this.shareVideo;
        payload.catalog = this.catalog;
        payload.generalVideo = this.generalVideo;
        payload.product_id = this.product ? this.product.uuid : null;
      }
      if (this.status.activate_reasons) {
        payload.reason = this.reason;
      }
      if (this.status.activate_transfer) {

        // eslint-disable-next-line max-len
        // $http.post(`${system.apiUrl}/lead/${$scope.lead.uuid}/transfer`, status).then(function (response) {
        //   onStatusSuccess(response);
        //   $scope.onCancel();
        // }, function () {
        //   notify.error('No se pudo transferir el lead');
        // });
      }
      if (this.status.activate_custom_transfer) {
        payload.transfer_form = this.transferForm?.id;
        payload.transfer_user = this.transferUser?.id;
        payload.transfer_status = this.transferStatus?.id;
      }
      if (this.status.activate_integrations) {
        // TODO: finish this
        const ints = Object.keys(this.status.integrations);
        const integrations = {};
        ints.forEach((int) => {
          if (this.status.integrations[int]) {
            if (int === 'vtiger' || int === 'teojama') {
              integrations[int] = {
                user: this.newIntegration.user,
                user_id: this.newIntegration.user.id,
              };
            } else {
              integrations[int] = {};
            }
          }
        });
        payload.reasignLead = this.newIntegration.reassignUser;
        payload.reasignedUser = this.newIntegration.reassignedUser;
        payload.integrations = integrations;
        console.log('integrations', integrations);
      }
      console.log('status', payload);
      this.saving = true;
      this.$store.dispatch('changeAppointmentStatus', payload)
        .then(
          (response) => {
            this.saving = false;
            console.log('response message', response);
            this.$notify({
              type: 'success',
              title: 'estado actualizado correctamente',
              text: response.data.message,
            });
            this.$emit('close-update', this.date.uuid);
          },
          (error) => {
            this.saving = false;
            console.log('error message', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.saving = false;
          console.log('error message', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    saveJustStatus() {
      if (this.saving) return;
      const payload = {
        id: this.status.uuid,
        appointmentId: this.date.uuid,
        company: this.company.uuid,
        note: this.note,
      };
      console.log('status', this.payload);
      this.saving = true;
      this.$store.dispatch('changeAppointmentStatus', payload)
        .then(
          (response) => {
            this.saving = false;
            console.log('response message', response);
            this.$notify({
              type: 'success',
              title: 'estado actualizado correctamente',
              text: response.data.message,
            });
            this.$emit('close-update', this.date.uuid);
          },
          (error) => {
            this.saving = false;
            console.log('error message', error);
            this.$notify({
              type: 'error',
              title: 'Error',
              text: error,
            });
          },
        ).catch((error) => {
          this.saving = false;
          console.log('error message', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        });
    },
    getCompanyUsers(company) {
      const payload = {
        company,
      };
      this.$store.dispatch('companyUsers', payload)
        .then((response) => {
          this.transferUsers = response.data.success;
          console.log('respuesta companyUsers', response);
        }, (error) => {
          console.log('error companyUsers', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error companyUsers', error);
        });
    },
    getCompanyForms(company) {
      const payload = {
        company,
      };
      this.$store.dispatch('companyForms', payload)
        .then((response) => {
          this.transferForms = response.data.success;
          if (this.date.transfer_form_id) {
            const index = this.transferForms.findIndex(
              (form) => form.id === this.date.transfer_form_id,
            );
            if (index >= 0) {
              this.transferForm = this.transferForms[index];
            }
          }
          console.log('respuesta companyForms', response);
        }, (error) => {
          console.log('error companyForms', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error companyForms', error);
        });
    },
    getCompanyStatuses(company) {
      const payload = {
        company,
      };
      this.$store.dispatch('companyStatuses', payload)
        .then((response) => {
          this.transferStatuses = response.data.success;
          console.log('respuesta companyStatuses', response);
        }, (error) => {
          console.log('error companyStatuses', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error companyStatuses', error);
        });
    },
    createOption() {
      this.options.push(this.newOption);
      this.showOptionInterface = false;
      this.newOption = { key: null, value: null };
    },
    createField() {
      this.newField.label = this.newField.name;
      this.newField.key = this.newField.name.replace(' ', '_');
      this.document.fields.push(this.newField);
      this.showFieldInterface = false;
      this.newField = { key: null, value: null, name: null };
    },
    addEmail() {
      this.fileReceptors.push(this.selectedEmail);
      this.showCreateEmail = false;
      this.selectedEmail = null;
    },
    addDocumentEmail() {
      this.documentReceptors.push(this.selectedDocumentEmail);
      this.showCreateDocumentEmail = false;
      this.selectedDocumentEmail = null;
    },
    deleteFileReceptorEmail(key) {
      this.fileReceptors.splice(key, 1);
    },
    deleteDocumentReceptorEmail(key) {
      this.documentReceptors.splice(key, 1);
    },
    deleteNotification(key) {
      this.notifications.splice(key, 1);
    },
    addNotification() {
      this.notifications.push({ value: 5, label: '5 minutos antes' });
    },
    setCategories() {
      if (this.section === 'todas') {
        this.categories = this.companyCategories;
      } else {
        this.categories = this.section.categories;
      }
    },
    setProducts() {
      this.products = this.category.products;
    },
    setDocumentProducts() {
      this.documentProducts = this.document.category.products;
    },
    copyUrl(url) {
      navigator.clipboard.writeText(url);
    },
    setShareProduct() {
      if (this.product.file) {
        this.shareDatasheet.active = true;
        this.shareDatasheet.url = `https://leadbox.ec/storage/${this.product.file}`;
        this.shareDatasheet.path = `https://leadbox.ec/storage/${this.product.file}`;
        this.shareDatasheet.wp = `https://wa.me/${this.date.phone}?text=Hola%20${this.date.name},%20revisa%20esta%20ficha%20técnica%20https://leadbox.ec/storage/${this.product.file}`;
      }
      console.log('shareDatasheet', this.shareDatasheet);
      if (this.product.video_url) {
        this.shareVideo.active = true;
        this.shareVideo.url = this.product.video_url;
        this.shareVideo.wp = `https://wa.me/${this.date.phone}?text=Hola%20${this.date.name},%20mira%20nuestro%20video:%20${this.product.video_url}`;
      }
    },
    setDocumentProduct() {
      this.document.price = this.document.product?.price;
    },
    getIntegrations() {
      console.log('the company', this.activeCompany);
      const payload = {
        company: this.activeCompany.uuid,
      };
      this.$store.dispatch('integrations', payload)
        .then((response) => {
          console.log('respuesta integrations', response);
          response.data.success.forEach((integration) => {
            if (integration.type === 'vtiger' || integration.type === 'teojama') {
              this.getIntegrationUsers(integration.type, integration.uuid);
            }
          });
        }, (error) => {
          console.log('error integrations', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error integrations', error);
        });
    },
    getIntegrationUsers(type, integration) {
      console.log('type', type);
      console.log('integration', integration);
      const payload = {
        company: this.activeCompany.uuid,
        type,
        integration,
      };
      console.log('payload', payload);
      this.$store.dispatch('integrationUsers', payload)
        .then((response) => {
          console.log('respuesta integrationUsers', response);
        }, (error) => {
          console.log('error integrationUsers', error);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error integrationUsers', error);
        });
    },
    getLastUser() {
      if (this.dateHistories.length > 0) {
        const lastHistory = this.dateHistories[this.dateHistories.length - 1];
        if (lastHistory.user) {
          const user = this.datesUsersList.find((usr) => usr.uuid === lastHistory.user.uuid);
          if (user) {
            this.newIntegration.reassignUser = true;
            this.newIntegration.reassignedUser = user;
          }
        } else {
          const selectedHistory = this.dateHistories
            .filter((history) => (history.user ? history.user.id !== this.date.user_id : false));
          if (selectedHistory.length > 0) {
            const assesor = selectedHistory.filter((history) => history.user.company_role !== 'Admin');
            if (assesor.length > 0) {
              const user = this.datesUsersList
                .filter((usr) => usr.uuid === assesor[0].user.uuid)[0];
              if (user) {
                this.newIntegration.reassignUser = true;
                this.newIntegration.reassignedUser = user;
              }
            }
          }
        }
      }
    },
    // whatsapp stuff
    setTemplateComponents() {
      this.dataTemplate.components = [];

      this.dataTemplate.body = [];
      this.dataTemplate.header = [];
      console.log('this.dataTemplate', this.dataTemplate);
      this.dataTemplate.selectedTemplate.components.forEach((comp) => {
        const parameters = [];
        let finalText = null;
        if (comp.type === 'BODY') {
          const re = /{{(.*?)}}/g;
          const regs = ((comp.text || '').match(re) || []);
          finalText = comp.text;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < regs.length; i++) {
            parameters.push({
              type: 'text',
              text: '',
            });
            finalText = finalText.replace(`${regs[i]}`, '');
          }
          this.dataTemplate.body.push({
            type: 'text',
            value: finalText,
          });
        }
        if (comp.type === 'HEADER') {
          if (comp.format === 'IMAGE') {
            parameters.push({
              type: 'image',
              image: {
                link: '',
              },
            });
            finalText = 'imagen';
            this.dataTemplate.header.push({
              type: 'image',
              value: finalText,
            });
          }
        }
        this.dataTemplate.components.push({
          type: comp.type.toLowerCase(),
          parameters,
        });
        console.log('this.dataTemplate.components', this.dataTemplate.components);
      });
      const bodyIndex = this.dataTemplate.components.findIndex((comp) => comp.type === 'body');
      if (bodyIndex >= 0) {
        this.dataTemplate.components[bodyIndex].parameters[0].text = this.dataTemplate.name;
      }
      this.setTemplateTexts();
    },
    setTemplateTexts() {
      this.dataTemplate.body = [];
      this.dataTemplate.header = [];
      this.dataTemplate.selectedTemplate.components.forEach((comp, key) => {
        let finalText = null;
        if (comp.type === 'BODY') {
          const re = /{{(.*?)}}/g;
          const regs = ((comp.text || '').match(re) || []);
          finalText = comp.text;
          // eslint-disable-next-line no-plusplus
          for (let i = 0; i < regs.length; i++) {
            finalText = finalText.replace(
              `${regs[i]}`,
              this.dataTemplate.components[key].parameters[i].text,
            );
          }
          this.dataTemplate.body.push({
            type: 'text',
            value: finalText,
          });
        }
        if (comp.type === 'HEADER') {
          if (comp.format === 'IMAGE') {
            finalText = this.dataTemplate.components[key].parameters[0].image.link;
            this.dataTemplate.header.push({
              type: 'image',
              value: finalText,
            });
          }
        }
      });
    },
    onPasteTemplates(evt) {
      console.log('on paste', evt);
      const files = [];
      const { items } = evt.clipboardData || evt.originalEvent.clipboardData;
      // eslint-disable-next-line
      for (const index in items) {
        const item = items[index];
        if (item.kind === 'file') {
          files.push(item.getAsFile());
        }
      }
      if (files.length > 0) {
        this.addFilesTemplate(files, evt.target.id, evt.target.ariaLabel);
      }
    },
    openFilePickerTemplate(k) {
      console.log('refs', this.$refs);
      console.log('this.$refs.templateInput', this.$refs[`templateInput${k}`]);
      const element = this.$refs[`templateInput${k}`][0];
      element.click();
    },
    onInputChangeTemplates(e) {
      console.log('event, ', e);
      this.addFilesTemplate(e.target.files, e.target.id, e.target.ariaLabel);
      e.target.value = null;
      // reset so that selecting the same file again will still cause it to fire this change
    },
    addFilesTemplate(newFiles, key, k) {
      const newUploadableFiles = [...newFiles]
        .map((file) => new UploadableFile(file)).filter((file) => !this.fileExists(file.id));
      console.log('files', newUploadableFiles);
      this.uploadFileTemplate(newUploadableFiles[0], key, k);
    },
    fileExists(otherId) {
      return this.files.some(({ id }) => id === otherId);
    },
    async uploadFileTemplate(file, key, k) {
      this.isUploading = true;
      const formData = new FormData();
      formData.set('type', file.file.type);
      formData.set('name', file.file.name);
      formData.append('file', file.file);
      console.log('formData', formData);
      this.axios.post(
        'https://leadbox.ec/api/v1/templates/uploadFile',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Company: this.activeCompany.uuid,
          },
        },
      ).then((data) => {
        console.log('data files template', data);
        console.log('key', key);
        console.log('k', k);
        console.log('this.dataTemplate.components[key]', this.dataTemplate.components[key]);
        console.log(
          ' this.dataTemplate.components[key].parameters[k]',
          this.dataTemplate.components[key].parameters[k],
        );
        console.log(
          'this.dataTemplate.components[key].parameters[k].image',
          this.dataTemplate.components[key].parameters[k].image,
        );
        console.log(
          'this.dataTemplate.components[key].parameters[k].image.link',
          this.dataTemplate.components[key].parameters[k].image.link,
        );

        this.isUploading = false;
        this.dataTemplate.components[key].parameters[k].image.link = data.data.success;
        this.dataTemplate.header = [];
        this.dataTemplate.header.push({
          type: 'image',
          value: data.data.success,
        });
      })
        .catch((e) => {
          this.isUploading = false;
          console.log('error upload file template', e);
        });
    },
    getConnectionTemplates(connection) {
      console.log('fired');
      const payload = {
        connection: connection.uuid,
      };
      this.isLoadingConnectionTemplates = true;
      this.$store.dispatch('connectionTemplates', payload)
        .then((response) => {
          this.isLoadingConnectionTemplates = false;
          console.log('respuesta connectionTemplates', response);
          this.dataTemplate.templates = response.data.success.waba_templates;
        }, (error) => {
          this.isLoadingConnectionTemplates = false;
          console.log('error connectionTemplates', error);
        })
        .catch((error) => {
          this.isLoadingConnectionTemplates = false;
          console.log('error connectionTemplates', error);
        });
    },
  },
  computed: {
    title() {
      return 'title';
    },
    leadsUsers() {
      return this.$store.state.leadsUsers;
    },
    flows() {
      return this.$store.state.flows;
    },
    leadsUsersList() {
      return this.$store.state.leadsUsersList;
    },
    leadHistories() {
      return this.$store.state.leadHistories;
    },
    companyIntegrations() {
      return this.$store.state.companyIntegrations;
    },
    integrationUsers() {
      return this.$store.state.integrationUsers;
    },
    sections() {
      return this.$store.state.productSections;
    },
    companyCategories() {
      return this.$store.state.productCategories;
    },
    company() {
      return this.$store.state.activeCompany;
    },
    date() {
      return this.$store.state.activeDate;
    },
    connections() {
      return this.$store.state.connections;
    },
    total_products() {
      let total = 0;
      this.categoriesCopy.forEach((category) => {
        category.products.forEach((product) => {
          total += product.price * product.quantity;
        });
      });
      return total;
    },
  },
  watch: {
    showModal(value) {
      if (value) {
        if (this.status?.activate_whatsapp) {
          this.dataTemplate.destination = this.date.phone.replace(/\+/g, '');
          this.dataTemplate.name = this.date.name;
        }
        if (this.status?.activate_transfer) {
          this.transferType = this.status.transfer_type;
          this.selectedTransfer = this.status.transfer_type === 'user'
            ? this.status.transfer_user : this.status.transfer_form;
        }
        if (this.status?.activate_custom_transfer) {
          this.selectedTransferCompany = this.status.transfer_company;
          this.getCompanyUsers(this.selectedTransferCompany.uuid);
          this.getCompanyForms(this.selectedTransferCompany.uuid);
          this.getCompanyStatuses(this.selectedTransferCompany.uuid);
        }
        if (this.status.activate_options) {
          this.options = this.status.options
            .map((option) => ({ key: option.name, value: null }));
        }
        if (this.status.activate_products) {
          this.categoriesCopy = this.$store.state.productCategories.map((category) => {
            category.products.map((product) => {
              // eslint-disable-next-line no-param-reassign
              product.selected = false;
              // eslint-disable-next-line no-param-reassign
              product.quantity = 0;
              // eslint-disable-next-line no-param-reassign
              product.note = null;
              return product;
            });
            return category;
          });
          console.log('categories', this.categoriesCopy);
          if (this.date.sold_at) {
            this.sell_date = this.date.sold_at_formatted;
          } else {
            const date = new Date();
            const m = date.getMonth() + 1;
            const month = m > 9 ? m : `0${m}`;
            const day = date.getDate() > 9 ? date.getDate() : `0${date.getDate()}`;
            const formatted = `${date.getFullYear()}-${month}-${day}`;
            console.log('formated', formatted);
            this.sell_date = formatted;
          }
        }
        if (this.status.activate_documents) {
          if (this.date.email) {
            this.documentReceptors.push(this.date.email);
          }
          this.document.fields = this.status.document_form.fields;
          console.log('status', this.status);
          console.log('documents', this.document);
        }
        if (this.status.activate_attachments) {
          if (this.date.email) {
            this.fileReceptors.push(this.date.email);
          }
          if (this.status.file_path) {
            this.catalog.active = true;
            this.catalog.url = `https://leadbox.ec/storage/${this.status.file_path}`;
            this.catalog.wp = `https://wa.me/${this.date.phone}?text=Hola%20${this.date.name},%20mira%20nuestro%20catálogo:%20https://leadbox.ec/storage/${this.status.file_path}`;
          }
          if (this.status.video_url) {
            this.generalVideo.active = true;
            this.generalVideo.url = this.status.video_url;
            this.generalVideo.wp = `https://wa.me/${this.date.phone}?text=Hola%20${this.date.name},%20revisa%20nuestro%20video%20${this.status.video_url}`;
          }
        }
        if (this.status.activate_calendar) {
          this.newEvent.title = `${this.status.name}: ${this.date.name}`;
        }
        if (this.status.activate_integrations) {
          this.getLastUser();
        }
      }
    },
    connection(val) {
      if (val) {
        this.getConnectionTemplates(val);
      }
    },
  },
};
</script>

<style scoped>
.bg-wp {
  background-color: #1bd741;
}
</style>
<style src="@vueform/toggle/themes/default.css"></style>
