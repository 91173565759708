<template>
  <Menu as="div" class="relative inline-block text-left min-w-50">
    <div>
      <!--eslint-disable-->
      <MenuButton
        class="md:inline-flex justify-center w-full  shadow-sm px-3
        py-1 text-sm font-medium text-white hover:bg-white-50 hidden">
        <template class="flex" v-if="activeMedicalCenter">
          <div class="flex flex-col">
            <p class="ml-2 text-company mt-1 text-left text-sm w-44">{{ activeMedicalCenter.city_name }} - {{ activeMedicalCenter.name }}</p>
          </div>
        </template>
        <template v-else>
          <ClipboardListIcon class="mr-1 ml-2 my-2 w-8 h-8" aria-hidden="true"/>
          <p class="mt-2">Centros Médicos</p>
        </template>
        <ChevronDownIcon class="-mr-1 md:ml-2 ml-1 my-2 h-5 w-5" aria-hidden="true"/>
      </MenuButton>
      <MenuButton
        class="inline-flex justify-center w-full  shadow-sm px-1
        py-3 text-sm font-medium text-white hover:bg-white-50 md:hidden">
        <ClipboardListIcon class="mr-1 ml-2 my-2 h-6 w-6" aria-hidden="true"/>
        <ChevronDownIcon class="-mr-1 ml-2 my-2 h-5 w-5" aria-hidden="true"/>
      </MenuButton>
    </div>
    <transition enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95">
      <MenuItems
        class="origin-top-right absolute left-0 md:mt-2 md:w-80 w-52 max-h-96 z-20 overflow-auto shadow-lg  ring-1 ring-black ring-opacity-5 focus:outline-none h-max-screen overflow-auto company-name overflow-x-hidden">
        <div class="py-1 w-full">
          <MenuItem @click="selectActiveMedicalCenter(null)">
            <a href="#"
               :class="[activeMedicalCenter === null ? 'bg-gray-900 text-white' : 'text-white', 'block px-4 py-2 text-sm flex border-b border-gray-600']">
              <ClipboardListIcon class="mr-1 ml-2 my-2 w-10 h-10" aria-hidden="true"/>
              <div class="flex flex-col text-ellipsis overflow-hidden">
                <p class="m-2 my-1 mt-2 break-normal text-company text-md">TODOS</p>
              </div>
            </a>
          </MenuItem>
          <MenuItem v-for="(medicalCenter, key) in medicalCenters" @click="selectActiveMedicalCenter(medicalCenter)">
            <a href="#"
               :class="[medicalCenter.uuid === activeMedicalCenter?.uuid ? 'bg-gray-900 text-white' : 'text-white', 'block px-4 py-2 text-sm flex border-b border-gray-600']">
              <div class="flex flex-col text-ellipsis overflow-hidden">
                <p class="m-2 my-1 break-normal text-company text-xs">{{ medicalCenter.city_name }} - {{ medicalCenter.name }}</p>
              </div>
            </a>
          </MenuItem>
        </div>
      </MenuItems>
    </transition>
  </Menu>
</template>

<script>
import {
  Menu, MenuButton, MenuItem, MenuItems,
} from '@headlessui/vue';
import {
  ChevronDownIcon, ClipboardListIcon,
} from '@heroicons/vue/solid';
import { useCompanyFallbackImage } from '@/utils/mixins';

export default {
  name: 'MedicalCenterDropDown',
  mixins: [useCompanyFallbackImage],
  components: {
    Menu,
    MenuButton,
    MenuItem,
    MenuItems,
    ChevronDownIcon,
    ClipboardListIcon,
  },
  props: {
    type: String,
  },
  methods: {
    selectActiveMedicalCenter(medicalCenter) {
      this.$store.dispatch('setActiveMedicalCenter', medicalCenter)
        .then((response) => {
          console.log('respuesta', response);
          this.$emit('changed-medical-center');
        }, (error) => {
          console.log('status', error.data.statusCode);
          this.$notify({
            type: 'error',
            title: 'Error',
            text: error.data.message,
          });
        })
        .catch((error) => {
          this.$notify({
            type: 'error',
            title: 'Error',
            text: 'hubo un error',
          });
          console.log('error', error);
        });
    },
  },
  computed: {
    medicalCenters() {
      return this.$store.state.medicalCenters;
    },
    activeMedicalCenter() {
      return this.$store.state.activeMedicalCenter;
    },
  },
};
</script>
<style>
  .company-name{
    background: #2a323c;
    color: #fff;
  }
  .text-company{
    text-overflow:ellipsis;
    white-space:nowrap;
    overflow:hidden;
  }

  .text-company span {
    color: #FFCF01;
  }
  button:focus {
    outline: none !important;
  }
  div#headlessui-menu-items-33 {
    margin-left: -49px;
  }
  /*Scroll*/
  .overflow-auto::-webkit-scrollbar-thumb {
    background-color: #8ad2e8;
  }

  .overflow-auto::-webkit-scrollbar-track {
    background: #2a323c;
  }

  .overflow-auto::-webkit-scrollbar {
    width: 5px;
  }
  .logo-company{
    margin-top: -5px;
  }
</style>
